import {createAction, createReducer, Reducer} from "@reduxjs/toolkit";

interface IMigrationState {
	versions: string[]
}

const initialState: IMigrationState = {
	versions: []
};

export const addMigrationVersion = createAction<string>("ADD_MIGRATION");

const migration: Reducer<IMigrationState> = createReducer(initialState, builder => {
  builder.addCase(addMigrationVersion, (state, action) => {
    state.versions = [...state.versions, action.payload]
  })
});

export default migration;
