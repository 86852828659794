import React, {ChangeEvent, FormEvent, useState} from 'react'
import {useValidation} from "../../../hooks";
import useTranslations from "../../../hooks/useTranslations";
import styles from './Account.module.scss'
import {UserResponse} from "../../../store/auth/authTypes";
import Icon from "../../../components/icons/Icon/Icon";
import Edit from "../../../resources/svg/Edit.svg";
import {IChannelTranslationsState} from "../../../store/channel/channel.types";
import TextInput from "../../../components/inputs/TextInput";
import Button, {ButtonTypes, ColourTypes} from "../../../components/inputs/buttons/Button/Button";
import {updateUser} from "../../../store/auth/authReducer";
import {FormType} from "../../../store/portal/portalTypes";
import {useAppDispatch} from "../../../store";

interface IAccountProps {
  user: UserResponse;
}

interface IUserData {
  [key: string]: FormField
}

interface FormField {
  title: string;
  value: string;
  type: FormType;
  error: string | undefined;
}

const Account = ({user}: IAccountProps) => {
  const initialFormState: IUserData = {
    firstName: {
      title: 'firstName',
      value: user.first_name || "",
      type: FormType.text,
      error: undefined
    },
    lastName: {
      title: 'lastName',
      value: user.last_name || "",
      type: FormType.text,
      error: undefined
    },
    email: {
      title: 'title',
      value: user.email || "",
      type: FormType.email,
      error: undefined
    },
    phone: {
      title: 'phone',
      value: user.phone_number || "",
      type: FormType.tel,
      error: undefined
    }
  }

  const dispatch = useAppDispatch();
  const {translation} = useTranslations();
  const {validateFirstName, validateLastName} = useValidation();
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [data, setData] = useState<IUserData>(initialFormState);

  const toggleAccountEdit = (): void => {
    setInEditMode(() => !inEditMode);
  }

  const cancelAccountEdit = (): void => {
    setData(() => initialFormState);
    setIsInvalid(() => false);
    toggleAccountEdit();
  }

  const updateState = (field: FormField): void => {
    let newObject = {...data};
    newObject[field.title] = {...newObject[field.title], value: field.value, error: field.error};
    setData(() => (newObject));
  }

  const validateField = (field: FormField): FormField => {
    if (field.title === 'firstName') field.error = validateFirstName(field.value);
    if (field.title === 'lastName') field.error = validateLastName(field.value);
    if (field.error) setIsInvalid(() => true)
    else setIsInvalid(() => false)
    return field;
  }

  const handleFieldChange: React.ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent): void => {
    const target = e.target as HTMLInputElement;
    const formData = {
      title: target.name,
      value: target.value,
      type: target.type,
      error: ''
    } as FormField;
    updateState(validateField(formData));
  }

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const updatedUser = {
      ...user,
      first_name: data.firstName.value,
      last_name: data.lastName.value,
      phone_number: data.phone.value
    };

    if (user.id) dispatch(updateUser(updatedUser));
    setInEditMode(false);
  };

  return (
    <div className={styles.account}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.heading}>{translation.get('portal:account:title')}</div>
          {!inEditMode && <div className={styles.leftAligned}>
            <Icon src={Edit} className={styles.icon} onClick={toggleAccountEdit}/>
          </div>}
        </div>
        <form onSubmit={handleFormSubmit}>
          {Object.entries(data).map(([name, field]) =>
            <div key={name}>
              {inEditMode ?
                <span>
                  <TextInput
                    label={translation.get(`portal:account:${field.title}` as keyof IChannelTranslationsState)}
                    value={field.value}
                    onChange={handleFieldChange}
                    type={field.type}
                    name={field.title}
                    disabled={field.type === 'email'}
                  />
                  {field.error && <p className={`${styles.error} ${styles.leftAligned}`}>{field.error}</p>}
                </span>
                :
                <div className={styles.row}>
                  <div className={styles.column}>
                    {translation.get(`portal:account:${name}` as keyof IChannelTranslationsState)}:
                  </div>
                  <div className={styles.column}>
                    {field.value}
                  </div>
                </div>
              }
            </div>
          )}
          {inEditMode &&
          <div className={styles.footer}>
            <Button
              text={translation.get("button:confirm")}
              disabled={isInvalid}
              style={{margin: 0}}
            />
            <Button
              text={translation.get("button:cancel")}
              color={ColourTypes.secondary}
              onClick={cancelAccountEdit}
              type={ButtonTypes.button}
              style={{margin: 0}}
            />
          </div>
          }
        </form>
      </div>
    </div>
  )
}
export default Account;