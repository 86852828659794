import {GenericInput} from "../types";
import React, {useCallback} from "react";
import styles from "./TextArea.module.scss";
import sharedStyles from "../Shared.module.scss";
import LabeledContainer from "../../containers/LabeledContainer";
import useInputStyles from "../InputStyles.hook";

interface TextAreaProps extends GenericInput<string> {
  onKeyPress?: (value: string) => void;
  max?: number;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  controls?: React.ReactElement;
  placeholder?: string;
  cols?: number;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>((
  {
    name,
    value,
    onChange,
    onClick = (() => {
    }),
    disabled,
    style,
    inputClassName= "",
    max,
    label,
    icon,
    cols = 5,
    controls,
    containerClassName = "",
    labelContainerClassName = "",
    placeholder= "",
    onBlur,
    onFocus
  }, ref) => {
  const inlines = useInputStyles(style);

  const onFieldChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange === undefined) return;

    onChange(e)
  }, [onChange])

  const content = <>
    {icon && icon}

    <textarea
      cols={cols}
      name={name}
      value={value}
      onChange={onFieldChange}
      placeholder={placeholder}
      disabled={disabled}
      ref={ref}
      onBlur={onBlur}
      onFocus={onFocus}
      maxLength={max}
      className={`${styles.input} ${sharedStyles.input} ${inputClassName}`}
    />

    {(controls !== undefined && React.isValidElement<any>(controls))
    && React.cloneElement(controls as React.ReactElement, {
      className: `${(controls.props.className || '')} ${sharedStyles.controls}`,
      fill: controls.props.fill || inlines.color,
      ...controls.props,
    })}
  </>

  if (label) return (
    <LabeledContainer
      style={inlines}
      onClick={onClick}
      label={label}
      className={labelContainerClassName}
      inputContainerClassName={`${styles.container} ${sharedStyles.container} ${containerClassName}`}>
      {content}
    </LabeledContainer>
  )

  return (<div
    style={inlines}
    onClick={onClick}
    className={`${styles.container} ${sharedStyles.container} ${containerClassName}`}>
    {content}
  </div>);
}
);

export default TextArea;
