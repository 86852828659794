import {useCallback, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import Step from "../../../../components/containers/Step";
import {useAppSelector} from "../../../../store";
import {saveJourney} from "../../../../store/flight/flightReducer";
import {Schedule} from "../../../../store/flight/types/api/liveFlight";
import {Journey} from "../../../../store/flight/types/api/reservation";
import JourneyCreationStepActive from "./JourneyCreationStepActive";
import JourneyCreationStepCompleted from "./JourneyCreationStepCompleted";
import useTranslations from "hooks/useTranslations";

const JourneyCreationStep = () => {
  const dispatch = useDispatch();
	const {translation} = useTranslations();

  const stepSubmit = useRef<(data: Journey)=>Promise<any>>(null);
  const search = useAppSelector((state) => ({
    ...state.flight.search,
    schedules: state.flight.search?.schedules.filter((schedule: Schedule)=>{
      return schedule.route[state.app.currentFlow === 'city' ? 'arrival_airport' : 'departure_airport'].serviceable
    })
  }))
  const whitelabel = useAppSelector((state) => state.channel.whitelabel)
  const [openRouteSelector, setOpenRouteSelector] = useState<boolean>(false);

  const onEnter = useCallback(() => {
    if (!stepSubmit.current) return;

    if (search?.schedules.length === 1) {
      let journey = {
        departure: {
          designator: search.number,
          codeshares: search.codeshares,
          carrier: {
            name: search.airline.name,
            codes: search.airline.codes
          },
          schedule: {
            datetime: search.schedules[0].departure_datetime,
            datetime_local: search.schedules[0].departure_datetime_local,
            airport: {
              location_id: search.schedules[0].route.departure_airport.id,
              name: search.schedules[0].route.departure_airport.name,
              services: {
                injection: search.schedules[0].route.departure_airport.serviceable,
                meeting: search.schedules[0].route.departure_airport.serviceable,
                belt: search.schedules[0].route.departure_airport.serviceable,
              },
              codes: search.schedules[0].route.departure_airport.codes,
              latitude: search.schedules[0].route.departure_airport.latitude,
              longitude: search.schedules[0].route.departure_airport.longitude,
              timezone: search.schedules[0].route.departure_airport.timezone,
              city: search.schedules[0].route.departure_airport.city,
              region: search.schedules[0].route.departure_airport.region,
              country: search.schedules[0].route.departure_airport.country,
            }
          }
        },
        arrival: {
          designator: search.number,
          codeshares: search.codeshares,
          carrier: {
            name: search.airline.name,
            codes: search.airline.codes
          },
          schedule: {
            datetime: search.schedules[0].arrival_datetime,
            datetime_local: search.schedules[0].arrival_datetime_local,
            airport: {
              location_id: search.schedules[0].route.arrival_airport.id,
              name: search.schedules[0].route.arrival_airport.name,
              services: {
                injection: search.schedules[0].route.arrival_airport.serviceable,
                meeting: search.schedules[0].route.arrival_airport.serviceable,
                belt: search.schedules[0].route.arrival_airport.serviceable,
              },
              codes: search.schedules[0].route.arrival_airport.codes,
              latitude: search.schedules[0].route.arrival_airport.latitude,
              longitude: search.schedules[0].route.arrival_airport.longitude,
              timezone: search.schedules[0].route.arrival_airport.timezone,
              city: search.schedules[0].route.arrival_airport.city,
              region: search.schedules[0].route.arrival_airport.region,
              country: search.schedules[0].route.arrival_airport.country,
            }
          }
        }
      }

      return stepSubmit.current(journey);
    }

    else if (search?.schedules.length > 1) setOpenRouteSelector(true);
  }, [search])

  const onSubmit = useCallback(async (journey: Journey) => {
    return dispatch(saveJourney(journey))
  }, [dispatch])

  return <Step
    ref={stepSubmit}
    onSubmit={onSubmit}
    onEnter={onEnter}
    header={translation.get("journey_creation:heading")}
    Active={JourneyCreationStepActive}
    Completed={JourneyCreationStepCompleted}
    props={{
      Active: {
        whitelabel,
        search,
        openModal: openRouteSelector,
        setOpenModal: setOpenRouteSelector,
      },
      Completed: {
        data: search
      }
    }}
  />
}

export default JourneyCreationStep;