import { DateTime } from "luxon";
import React, { useMemo } from "react";
import CompleteStep from "../../../../../components/CompleteStep";
import useTranslations from "../../../../../hooks/useTranslations";
import {useAppSelector} from "../../../../../store";
import {Flow} from "../../../../../store/channel/channel.types";
import {Journey} from "../../../../../store/flight/types/api/reservation";
import {StepInfoProps} from "../../../../types";

export interface Props extends StepInfoProps {
  onEdit: () => void;
  data: {
    flow: Required<Flow>,
  }
}

const PnrFlightStepCompleted: React.FC<Props> = ({onEdit, data}) => {
  const {translation} = useTranslations();
  const journey = useAppSelector((state) => state.flight.journey as Required<Journey>);

  const departure_date = useMemo(() => {
    return DateTime.fromJSDate(new Date(journey.departure.schedule.datetime)).toLocaleString(DateTime.DATE_SHORT)
  }, [journey])

  return (
    <CompleteStep
      id="pnr-flight-edit"
      onEdit={onEdit}
      headingText={translation.get("flight:completed_heading", {
        carrier: journey.departure.carrier.name,
        designator: journey.departure.designator,
        departure_date,
        departure_airport: journey.departure.schedule.airport.name
      })}
    />
  );
};

export default PnrFlightStepCompleted;
