import React, { useCallback, useMemo, useState } from "react";
import { ActiveStepProps } from "../../../../../components/containers/Step/types";
import LuggageIcon from "../../../../../components/icons/LuggageIcon";
import MinusIcon from "../../../../../components/icons/MinusIcon";
import PlusIcon from "../../../../../components/icons/PlusIcon";
import Button from "../../../../../components/inputs/buttons/Button";
import IntegerInput from "../../../../../components/inputs/IntegerInput";
import TextInput from "../../../../../components/inputs/TextInput";
import useTranslations from "../../../../../hooks/useTranslations";
import { useValidation } from "../../../../../hooks";
import { FlightState, Passenger } from "../../../../../store/flight/types/store";
import sharedStyles from "../../../../../styles/Shared.module.scss";
import styles from './PaxSelectionActive.module.scss';
import {Allowance, AllowanceUnit} from "store/flight/types/api/reservation";
import Utils from 'utils/Utils'
import { useAppSelector } from "store";

interface PaxSelectionActiveProps extends ActiveStepProps {
  data: FlightState;
  onUpdate: (passengers: Passenger[]) => void;
  onSubmit: (passengers: Passenger[]) => void;
}

const allowanceSummary = (flightId: number | undefined, passengers: Passenger[]): string => {
  const totalAllowance = passengers.reduce((result, passenger) => {
    const flightAllowance = passenger.allowance
      ?.filter(allowance => allowance.flight_id === flightId)
      .reduce((acc, allowance) => {
        acc.total += allowance.base * allowance.quantity;
        acc.unit = allowance.unit;
        return acc;
      }, { total: 0, unit: '' });

    result.total += flightAllowance?.total || 0;
    result.unit = flightAllowance?.unit || result.unit;
    return result;
  }, { total: 0, unit: '' });

  return `${totalAllowance.total} ${totalAllowance.unit}`;
}

const calculateAllowance = (flightId: number | undefined, allowances: Allowance[]): string => {
  const summaryWithUnit = allowances.reduce((acc, allowance) => {
    if (allowance.flight_id === flightId) {
      acc.total += allowance.base * allowance.quantity;
      acc.unit = allowance.unit;
    }
    return acc;
  }, { total: 0, unit: '' });

  const displayUnit = summaryWithUnit.unit === AllowanceUnit.count ? '' : summaryWithUnit.unit;

  return `${summaryWithUnit.total} ${displayUnit}`;
}

const getClassName = (id: number, length: number, left: boolean) => {
  let className = '';

  if (id === 0) className = styles.halfBorderTop;
  else if (id === length - 1) className = styles.halfBorderBottom;
  else className = styles.extended;

  className += left ? ` ${styles.borderLeft}` : ` ${styles.borderRight}`;
  return className;
}

const hideOrShowLastName = (name: string, percentage = 1) => {
	if(percentage === 1) return name;

	let visibleCharacters = Math.ceil(name.length * percentage);
  return name.split('').map((character, index)=>{
     if(index >= visibleCharacters) return '*';
     return character;
  }).join('')
}

const PaxSelectionActive = ({ data, onSubmit, onUpdate }: PaxSelectionActiveProps) => {
  const { translation } = useTranslations();
  const { validatePassportNumber, validateLuggage } = useValidation();

	const {passengerNamePercentageShown} = useAppSelector(state => state.channel.properties.content)

  const [passengers, setPassengers] = useState(data.passengers);
  const [errors, setErrors] = useState<{ passport?: (string | undefined)[], luggage?: string }>({
    passport: undefined,
    luggage: undefined,
  });

  const validatePassportFields = useCallback((passengers: Passenger[]) => {
    /**
     * The passport must be provided for any passenger that uses our service.
     */
    const passportErrors = passengers.map((passenger) => {
      if (passenger.luggage === 0) return undefined;
      return validatePassportNumber(passenger.passport);
    })

    const passportNumbers = passengers.map(passenger => passenger.passport)

    const hasDuplicates = Utils.object.hasDuplicates(passportNumbers)

    if(hasDuplicates)
      passportErrors[passportErrors.length - 1] = translation.get("validation:input:passport_duplicate")

    setErrors((errors) => ({...errors, passport: passportErrors}))

    return passportErrors.every(err => err === undefined);
  }, [validatePassportNumber, translation])

  const validateLuggageFields = useCallback((passengers: Passenger[]) => {
    /**
     * At least one luggage item should be selected.
     */
    const luggageError = validateLuggage(passengers);

    setErrors((errors) => ({ ...errors, luggage: luggageError }))

    return luggageError === undefined;
  }, [validateLuggage])

  const handleLuggageChange = useCallback((value: number, id: number) => {
    const updated = [...passengers];

    updated[id] = {
      ...updated[id],
      luggage: value
    }

    const luggageValid = validateLuggageFields(updated)
    setPassengers(updated)

    if (luggageValid) onUpdate(updated);
  }, [passengers, onUpdate, validateLuggageFields]);

  const handlePassportChange = useCallback((value: string, id: number) => {
    const updated = [...passengers];

    updated[id] = {
      ...updated[id],
      passport: value,
    }

    validatePassportFields(updated);
    setPassengers(updated)
  }, [passengers, validatePassportFields])

  const handleSubmit = useCallback(() => {
    const validPassport = validatePassportFields(passengers);
    const validLuggage = validateLuggageFields(passengers);

    if (!validPassport || !validLuggage) return;

    onSubmit(passengers);
  }, [onSubmit, passengers, validatePassportFields, validateLuggageFields])

  const isWeightSharing = useMemo(() => {
    if (passengers.length === 1) return false;

    return passengers.some(passenger => {
      if (passenger.allowance.length)
        return passenger.allowance.find(allowance => allowance.flight_id === data.journey?.departure.id && allowance.weight_sharing)

      return false;
    })
  }, [passengers, data.journey?.departure.id])

  const disableSubmit = useMemo(() => {
    const emptyFields = passengers.every(pax => pax.passport === "") || passengers.reduce((total, passenger) => total + passenger.luggage, 0) === 0;

    return emptyFields || !!Object.values(errors).map((value) => {
      if (Array.isArray(value)) {
        return value.reduce((value: string | undefined, previous) => {
          if (previous !== undefined) return previous;

          return value;
        });
      }

      return value;
    }).filter((value) => value !== undefined).length
  }, [passengers, errors])


  return (
    <article className={styles.container}>
      <header>
        <h2>{translation.get("passenger:title")}</h2>
        <h4>
          {translation.get("passenger:subtitle")}
        </h4>
      </header>

      <table>
        <thead>
          <tr>
            <th scope={"col"}>{translation.get("passenger:passenger_info:first_name")}</th>
            <th scope={"col"}>{translation.get("passenger:passenger_info:last_name")}</th>
            <th scope={"col"}>{translation.get("passenger:passenger_info:allowance")}</th>
            {isWeightSharing && passengers.length > 1 && 
              <>
                <th scope={"col"}></th>
                <th scope={"col"}></th>
                <th scope={"col"}></th>
              </>
             }
            <th scope={"col"}>{translation.get("passenger:passenger_info:luggage")}</th>
            <th scope={"col"}>{translation.get("passenger:passenger_info:passport")}</th>
          </tr>
        </thead>
        <tbody>
          {passengers.map((passenger, id) => <React.Fragment key={`row-${id}`}>
            <tr data-label={passenger.first_name + " " + passenger.last_name}>
              <td data-field={"FirstName"}
                data-label={translation.get("passenger:passenger_info:first_name")}>{passenger.first_name}</td>
              <td data-field={"LastName"}
                  data-label={translation.get("passenger:passenger_info:last_name")}>{hideOrShowLastName(passenger.last_name, passengerNamePercentageShown)}</td>
              <td data-field={"Allowance"} data-label={translation.get("passenger:passenger_info:allowance")}>
                {calculateAllowance(data.journey?.departure.id, passenger.allowance)}
              </td>
              {isWeightSharing && passengers.length > 1 && 
                <>
                  <td 
                    data-field="leftLine" 
                    className={getClassName(id, passengers.length, true)} 
                    data-label=""
                  >
                    <div />
                  </td>
                  <td data-field={"SharedAllowance"}
                    data-label={translation.get("passenger:passenger_info:shared_allowance")}>
                    <label>{translation.get("passenger:passenger_info:shared_allowance")}</label>
                    {allowanceSummary(data.journey?.departure.id, passengers)}
                  </td>
                  <td 
                    data-field="rightLine" 
                    className={`${getClassName(id, passengers.length, false)} ${passenger.infant && styles.disabled}`} 
                    data-label=""
                   >
                    <div />
                  </td>
                </>
              }
              <td data-field={"LuggageCount"} data-label={translation.get("passenger:passenger_info:luggage")}>
                <IntegerInput
                  disabled={passenger.infant}
                  data-cy="luggage-input"
                  name="luggageCount"
                  icon={LuggageIcon}
                  value={passenger.luggage === undefined ? passenger.allowance[0].quantity : passenger.luggage}
                  min={0}
                  max={passenger.allowance[0]?.unit === 'count' ? passenger.allowance[0]?.quantity : 30}
                  onChange={(value) => handleLuggageChange(value, id)}
                  addIcon={PlusIcon}
                  subtractIcon={MinusIcon}
                  delay={300}
                />
              </td>
              <td data-field={"Passport"} data-label={translation.get("passenger:passenger_info:passport")}>
                <TextInput
                  value={passenger.passport || ""}
                  data-cy="passport-input"
                  onChange={(e) => handlePassportChange(e.target.value.toUpperCase(), id)}
                  error={errors && errors.passport && errors.passport[id]}
                  labelContainerClassName={styles.labelContainer}
                  disabled={passenger.infant || passenger.luggage === 0}
                />
              </td>
            </tr>
          </React.Fragment>)}
        </tbody>
      </table>

      <div className={sharedStyles["submit-button-wrapper"]}>
        <Button
          type="submit"
          data-cy="passenger-step-submit"
          text={translation.get("button:next")}
          onClick={handleSubmit}
          disabled={disableSubmit}
        />
      </div>
    </article>
  )
}

export default PaxSelectionActive;