import styles from "./Hamburger.module.scss";

interface HamburgerProps {
  isOpen: boolean;
  style?: any;
  onClick?: () => void;
}

const Hamburger = ({isOpen, style, onClick}: HamburgerProps) => {
  return (
    <div style={{display: 'flex'}}>
      <div onClick={onClick} className={`${styles.hamburger} ${isOpen ? styles.active : '' }`}>
        <span className={styles.bar} {...(style ? {"style": style} : {})} />
        <span className={styles.bar} {...(style ? {"style": style} : {})}/>
        <span className={styles.bar} {...(style ? {"style": style} : {})}/>
      </div>
    </div>
  )
}

export default Hamburger;