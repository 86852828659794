import React from "react";
import useTranslations from "../../../../hooks/useTranslations";
import sharedStyles from "../../../../styles/Shared.module.scss";
import styles from "./Incomplete.module.scss";
import Button from "../../../inputs/buttons/Button/Button";
import {IncompleteStepProps} from "../types";

const Incomplete = ({heading}: IncompleteStepProps) => {
  const {translation} = useTranslations();
  return (
    <div className={styles["incomplete-flex-align-center"]}>
      <h2 className={styles["heading-incomplete"]}>
        {heading || ""}
      </h2>
      <div className={sharedStyles["flex-align-right"]}>
        <div className={styles.line}/>
        <Button
          text={translation.get("button:next")}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default Incomplete;
