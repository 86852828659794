import React, {memo, useCallback, useEffect, useState,} from "react";
import "react-datepicker/dist/react-datepicker.css";
import useTranslations from "hooks/useTranslations";
import SingleInput from "components/inputs/SingleInput/SingleInput";
import Label from "components/containers/LabeledContainer/Label/Label";
import styles from "./VerificationForm.module.scss";
import ValidationError from "components/ValidationError";
import {useAppSelector} from "store";
import Button from "components/inputs/buttons/Button";
import InfoCard from "components/InfoCard/InfoCard";
import { DateTime } from "luxon";

export interface Props {
  inputs: Array<string>;
  autoFocus?: boolean;
	resendCode: () => void;
  onChange: (otp: string) => any;
  onSubmit: (values: string) => any;
	"data-cy"?: string;
}

const VerificationForm = ({autoFocus, inputs, onChange, onSubmit, resendCode, "data-cy": dataCy}: Props) => {
  const {translation} = useTranslations();

  const properties = useAppSelector((state) => state.channel.properties);
  const error = useAppSelector((state) => state.payment.verification?.error);
	const expiryTimestamp = useAppSelector((state) => state.payment.verification?.expiryTimestamp);
  const [activeInput, setActiveInput] = useState<number>(0);
  const [values, setValues] = useState<Array<string>>(['', '', '', '', '', '']);
	const [codeExpired, setCodeExpired] = useState<boolean>(false);

  const handleChange = useCallback((char: string[]) => {
    const value = char.join('');
    onChange(value);
  }, [onChange]);

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedValues = [...values];
      updatedValues[activeInput] = str[0] || '';
      setValues(updatedValues);
      handleChange(updatedValues);
    },
    [activeInput, handleChange, values],
  );

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(inputs.length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [inputs],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      if (!target?.value) {
        e.preventDefault();
        return;
      }

      changeCodeAtFocus(target.value.toUpperCase());
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput],
  );

	const handleOnPaste = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			e.preventDefault();
			const pastedData = e.clipboardData.getData('text');
			
			if (pastedData.length > 0) {
				const updatedValues = [...values];
				let nextIndex = activeInput;
				for (let char of pastedData) {
					if (nextIndex < inputs.length) {
						updatedValues[nextIndex] = char.toUpperCase();
						nextIndex++;
					} else {
						break;
					}
				}
				setValues(updatedValues);
				handleChange(updatedValues);
				focusInput(Math.min(nextIndex, inputs.length - 1));
			}
		},
		[activeInput, focusInput, handleChange, inputs.length, values]
	);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (values[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, values],
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

	useEffect(() => {
		const now = DateTime.now();
		const timestamp = DateTime.fromISO(expiryTimestamp);

		if(now >= timestamp && error) setCodeExpired(true);

		else setCodeExpired(false);
	}, [error, expiryTimestamp])

  return (
    <div data-cy={dataCy} className={`${styles.container} ${styles.gap}`}>
      <InfoCard 
				className={styles.emailInfoCard} 
				type="info" 
				title={translation.get("payment:verification:title")}
				subtitle={translation.get("payment:verification:subtitle")}
			/>

      <div className={styles.container}>
        <Label className={styles.verificationLabel} text={translation.get("payment:verification:label")}/>
        <div className={styles.wrapper}>
          {inputs.map((item: string, index: number) => (
            <SingleInput
              customProperty
              properties={properties}
              id={`SingleInput-${index}`}
              key={`SingleInput-${index}`}
              type={item}
							onPaste={handleOnPaste}
              focus={activeInput === index}
              value={values && values[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
            />
          ))}
        </div>

				{!codeExpired && <div onClick={resendCode} className={styles.resend}>{translation.get("payment:verification:code_resend")}</div>}
        {error && <ValidationError className={styles.error} onClick={() => codeExpired && resendCode()} error={translation.get(error)}/>}

        <Button
          id="submit-verification"
          data-cy="submit-verification"
          type="submit"
          className={styles.submit}
          onClick={() => onSubmit(values.join(''))}
          text={translation.get("button:submit")}
        />
      </div>

    </div>
  );
}

export default memo(VerificationForm);