import CompleteStep from "components/CompleteStep";
import useTranslations from "hooks/useTranslations";
import {useAppSelector} from "store";

const LoginStepComplete = () => {
  const {translation} = useTranslations();
  const {first_name, last_name} = useAppSelector(state => state.auth.user)

  return (
    <CompleteStep
      id="login-step"
      headingText={translation.get("login:completed_heading", {
        first_name,
        last_name
      })}
    />
  )
}

export default LoginStepComplete;