import React, {useCallback, useRef} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import enGb from 'date-fns/locale/en-GB';
import CaretIcon from "../../icons/CaretIcon";
import {GenericInput} from '../types';
import styles from './DateInput.module.scss';
import LabeledContainer from '../../containers/LabeledContainer';
import TextInput from '../TextInput';
import {IconProps} from '../../icons/Icon/Icon';
import CalendarIcon from "../../icons/CalendarIcon";

registerLocale('en-gb', enGb);

interface DateProps extends GenericInput<Date> {
  onKeyPress?: (value: string) => void;
  value: Date;
  min?: Date;
  max?: Date;
  onChange: (date: Date) => void;
  caretIcon?: React.ReactElement<IconProps>;
}

const DateInput = React.forwardRef<HTMLInputElement, DateProps>((
  {
    name,
    onChange,
    style,
    inputClassName,
    min,
    max,
    label,
    labelContainerClassName = '',
    containerClassName = '',
    icon = CalendarIcon,
    caretIcon = CaretIcon,
    value,
    disabled = false
  }, ref,
) => {
  const dateRef = useRef<DatePicker | null>(null);

  const content = (
    <DatePicker
      wrapperClassName={`${styles.dateWrapper}`}
      dateFormat="dd/MM/yyyy"
      selected={value}
      onChange={onChange}
      minDate={min}
      maxDate={max}
      popperPlacement="bottom-end"
      popperClassName={`${styles.popperContainer}`}
      locale="en-gb"
      disabled={disabled}
      ref={dateRef}
      name={name}
      customInput={(
        <TextInput
          readonly={true}
          disabled={disabled}
          containerClassName={`${styles.inputContainer || ''}`}
          ref={ref}
        />
      )}
    />
  );

  const onOutsideClick = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    /**
     * Carry over click events to the date selector.
     */
    if (dateRef === null || typeof dateRef === "function") return;

    // Do not propagate click event to parent container.
    event.preventDefault();

    if (!dateRef.current?.isCalendarOpen()) {
      dateRef?.current?.setOpen(true)
    }
  }, [dateRef])

  if (label) {
    return (
      <LabeledContainer
        onClick={onOutsideClick}
        label={label}
        className={`${disabled && styles.disabled} ${labelContainerClassName}`}
        inputContainerClassName={`${styles.container} ${containerClassName}`}
        caret={React.cloneElement(caretIcon, {
          className: `${caretIcon.props.className} ${styles.caret}`
        })}
        icon={icon}
      >
        {content}
      </LabeledContainer>
    );
  }

  return (
    <div className={`${styles.container || ''} ${containerClassName || ''}`}>
      {content}
    </div>
  );
});

export default DateInput;
