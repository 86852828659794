import {GenericInput} from "../types";
import React, {FocusEventHandler, useCallback} from "react";
import styles from "./TextInput.module.scss";
import LabeledContainer from "../../containers/LabeledContainer";
import {IconProps} from "../../icons/Icon/Icon";
import ValidationError from "../../ValidationError";

interface TextInputProps extends GenericInput<string | number> {
  onKeyPress?: (value: string) => void;
  max?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => React.FocusEventHandler<HTMLInputElement> | void;
  onFocus?: FocusEventHandler;
  type?: "number" | "tel" | "email" | "text" | "password";
  controls?: React.ReactElement;
  placeholder?: string;
  error?: string;
  customProperty?: boolean;
  component?: React.ReactElement;
  autoComplete?: "on" | "off";
  readonly?: boolean;
  disablePaste?: boolean;
  "data-cy"?: string;
}

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((
  {
    name,
    type = "text",
    value,
    onChange,
    onClick = (() => {
    }),
    disabled,
    style,
    inputClassName,
    max,
    label,
    component,
    icon,
    controls,
    containerClassName = "",
    labelContainerClassName = "",
    placeholder = "",
    onBlur,
    onFocus,
    error,
    customProperty,
    autoComplete="off",
    readonly = false,
    'data-cy': dataCy,
    disablePaste,
  }, ref) => {
  /**
   * A generic text input.
   *
   * This input can either be used directly, or in an abstract manner by nesting it within other components.
   * Note: If you wish to use labels please use the label container instead.
   */

  const onFieldChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange === undefined) return;

    onChange(e)
  }, [onChange])

  const content = <>
    {
      (icon !== undefined && React.isValidElement<IconProps>(icon))
      && React.cloneElement(icon as React.ReactElement<IconProps>, (
        () => {
          return {
            className: `${(icon.props.className || '')}`,
            ...icon.props,
          }
        }
      )())
    }

    <input
      data-cy={dataCy}
      data-customproperty={customProperty}
      size={1}
      type={type}
      name={name}
      value={value}
      onPaste={disablePaste ? (e) => e.preventDefault() : undefined}
      onChange={onFieldChange}
      placeholder={placeholder}
      disabled={disabled}
      ref={ref}
      onBlur={onBlur}
      onFocus={onFocus}
      maxLength={max}
      autoComplete={autoComplete}
      readOnly={readonly}
      className={`${styles.input || ""} ${inputClassName || ""}`}
    />

    {(controls !== undefined && React.isValidElement<any>(controls))
    && React.cloneElement(controls as React.ReactElement, {
      className: `${(controls.props.className || '')} ${styles.controls}`,
      ...controls.props,
    })}
  </>

  if (label) return (
    <div className={`${labelContainerClassName}`}>
      <LabeledContainer
        onClick={onClick}
        label={label}
        component={component}
        className={`${disabled && styles.disabled}`}
        inputContainerClassName={`${styles.container} ${containerClassName}`}
        error={error}
      >
        {content}
      </LabeledContainer>
    </div>
  )

  return (
    <div className={labelContainerClassName}>
      <div
        onClick={onClick}
        data-disabled={disabled}
        className={`${styles.container} ${containerClassName || ""}`}>
        {content}
      </div>
      <ValidationError error={error}/>
    </div>
  )
});

export default TextInput;
