import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import useTranslations from "../../../hooks/useTranslations";
import {useAppDispatch, useAppSelector} from "../../../store";
import {setSearchTerm} from "../../../store/booking/bookingReducer";
import BookingService from "../../../store/booking/bookingService";
import {AddressSearchType, SelectableAddress} from "../../../store/booking/bookingTypes";
import styles from "./AddressSearch.module.scss";
import Modal from "components/modals/Modal";
import Button from "components/inputs/buttons/Button";
import {ColourTypes} from "components/inputs/buttons/Button/Button";
import {LocationType} from "store/portal/portalTypes";
import {Location} from "store/booking/bookingTypes";
import {useBookingMeta} from "hooks";


interface PlacesAutoCompleteProps {
  type: string;
  onError: (error: string | undefined) => void;
  onUpdate: (location: Location) => void;
}

interface AddressPayload {
  country_code: string;
  language: string;
  page: string;
  short_address?: string;
  address_string?: string;
}

export const AddressSearch: React.FC<PlacesAutoCompleteProps> = ({type, onUpdate, onError}) => {
  const dispatch = useAppDispatch();
  const {translation} = useTranslations();
  const {direction} = useBookingMeta();

  const country = useAppSelector((state) => state.flight.journey[direction].schedule.airport.country);
	const language = useAppSelector((state) => state.app.currentLanguage || state.channel.properties.language.meta.default)
  const searchTerm = useAppSelector((state) => state.booking.saAddress?.searchTerm || '');

  const [value, setValue] = useState<string>(searchTerm);
  const [responses, setResponses] = useState<SelectableAddress[]>([]);
  const [openAddressSelector, setOpenAddressSelector] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [shortAddressValue, setShortAddressValue] = useState<string>('');

  const fetchAddresses = useCallback((value: string, type: AddressSearchType, page?: string) => {
    dispatch(setSearchTerm(value));
    const payload: AddressPayload = {
      country_code: country,
      language,
      page: page || "1",
    };

    if (type === 'short_address') {
      payload.short_address = value;
    } else {
      payload.address_string = value;
    }

    BookingService.getCountrySpecificAddress(payload).then((response) => {
      onError(undefined);
      setResponses([...responses, ...response.data.results]);
      setOpenAddressSelector(true)

      if (!response.data.next) {
        setHasMore(false);
        return;
      }

      const queryParams = new URLSearchParams(response.data.next);
      const page = queryParams.get('page');
      if (page) setNextPage(page)
    }).catch((error) => {
      onError(error.response.data.description);
    })
  }, [dispatch, country, language, onError, responses]);

  const debouncedFetchAddresses = useMemo(() => {
    let timer: NodeJS.Timeout;
    return (value: string, type: AddressSearchType, page?: string) => {
      clearTimeout(timer);
      timer = setTimeout(() => fetchAddresses(value, type, page), 1000);
    };
  }, [fetchAddresses]);


  const handleAddressInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
    if (inputValue.length > 3) {
      debouncedFetchAddresses(inputValue, AddressSearchType.Standard);
    }
  };

  const handleShortAddressInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toUpperCase();
    setShortAddressValue(inputValue);
    if (inputValue.match(/[A-Z]{4}\d{4}/)) {
      debouncedFetchAddresses(inputValue, AddressSearchType.Short);
    }
  };

  const handleAddressSelection = (address: SelectableAddress) => {
    const formattedAddress = {
      name: `${address.street} ${address.number} ${address.addition}, ${address.city} ${address.postal_code}, ${address.region}, ${address.country}`,
      description: "",
      type: LocationType.private,
      coordinates: {
        latitude: address.coordinates.latitude,
        longitude: address.coordinates.longitude
      },
      address: {
        street: address.street,
        number: address.number,
        addition: address.addition,
        postal_code: address.postal_code,
        city: address.city,
        region: address.region,
        country: address.country,
        coordinates: {
          latitude: address.coordinates.latitude,
          longitude: address.coordinates.longitude
        },
      }
    }

    onUpdate(formattedAddress)
    setOpenAddressSelector(false);
  }

  const scrollContainerRef = useRef<HTMLTableElement | null>(null);
  const isUpdating = useRef<boolean>(false);

  const checkScrollEnd = useCallback(() => {
    if (scrollContainerRef.current && hasMore && !isUpdating.current) {
      const {scrollTop, clientHeight, scrollHeight} = scrollContainerRef.current;
      const isNearTableEnd = scrollTop + clientHeight >= scrollHeight * 0.9;
      const isContainerNotFull = scrollHeight <= clientHeight;

      if (isNearTableEnd || isContainerNotFull) {
        isUpdating.current = true;
        debouncedFetchAddresses(value, AddressSearchType.Standard, nextPage)
      }
    }
  }, [hasMore, debouncedFetchAddresses, value, nextPage]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const onScroll = () => {
        checkScrollEnd();
      };

      scrollContainer.addEventListener('scroll', onScroll);
      return () => scrollContainer.removeEventListener('scroll', onScroll);
    }
  }, [checkScrollEnd]);

  useEffect(() => {
    isUpdating.current = false;
    checkScrollEnd();
  }, [checkScrollEnd, responses]);

  useEffect(() => {
    setShortAddressValue("");
    setValue("");
  }, [type]);

  const closeAndReset = () => {
    setOpenAddressSelector(false);
    setResponses([]);
  }

  return (
    <div>
      <Modal headerText={translation.get("address:address_field")} hide={closeAndReset} isShown={openAddressSelector}
             modalContent={
               <div ref={scrollContainerRef} className={styles.modalContent}>
                 {responses.map(address =>
                   <Button
                     className={styles.addressButton}
                     key={address.coordinates.latitude + address.coordinates.longitude}
                     color={ColourTypes.primary}
                     text={address.extra.representation.join(" ")}
                     onClick={() => handleAddressSelection(address)}
                   />
                 )}
               </div>
             }
      />
      {type === AddressSearchType.Standard ?
        <input
          type="text"
          placeholder={translation.get("address:address_field")}
          value={value}
          onChange={handleAddressInput}
          className={styles.input}
        />
        :
        <input
          type="text"
          placeholder={translation.get("address:short_address:placeholder")}
          value={shortAddressValue}
          onChange={handleShortAddressInput}
          className={styles.input}
        />
      }

    </div>
  )
};
