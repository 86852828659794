import React, { useMemo } from "react";
import useTranslations from "../../../../hooks/useTranslations";
import sharedStyles from "../../../../styles/Shared.module.scss";
import Warning from "../../Warning/Warning";
import {ITenant} from "../../../../store/portal/portalTypes";
import UserFriends from "../../../../resources/svg/UserFriends.svg";
import InfoCircle from "../../../../resources/svg/InfoCircle.svg";
import TenantInformation from "../../TenantInformation/TenantInformation";
import styles from "./PickupStep.module.scss";
import Icon from "../../../../components/icons/Icon/Icon";
import { Flow } from "../../../../store/channel/channel.types";
import {BookingState} from "../../../../store/booking/types/store";
import { PnrJourney } from "store/flight/flightTypes";

interface PickupStepProps {
  journey: PnrJourney;
  booking: BookingState;
  type: Flow;
  tenant?: ITenant;
  bookingDetails?: JSX.Element;
}

const PickupStep = ({journey, booking, type, tenant, bookingDetails}: PickupStepProps) => {
  const {translation} = useTranslations();

	const pickupLocation = useMemo(() => {
		const address = booking.meeting?.location?.address;

		if (type === Flow.City) return journey.arrival.schedule.airport.name;
		if(!address) return "";

		return `${address.number}, ${address.street ? `${address.street}, ` : ""}${address.city}, ${address.region}, ${address.postal_code}, ${address.country}`
	}, [booking.meeting?.location?.address, journey.arrival.schedule.airport.name, type])

  return (
    <div>
      {bookingDetails ? bookingDetails : <h2 className={sharedStyles.cardText}>{translation.get('thank_you:pickup:title')} </h2>}
      <h2 className={`${sharedStyles.cardText}`}>{pickupLocation} </h2>
      {tenant && <div>
        <h2 className={sharedStyles.cardText}>{translation.get('thank_you:pickup:tenant')}
          <span className={`${sharedStyles.cardText}`}>{` ${tenant.name}`}</span>
        </h2>
        <TenantInformation icon={InfoCircle} tenant={tenant}/>
      </div>}
      {type === Flow.Airport ?
        <Warning icon={UserFriends} text={translation.get('thank_you:pickup:warning')}/> :
        <div className={styles.cityPickupInfoBox}>
          <Icon className={styles.infoIcon} src={InfoCircle}/>
          <h3 className={styles.cityPickupInfo}>{translation.get("city:luggage:pickup:title")}</h3>
          <h4 className={styles.cityPickupInfo}>{translation.get("city:luggage:pickup:subtitle")}</h4>
        </div>
      }
    </div>
  );
}

export default PickupStep;