import Button from "components/inputs/buttons/Button";
import React, {useEffect, useRef, useState} from "react";
import useTranslations from "../../hooks/useTranslations";
import sharedStyles from "../../styles/Shared.module.scss";
import style from "./CompleteStep.module.scss";

interface ICompleteStepProps {
  id: string;
  headingText?: string;
  input?: string;
  headingComponent?: JSX.Element;
  children?: React.ReactNode;
  onEdit?: (height: number) => void;
  className?: string;
	disableEdit?: boolean;
}

const CompleteStep = (
  {
    id,
    headingText,
    input,
    headingComponent,
    children,
		disableEdit,
    onEdit,
    className = "",
  }: ICompleteStepProps) => {
  const {translation} = useTranslations();

  const stepContainer = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(
    stepContainer.current?.clientHeight || 0
  );

  useEffect(() => {
    setHeight(stepContainer.current?.clientHeight || 0);
  }, [height]);

  const onClick = () => {
    if(onEdit === undefined) return;

    onEdit(height);
  };

  return (
    <React.Fragment>
      <div className={`${style.container} ${className}`}>{headingText &&
        <h2 data-cy="completed-step-heading">
          {headingText || ""}
          <span className={sharedStyles.completeTitle}>
            {input}
          </span>
        </h2>}
        {headingComponent && headingComponent}
        <div className={`step-content`}>
          {children}
          <div className={style.buttonWrapper}>
            {onEdit && <Button type="reset" id={id} text={translation.get("button:edit")} disabled={!!disableEdit} onClick={onClick}/>}
          </div>
        </div>
      </div>
    </React.Fragment>

  );
}

export default CompleteStep;
