import {useCallback, Fragment} from 'react';
import useTranslations from "../../hooks/useTranslations";
import {IChannelTranslationsState, WhiteLabel} from "../../store/channel/channel.types";
import {useAppSelector} from "../../store";
import Accordion from "../../components/containers/Accordion";
import styles from "./Faq.module.scss"

const Faq = ({whitelabel}: { whitelabel: WhiteLabel }) => {
  /**
   * A list of frequently asked questioned in the form of a Modal.
   */

  const {translation} = useTranslations();
  const faqItems = useAppSelector(state => state.channel.properties.faq);

  const getAccordionData = useCallback((key: string, length: number) => {
    return Array.from({length}, (_, i) => ({
      title: translation.get(`${key}:question:${i + 1}` as keyof IChannelTranslationsState),
      content: translation.get(`${key}:answer:${i + 1}` as keyof IChannelTranslationsState, {contact: whitelabel.company.phone}),
    }));
  }, [translation, whitelabel.company.phone]);

  const getStatements = useCallback((key: string, length: number) => {
    return Array.from({length}, (_, i) => translation.get(`${key}:${i + 1}` as keyof IChannelTranslationsState)).filter(Boolean);
  }, [translation]);

  return (
    <div>
      {faqItems.map((item: string) => {
        const itemLength = parseInt(translation.get(`${item}:length` as keyof IChannelTranslationsState), 10);

        if (item === 'faq:statements') {
          const statementData = getStatements(item, itemLength);
          return (
            <Fragment key={item}>
              <div className={styles.title}>{translation.get(`${item}:title` as keyof IChannelTranslationsState)}</div>
              <ul className={styles.list}>
                {statementData.map((statement, index) => (
                  <li className={styles.item} key={index}>{statement}</li>
                ))}
              </ul>
            </Fragment>
          )
        }

        return (
          <Accordion
            key={item}
            title={translation.get(`${item}:title` as keyof IChannelTranslationsState)}
            data={getAccordionData(item, itemLength)}
          />
        );
      })}
    </div>
  )
}

export default Faq;
