import useTranslations from "../../../../hooks/useTranslations";
import Regulations from "../../Regulations/Regulations";
import sharedStyles from "../../../../styles/Shared.module.scss";
import { Flow } from "../../../../store/channel/channel.types";
import { PnrJourney } from "store/flight/flightTypes";

interface FlightStepProps {
  journey: PnrJourney
  type: Flow
}

const FlightStep = ({journey, type}: FlightStepProps) => {
  const {translation} = useTranslations();
  return (
    <div>
      <h2 className={sharedStyles.cardText}>{translation.get('thank_you:flight:title')} {journey.departure.carrier.name}! </h2>
      {type === Flow.Airport ? <Regulations/> : null}
    </div>
  );
}

export default FlightStep;