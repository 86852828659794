import { LegacyRef, useCallback, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ENV from './environments';

interface Props {
	onVerify: (token: string | null) => Promise<void>;
}

const ReCAPTCHAv2 = ({ onVerify }: Props) => {
  const recaptchaRef: LegacyRef<ReCAPTCHA> | undefined = createRef();

  const onChange = useCallback((value: string | null) => {
		return onVerify(value)
	}, [onVerify]);

  return (
    <div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={ENV.RECAPTCHA_V2_KEY}
        onChange={onChange}
      />
    </div>
  );
};

export default ReCAPTCHAv2;
