import React from "react";
import useTranslations from "../../hooks/useTranslations";
import Authentication from '../../utils/authentication';
import styles from "./Header.module.scss";
import ThankYouMessage from "../../pages/BookingSummary/ThankYouMessage";
import Menu from "./Menu";
import { useAppSelector } from "../../store";
import { useLocation } from 'react-router-dom';

export interface IInlineStyles {
  header: React.CSSProperties,
  hero: React.CSSProperties,
  navigation: React.CSSProperties,
  menu: React.CSSProperties,
  logo: React.CSSProperties,
  button: React.CSSProperties
}

const Header: React.FC = () => {
  /**
   * Defines the structure and functionality of the webfunnels' header.
   *
   * This includes the navigation,
   */
  const location = useLocation();
  const { translation } = useTranslations();
  const properties = useAppSelector((state) => state.channel.properties);
  const content = useAppSelector(state => state.channel.properties.content);
  const {showHeaderSlogan, headerMenu} = content;

  const { whitelabel, switches } = properties;

  const handleLogout = async () => {
    await Authentication.logout();
    localStorage.clear();
  }

  /**
   * DISPLAY LOGIC
   */
  return (
    <section className={styles.header}>
      <Menu
        items={headerMenu}
        switches={switches} 
        handleLogout={handleLogout}
        pathName={location.pathname} 
        images={whitelabel.images} 
      />

      {
        <header className={styles.hero}>
          {location.pathname === '/summary' ?
            <div className={styles.thankYou}>
              <ThankYouMessage />
            </div>
            :
            <div className={styles.content}>
              {showHeaderSlogan && <h1 className={styles.headerSlogan} id={'slogan'}>{translation.get('header:slogan')}</h1>}
            </div>
          }
        </header>
      }
    </section>
  );
};

export default Header;
