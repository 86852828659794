import styles from "./SuperscriptedText.module.scss";
import Icon from "../icons/Icon";

export interface SuperscriptedTextProps {
  text: string;
  superscriptIcon?: string;
  superscriptText?: string;
  info?: JSX.Element;
}

const SuperscriptedText = (
  {
    text,
    superscriptIcon,
    superscriptText,
    info,
  }: SuperscriptedTextProps) => {
  return (
    <span className={styles.container}>
      {` ${text}`}
      <sup className={`${styles.superscript} ${info ? styles.tooltip : ''}`}>
        <span>
          {superscriptIcon ? <Icon src={superscriptIcon} className={styles.icon}/> : superscriptText}<span className={styles.tooltipText}>{info}</span>
        </span>
      </sup>
    </span>
  )
}

export default SuperscriptedText;