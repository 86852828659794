import React, { useCallback, useMemo, useState } from "react"
import LabeledContainer from "../../../../../../components/containers/LabeledContainer";
import Icon from "../../../../../../components/icons/Icon";
import BaseDropdown, { DropdownItem } from "../../../../../../components/inputs/dropdowns/BaseDropdown";
import User from "../../../../../../resources/svg/User.svg";
import { Passenger } from "../../../../../../store/flight/types/store";
import styles from "./PnrContactForm.module.scss";
import useTranslations from "hooks/useTranslations";
import { useAppSelector } from "store";

export interface Fields {
  email: string;
  confirmEmail: string;
  firstName: string;
  lastName: string;
  passportNumber?: string;
  phoneNumber: string;
  password?: string;
  confirmPassword?: string;
  general?: string;
}

interface IContactFormProps {
  onChange: (formData: Fields) => void;
  passengers: Passenger[]
}

const ContactSelectionForm = ({ passengers, onChange }: IContactFormProps) => {
  const { translation } = useTranslations();

  const contact = useAppSelector(state => state.payment.contact);

  const dropdownOptions: DropdownItem<Passenger>[] = useMemo(() => {
    return passengers.filter((passenger: Passenger) => !passenger.infant).map((passenger: Passenger) => ({
      value: passenger,
      label: `${passenger.title || ""} ${passenger.last_name} ${passenger.first_name}`,
      onClick: (item: any) => setSelectedItem(item)
    })
    )
  }, [passengers])

  const initialSelectedItem: DropdownItem<Passenger> | undefined = useMemo(() => {
    const passenger = passengers.find(pax => pax.first_name === contact.first_name && pax.last_name === contact.last_name)

    if (passenger)
      return {
        value: passenger,
        label: `${passenger.title || ""} ${passenger.last_name} ${passenger.first_name}`,
        onClick: (item: any) => setSelectedItem(item)
      }

  }, [contact, passengers])

  const [selectedItem, setSelectedItem] = useState<DropdownItem<Passenger> | undefined>(initialSelectedItem) as [DropdownItem<Passenger> | undefined, React.Dispatch<React.SetStateAction<DropdownItem<Passenger> | undefined>>];

  const onPassengerSelection = useCallback((item: DropdownItem<Passenger>) => {
    if(item.label === selectedItem?.label) return;

    onChange({
      email: item.value.email || "",
      confirmEmail: "",
      firstName: item.value.first_name,
      lastName: item.value.last_name,
      passportNumber: item.value.passport,
      phoneNumber: item.value.phone_numbers ? item.value.phone_numbers[0] : ""
    })
  }, [selectedItem, onChange])

  return (
    <>
      <LabeledContainer className={styles.contact} label={translation.get("payment:label_contact")} children={
        <BaseDropdown
          data-cy="contact-dropdown"
          className={styles.dropdown}
          value={selectedItem?.label}
          items={dropdownOptions}
          onSelect={onPassengerSelection}
          icon={() =>
            <Icon
              src={User}
              className={`${styles.icon}`}
            />
          }
        />
      } />
    </>
  )
}

export default ContactSelectionForm;
