import { Dispatch, SetStateAction } from "react";
import Button from "components/inputs/buttons/Button";
import { ActiveStepProps } from "components/containers/Step/types";
import Modal from "components/modals/Modal";
import { WhiteLabel } from "store/channel/channel.types";
import { FlightSearch, Schedule } from "store/flight/types/api/liveFlight";
import { ColourTypes } from "components/inputs/buttons/Button/Button";
import useTranslations from "hooks/useTranslations";

interface Props extends ActiveStepProps {
  search: FlightSearch;
  whitelabel: WhiteLabel;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: any;
}

const JourneyCreationStepActive = ({ openModal, setOpenModal, whitelabel, search, onSubmit }: Props) => {

  const { translation } = useTranslations();

  const createJourney = (schedule: Schedule) => {
    return {
      departure: {
        designator: search.number,
        codeshares: search.codeshares,
        carrier: {
          name: search.airline.name,
          codes: search.airline.codes
        },
        schedule: {
          datetime: schedule.departure_datetime,
          datetime_local: schedule.departure_datetime_local,
          airport: {
            location_id: schedule.route.departure_airport.id,
            name: schedule.route.departure_airport.name,
            services: {
              injection: schedule.route.departure_airport.serviceable,
              meeting: schedule.route.departure_airport.serviceable,
              belt: schedule.route.departure_airport.serviceable,
            },
            codes: schedule.route.departure_airport.codes,
            latitude: schedule.route.departure_airport.latitude,
            longitude: schedule.route.departure_airport.longitude,
            timezone: schedule.route.departure_airport.timezone,
            city: schedule.route.departure_airport.city,
            region: schedule.route.departure_airport.region,
            country: schedule.route.departure_airport.country,
          }
        }
      },
      arrival: {
        designator: search.number,
        codeshares: search.codeshares,
        carrier: {
          name: search.airline.name,
          codes: search.airline.codes
        },
        schedule: {
          datetime: schedule.arrival_datetime,
          datetime_local: schedule.arrival_datetime_local,
          airport: {
            location_id: schedule.route.arrival_airport.id,
            name: schedule.route.arrival_airport.name,
            services: {
              injection: schedule.route.arrival_airport.serviceable,
              meeting: schedule.route.arrival_airport.serviceable,
              belt: schedule.route.arrival_airport.serviceable,
            },
            codes: schedule.route.arrival_airport.codes,
            latitude: schedule.route.arrival_airport.latitude,
            longitude: schedule.route.arrival_airport.longitude,
            timezone: schedule.route.arrival_airport.timezone,
            city: schedule.route.arrival_airport.city,
            region: schedule.route.arrival_airport.region,
            country: schedule.route.arrival_airport.country,
          }
        }
      }
    }
  }

  return (
    <Modal headerText={translation.get("journey_creation:route_heading")} hide={() => setOpenModal(false)} isShown={openModal} modalContent={
      <>
        {search.schedules.map(schedule =>
          <Button
						key={schedule.id}
            style={{width: "100%"}}
            color={ColourTypes.primary}
            text={`${schedule.route.departure_airport.name} - ${schedule.route.arrival_airport.name}`}
            onClick={() => onSubmit(createJourney(schedule))}
          />
        )}
      </>
      } 
    />
  )
}

export default JourneyCreationStepActive