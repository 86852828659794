import ChevronDown from "../../../resources/svg/ChevronDown.svg";
import Icon from "../Icon";
import React, {useMemo} from "react";
import {IconProps} from "../Icon/Icon";
import styles from "./CaretIcon.module.scss";

interface Props extends Omit<IconProps, 'src'> {}

const CaretIcon: React.FC<Props> = ({className}) => {
  const classNames = useMemo(() => {
    return `${className ? className : ''} ${styles.icon}`;
  }, [className])

  return <Icon src={ChevronDown} className={classNames}/>;
}

export default CaretIcon;