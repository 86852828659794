import Icon from "../Icon";
import React, {useMemo} from "react";
import {IconProps} from "../Icon/Icon";
import styles from "./CalendarIcon.module.scss";
import Calendar from "../../../resources/svg/Calendar.svg";

interface Props extends Omit<IconProps, 'src'> {}

const CalendarIcon: React.FC<Props> = ({className, ...props}) => {
  const classNames = useMemo(()=>{
    return `${className ? className : ''} ${styles.icon}`;
  }, [className])

  return <Icon src={Calendar} className={classNames} {...props} />;
}

export default CalendarIcon;