import { createAsyncThunk, createReducer, Reducer } from "@reduxjs/toolkit";
import { GET_BOOKINGS, GET_INVOICE_DETAILS, GET_LOCATION, GET_LOCATIONS, GET_TENANT_DETAILS } from "./portalConstants";
import { IPortalState, LocationType } from './portalTypes';
import { Location } from "../booking/bookingTypes";
import PortalService from "./portalService";
import { APIStatus } from "utils/apiStatus";
import PaymentService from "store/payment/paymentService";

const initialState: IPortalState = {
  bookings: {
    bookings: [],
    offset: undefined
  },
  locations: {
    bagpoint: [],
    private: [],
    offset: undefined
  },
  invoices: [],
  tenants: []
}

export const getBookings = createAsyncThunk(GET_BOOKINGS, async ({ offset, status }: { offset?: number, status?: APIStatus }) => {
  const data = await PortalService.getBookings(status ? status : APIStatus.READY, offset)
  return {
    ...data,
    offset
  }
})
export const getLocations = createAsyncThunk(GET_LOCATIONS, async ({ offset, type }: { offset?: number, type?: LocationType }) => {
  const data = await PortalService.getLocations(type ? type : LocationType.private, offset)
  return {
    ...data,
    offset
  }
})
export const getLocationById = createAsyncThunk(GET_LOCATION, async (id: number) =>
  await PortalService.getLocationById(id)
)
export const getInvoiceDetails = createAsyncThunk(GET_INVOICE_DETAILS, async (bookingCode: string) => {
  const data = await PaymentService.getInvoices(bookingCode)
  return {
    ...data,
    bookingCode
  }
})
export const getTenantDetails = createAsyncThunk(GET_TENANT_DETAILS, async ({ tenantId, bookingCode }: { tenantId: number, bookingCode: string }) => {
  const data = await PortalService.getTenant(tenantId)
  return {
    ...data,
    bookingCode
  }
})

const portal: Reducer<IPortalState> = createReducer(initialState, builder => {
  builder.addCase(getBookings.fulfilled, (state, action) => {

    const {results} = action.payload;
    let {bookings} = state.bookings;
    results.forEach((booking) => {
      if (!bookings.length || !bookings.find(item => item.code === booking.code)) {
        bookings = bookings.concat(booking)
      }
    })

    state.bookings = {
      bookings,
      offset: action.payload.offset,
      count: action.payload.count
    }
  })
  builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
    state.invoices = [...state.invoices, action.payload]
  })
  builder.addCase(getLocations.fulfilled, (state, action) => {

    const results = action.payload.results as Array<Required<Location>>;
    let locations = state.locations.private;
    results.forEach((location) => {
      if (!locations.length || !locations.find(item => item.id === location.id)) {
        locations = locations.concat(location)
      }
    })

    state.locations = {
      ...state.locations,
      private: locations,
      offset: action.payload.offset,
      count: action.payload.count
    }
  })
  builder.addCase(getLocationById.fulfilled, (state, action) => {
    state.locations = {
      ...state.locations,
      bagpoint: state.locations.bagpoint.concat(action.payload)
    }
  })
  builder.addCase(getTenantDetails.fulfilled, (state, action) => {
    state.tenants = [...state.tenants, action.payload]
  })
})

export default portal;