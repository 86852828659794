import ENV from "../utils/environments";
import {AxiosRequestConfig} from "axios";
import Fetcher from "../utils/fetcher";

interface Endpoints {
  booking: string
}

abstract class AbstractService {

  /**
   *  The AbstractService aims to provide some base methods which can be re-used across all
   *  services to facilitate the integration of the API.
   */
  static ENDPOINTS: Endpoints = {
    booking: `${ENV.API_URL}/bookings/{bookingCode}/status/?limit=1`,
  }

  static delay = (t: number) => new Promise((resolve) => setTimeout(resolve, t));

  static async awaitStatus(
    bookingCode: string,
    entity: keyof Endpoints = 'booking',
    statuses = ['STATUS.PROGRESS.001'],
    timer = 2000
  ): Promise<AxiosRequestConfig> {
    /**
     * Wait for the status of the associated entity to reach a specific status.
     * Until this status is reached, the method will repeatedly call the server.
     */

    const URL = this.ENDPOINTS[entity].replace("{bookingCode}", bookingCode);
    return Fetcher(URL, 'GET')
      .then(async response => {
        if (statuses.includes(response.data.results[0].name)) {
          return response.config;
        }

        return new Promise(resolve => setTimeout(resolve, timer))
          .then(_ => this.awaitStatus(bookingCode, entity, statuses, timer));
      })
  }
}

export default AbstractService;
