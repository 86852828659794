import AbstractService from "../service";
import ENV from "../../utils/environments";
import {
  InvoiceResponse,
  PaymentContact,
  PaymentContactResponse,
  PaymentLinkParams,
  PaymentMethodResponse,
  PaymentSessionResponse,
	VerificationCodeResponse
} from "./paymentTypes";
import Fetcher from "../../utils/fetcher";
import {publicIpv4, publicIpv6} from 'public-ip';
import { BookingResponse } from "store/booking/bookingTypes";
import { AxiosRequestConfig } from "axios";
import { FlightSearch } from "store/flight/types/api/liveFlight";

const STATUS = {
  INVOICE: {
    PROCESSING: "STATUS.PROGRESS.000",
    CREATED: "STATUS.PROGRESS.001",
    UPDATED: "STATUS.EVENT.004",
    READY: "STATUS.PROGRESS.002",
  }
}

class PaymentService extends AbstractService {
  static async getInvoice(bookingCode: string, timer = 1000): Promise<InvoiceResponse> {
    /**
     * Retrieve the currently valid invoice for a given booking code.
     *
     * The invoice call will repeatedly attempt to retrieve the invoice, until one such time where the
     * invoice is made available.
     */
    return Fetcher(
      `${ENV.API_URL}/bookings/${bookingCode}/invoices/latest/`,
      'GET'
    )
      .then((response) => response.data)
      .catch((error) => {
        if (error.status !== 200 || error.data.status !== STATUS.INVOICE.READY) {
          return this.delay(timer).then(() => this.getInvoice(bookingCode, timer));
        }
      })
  }

  static async getAdyenSession(bookingCode: string, returnUrl: string, contact: PaymentContact): Promise<PaymentSessionResponse> {
    let shopperIP = '';
    try {
      shopperIP = await publicIpv6();
    } catch (e) {
      try {
        shopperIP = await publicIpv4();
      } catch (error) {
        console.error(error)
      }
    }
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/adyen/session/`, 'POST', {
      returnUrl,
      shopperIP,
      contact
    })
      .then((response) => response.data)
      .catch((error) => console.debug(error))
  }

  static async savePaymentContact(bookingCode: string, contactDetails: PaymentContact, language?: string): Promise<PaymentContactResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/contacts/`, 'POST', {
      ...contactDetails, language
    })
      .then((response) => response.data)
      .catch((error) => new Error(error))
  }

  static async getInvoices(bookingCode: string): Promise<InvoiceResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/invoices/`, 'GET')
      .then((response) => response.data);
  }

  static async getMethods(bookingCode: string): Promise<PaymentMethodResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/methods/`, 'POST')
      .then((response) => response.data)
      .catch((error) => console.debug(error))
  }

  static async createOrder(bookingCode: string): Promise<any> { // @todo: TYPE THIS SHIT
    /**
     * Confirm the booking options by submitting an order. This will request the final pricing to be calculated and
     * sent to the bridge by the tenant.
     */
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/orders/`, 'POST')
      .then((response) => response.data);
  }

	static async getVerificationCode(bookingCode: string, contact: PaymentContact, returnUrl: string): Promise<VerificationCodeResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/code/`, 'POST', {
			contact,
			return_url: returnUrl
		}).then((response) => response.data)
  }

	static async verifyCode(bookingCode: string, verificationCode: string, paymentReference: string): Promise<VerificationCodeResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/code/${paymentReference}/`, 'PATCH', {
			code: verificationCode
		}).then((response) => response.data)
  }

	static async sendPaymentLink(params: PaymentLinkParams): Promise<void> {
		const {bookingCode, contact, returnUrl} = params;
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/payments/adyen/link/`, 'POST', {
			contact,
			return_url: returnUrl,
		}).then((response) => response.data)
  }

  static async getBookingData(bookingCode: string, token: string): Promise<BookingResponse> {
    const headers: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/`, 'GET', {}, undefined, headers).then((response) => response.data)
  }

  static async getFlightData({flight, date}: { flight: string, date: string }, token: string): Promise<FlightSearch> {
		const headers: AxiosRequestConfig | undefined = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

    return Fetcher(`${ENV.API_URL}/flight/schedules/live/${flight}/?departure_date=${date}`, 'GET', undefined, undefined, headers)
      .then((response) => response.data);
  }

	static async getLocationData(id: number, token: string): Promise<any> {
		const headers: AxiosRequestConfig | undefined = {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};

    return Fetcher(`${ENV.API_URL}/locations/${id}/`, 'GET', undefined, undefined, headers)
      .then((response) => response.data);
  }
}

export default PaymentService;
