import React, {useCallback, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {useAppSelector} from "store";
import Step from "../../../../components/containers/Step";
import {useBookingMeta, useOptions, useProperties} from "../../../../hooks";
import useTranslations from "../../../../hooks/useTranslations";
import { goToStep, setBooking } from "../../../../store/booking/bookingReducer";
import { FlightState, Passenger } from "../../../../store/flight/types/store";
import { StepInfoProps } from "../../../types";
import PaxSelectionActive from "./PaxSelectionActive";
import PaxSelectionCompleted from "./PaxSelectionCompleted";
import { setPassengers } from "store/flight/flightReducer";
import { Flow, Steps } from "store/channel/channel.types";

const PaxSelectionStep: React.FC<StepInfoProps> = () => {
  const {translation} = useTranslations();
  const dispatch = useDispatch();
  const {properties} = useProperties();
	const {options: timeSlotOptions} = useOptions();

  const flight = useAppSelector((state) => state.flight as Required<FlightState>);
  const booking = useAppSelector((state) => state.booking);
  const {flow} = useBookingMeta();
  const [, setUpdatedPassengers] = useState<Passenger[]>([])

  const onChange = useCallback(async (passengers: Passenger[]) => {
    setUpdatedPassengers(passengers)

    if (booking.details?.code) return dispatch(setBooking({
      code: booking.details.code,
      flow,
      data: {
        passengers: passengers.map((passenger) => ({
          first_name: passenger.first_name,
          last_name: passenger.last_name,
          items: passenger.luggage,
        })),
      },
      offsets: properties.logistics.offsets,
    }))
  }, [booking.details, flow, dispatch, properties])

	const onEnter = useCallback(() => {
    // If no timeslot options are available, redirect back to first step
    if (Object.keys(timeSlotOptions).length === 0) {
      // according to the flow, find the step which should be redirected to
      const step = flow === Flow.City ? Steps.FLIGHT : Steps.PNR_FLIGHT
      dispatch(goToStep({stepName: step, currentFlow: flow}))
    }
  }, [flow, timeSlotOptions, dispatch])

  const onSubmit = useCallback(async (passengers: Passenger[]) => {
    /*
     * Upon submitting the form, we store the collected data in our redux store.
     */
    setUpdatedPassengers((updatedPassengers) => {
      const first = passengers.map((p) => p.passport)
      const second = updatedPassengers.map((p) => p.passport)
      const isUpdated = !!first.filter(passport => second.indexOf(passport) < 0).length

      // If booking already exists then update booking with new luggage count
      if (isUpdated && booking.details?.code) dispatch(setBooking({
        code: booking.details.code,
        flow,
        data: {
          passengers: passengers.map((passenger) => ({
            first_name: passenger.first_name,
            last_name: passenger.last_name,
            items: passenger.luggage,
          })),
        },
        offsets: properties.logistics.offsets,
      }))

      return passengers
    })

    return await dispatch(setPassengers(passengers));
  }, [booking.details, flow, dispatch, properties])

  const onError = useCallback((error: Error) => {
    //do something with the error

    return {
      message: ''
    }
  }, [])

  const props = useMemo(() => ({
    Active: {data: {...flight}},
    Completed: {data: {passengers: flight.passengers}}
  }), [flight])

  return (<Step
          header={translation.get("passenger:title")}
          Active={PaxSelectionActive}
          Completed={PaxSelectionCompleted}
          onUpdate={onChange}
          onSubmit={onSubmit}
          onError={onError}
					onEnter={onEnter}
          props={props}
  />)
};

export default PaxSelectionStep;