import React, {useMemo} from "react";
import {Flow} from "../store/channel/channel.types";
import {useAppSelector} from "../store";
import {AirportDirection} from "../store/flight/types/enums";

interface Props {
  step: number;
  stepName: string;
  progress: number;
  flow: Flow;
  children: React.ReactNode;
}

export interface MetaContext extends Omit<Props, 'children'> {
  loading: boolean;
  direction: AirportDirection;
}

const Context = React.createContext({} as MetaContext);

export const MetaProvider: React.FC<Props> = (
  {
    progress,
    flow,
    step,
    children,
    stepName
  }
) => {
  /**
   * Allows us to easily retrieved booking related properties across any of the booking steps.
   */
  const loading = useAppSelector((state) => state.app.loading);
  const direction = useMemo(()=> flow === Flow.City ? AirportDirection.Arrival : AirportDirection.Departure, [flow]);

  return <Context.Provider value={{progress, flow, step, loading, direction, stepName}}>{children}</Context.Provider>
}

export function useBookingMeta<T extends MetaContext>() {
  return React.useContext(Context) as T
}

export default useBookingMeta;