import {useAppSelector} from "../../store";
import {ChannelProperties} from "../../store/channel/channel.types";

interface Properties {
  properties: ChannelProperties;
  language: string;
  locale: string;
}

export default function useProperties(): Properties {
  const channel = useAppSelector((state) => state.channel);
  const language = useAppSelector((state) => state.app.currentLanguage || state.channel.properties.language?.meta.default)
  const locale = useAppSelector((state) => state.channel.properties.language.meta.ietf[
    state.app.currentLanguage || state.channel.properties.language.meta.default
  ])

  return {properties: channel.properties, language, locale};
}
