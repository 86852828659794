import React, {useLayoutEffect, useState} from "react";
import BookingDetails from "../BookingDetails/BookingDetails";
import {IBooking} from "../../../store/portal/portalTypes";
import styles from './Booking.module.scss'
import {getLocationById} from "../../../store/portal/portalReducer";
import Icon from "../../../components/icons/Icon/Icon";
import ChevronDown from "../../../resources/svg/ChevronDown.svg";
import Utils from "../../../utils/Utils";
import {useAppDispatch, useAppSelector} from "../../../store";
import {Location} from "../../../store/booking/bookingTypes";

export interface BookingItemProps {
  booking: IBooking;
}

const Booking = ({booking}: BookingItemProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const pickupLocationId = booking.assignments[0].from_location;
  const dropOffLocationId = booking.assignments[0].to_location;

  const pickupLocation = useAppSelector((state) => {
      let filter = (loc: Required<Location>) => loc.id === pickupLocationId

      return state.portal.locations.private.find(filter)
        || state.portal.locations.bagpoint.find(filter)
        || null
    }
  );
  const dropOffLocation = useAppSelector(state => {
    let filter = (loc: Required<Location>) => loc.id === dropOffLocationId

    return state.portal.locations.private.find(filter)
      || state.portal.locations.bagpoint.find(filter)
      || null
  });

  useLayoutEffect(() => {
    if (!pickupLocation) {
      dispatch(getLocationById(pickupLocationId))
    }
    if (!dropOffLocation) {
      dispatch(getLocationById(dropOffLocationId))
    }
  }, [pickupLocation, pickupLocationId, dropOffLocation, dropOffLocationId, dispatch]);

  const toggleExpander = (): void => {
    setExpanded((expanded) => !expanded);
  }

  return (
    <div onClick={toggleExpander} className={styles.container}>
      <div className={styles.row}>
        <div className={styles.column}>
          {booking?.assignments[0].service === 'injection' ?
            `${new Date(Utils.date.parse(booking?.assignments[0]?.from_datetime)).toLocaleDateString()} - ${new Date(Utils.date.parse(booking?.assignments[0]?.from_datetime)).toLocaleTimeString().slice(0, 5)}` :
            `${new Date(Utils.date.parse(booking?.assignments[0]?.to_datetime)).toLocaleDateString()} - ${new Date(Utils.date.parse(booking?.assignments[0]?.to_datetime)).toLocaleTimeString().slice(0, 5)}`
          }
        </div>
        <div className={styles.column}>
          {booking.code.toUpperCase()}
        </div>
        <div className={styles.column}>
          {pickupLocation?.name}
        </div>
        <div className={styles.column}>
          {dropOffLocation?.name}
        </div>
        <div className={styles.column}>
          {booking?.assignments[0]?.luggage_count}
        </div>
        <div className={`${styles.column} ${styles.centered} ${expanded ? styles.rotate : null}`}><Icon
          src={ChevronDown} className={styles["arrow-icon"]}/></div>
      </div>
      {expanded &&
        <div><BookingDetails key={booking.code} code={booking.code} tenantId={booking?.assignments[0]?.tenant}/></div>}
    </div>
  )
}

export default Booking;