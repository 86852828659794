import React from 'react';
import {useLocation, Navigate, Outlet} from 'react-router-dom';
import Authentication from "../utils/authentication";


const PrivateRoute = () => {
  /**
   * Children nested under private routes will only be visible provided that the customer is
   * currently signed in to his account.
   */

    const location = useLocation();
    const isLoggedIn = Authentication.isLoggedIn();

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" state={{from: location}}/>;
}

export default PrivateRoute;