import React from "react";
import useTranslations from "../../../hooks/useTranslations";
import styles from "./IconSection.module.scss";
import {
  IChannelTranslationsState,
  IRegulationItem,
  IRegulationSection
} from "../../../store/channel/channel.types";
import Icon from "../../../components/icons/Icon/Icon";

interface RegulationsProps {
  section: IRegulationSection
}

const IconSection = ({section}: RegulationsProps) => {

  const {translation} = useTranslations();

  return (
    <div className={styles.component}>
      <div className={styles.row}>
        <h3 className={`${styles.sectionName}`}>{translation.get(section.name as keyof IChannelTranslationsState)}</h3>
        <div className={styles.sectionBody}>
          {section.items.map((item: IRegulationItem, index: number) => (
            <div key={index} className={styles.item}>
              <div className={styles.itemHeader}>
                {item.icons.map((iconUrl: string, index: number) => (
                  <Icon key={index} src={iconUrl} className={styles.regulationIcon}/>
                ))}
              </div>
              <p className={styles.itemName}>{translation.get(item.name as keyof IChannelTranslationsState)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default IconSection;