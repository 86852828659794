import {useCallback, useEffect, useRef, useState} from "react";
import Step from "components/containers/Step";
import LoginStepActive from "./LoginStepActive/LoginStepActive";
import LoginStepCompleted from "./LoginStepComplete/LoginStepComplete";
import {getUser} from "store/auth/authReducer";
import {OAuthErrorTypes} from "store/auth/authTypes";
import Authentication from "utils/authentication";
import useTranslations from "hooks/useTranslations";
import {useAppDispatch} from "store";
import {Errors} from "components/containers/Step/types";

export interface LoginFields {
  email: string;
  password: string;
}

const LoginStep = () => {
  const {translation} = useTranslations();
  const dispatch = useAppDispatch();

  const isLoggedIn = Authentication.isLoggedIn();
  const stepSubmit = useRef<(data: any) => Promise<any>>(null);

  const [submitHandler, setSubmitHandler] = useState<((data: any) => Promise<any>) | undefined>(() => isLoggedIn ? undefined : onSubmit);

  const onEnter = useCallback(() => {
    if (!stepSubmit.current) return;

    if (isLoggedIn) return stepSubmit.current({});
  }, [isLoggedIn]);

  const onSubmit = useCallback(async ({email, password}: LoginFields) => {
    if (isLoggedIn) return Promise.resolve();

    if (!email || !password) {
      return Promise.reject(new Error("Invalid input data"));
    }

    return Authentication.auth()
      .then(async (token) => ({
        oldToken: token,
        token: await Authentication.login(email, password),
      }))
      .then(() => dispatch(getUser(email)));
  }, [dispatch, isLoggedIn]);

  const onError = useCallback((error: any): Errors => {
    if (error?.body?.error === OAuthErrorTypes.invalid_grant) {
      return {
        password: translation.get("login:invalid_grant"),
      };
    }

    return {
      password: translation.get("error:general_error"),
    };
  }, [translation]);

  useEffect(() => {
    if (isLoggedIn) {
      setSubmitHandler(undefined);
    } else {
      setSubmitHandler(() => onSubmit);
    }
  }, [isLoggedIn, onSubmit]);

  return (
    <Step
      onEnter={onEnter}
      ref={stepSubmit}
      header={translation.get("login:heading")}
      Active={LoginStepActive}
      Completed={LoginStepCompleted}
      onSubmit={submitHandler}
      onError={onError}
      props={{
        Completed: {},
      }}
    />
  );
};

export default LoginStep;
