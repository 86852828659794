import React from "react";
import CompleteStep from "../../../../../components/CompleteStep";
import useTranslations from "../../../../../hooks/useTranslations";
import {MembershipState} from "../../../../../store/membership/membershipTypes";
import styles from './AltanfeethiComplete.module.scss';
import {useAppSelector} from "../../../../../store";

export interface IFlightInfoCollapsedProps {
  onEdit: (height: number) => void;
}

const AltanfeethiComplete = ({onEdit}: IFlightInfoCollapsedProps) => {
  const {translation} = useTranslations();
  const membership = useAppSelector((state) => state.membership as Required<MembershipState>);

  return (
    <CompleteStep
      id="membership-edit"
      onEdit={onEdit}
      className={styles.container}
    >
      <h2 style={{margin: 0}}>
        {translation.get("membership:completed_heading")} <span className={styles.membershipNumber}>{membership.code}</span>
      </h2>
    </CompleteStep>
  );
};

export default AltanfeethiComplete;