import React, {useState} from 'react';
import styles from './AccordionItem.module.scss';

const AccordionItem = ({title, content}: { title: string, content: string }) => {
  const [isActive, setIsActive] = useState(false);
  const [hover, setHover] = useState<boolean>(false);

  const toggleHover = () => {
    setHover(() => !hover)
  }

  return (
    <div className={styles.accordionItem}>
      <div className={isActive ? `${styles.accordionTitle} ${styles.active}` : styles.accordionTitle} onMouseEnter={toggleHover} onMouseLeave={toggleHover} onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive &&
      <div className={isActive ? `${styles.accordionContent} ${styles.active}` : styles.accordionContent}>{content}</div>}
    </div>
  );
};

export default AccordionItem;