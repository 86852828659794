import Button from "components/inputs/buttons/Button";
import {ColourTypes} from "components/inputs/buttons/Button/Button";
import Modal from "components/modals/Modal";
import { useOptions, useValidation } from "hooks";
import { DateTime, Duration } from "luxon";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import FlightService from "store/flight/flightService";
import Utils from "utils/Utils";
import Step from "../../../../components/containers/Step";
import {Errors} from "../../../../components/containers/Step/types";
import useTranslations from "../../../../hooks/useTranslations";
import {useAppSelector} from "../../../../store";
import {clearBooking} from "../../../../store/booking/bookingReducer";
import {FlightErrorCodes, FlightErrorCodesEnum, Flow} from "store/channel/channel.types";
import {saveJourney, savePnrCode, saveSessionId, setPassengers} from "store/flight/flightReducer";
import {APIReservation, Journey, Passenger} from "store/flight/types/api/reservation";
import {StepInfoProps} from "../../../types";
import StepError from "../StepError";
import {InvalidFlightDate} from "./errors";
import PnrFlightStepActive from "./PnrFlightStepActive";
import PnrFlightStepCompleted from "./PnrFlightStepCompleted";
import styles from "./PnrFlightStep.module.scss"
import ReCAPTCHAv2 from "utils/RecaptchaV2";

export interface PnrFlightFormData {
  pnr: string;
  lastName: string;
}

export interface PNRFlightStepData {
  reservation: APIReservation,
  journey: Journey
}

interface ErrorResponse {
  code?: keyof FlightErrorCodes;
  detail?: {
    [key: string]: any;
  };
}

const getAllowanceSummary = (passengers: Passenger[]) => {
  return passengers.map(passenger => {
    if (!passenger.allowance.length) return 0
    return passenger.allowance.reduce((acc, allowance) => acc + allowance.base * allowance.quantity, 0);
  }).reduce((acc, allowance) => acc + allowance, 0)
}

const PnrFlightStep: React.FC<StepInfoProps> = () => {
  /**
   * This is the first of two steps when retrieving passenger information
   * direction from the airline's apis.
   *
   * This is expected to use the airline's reservation systems in order to retrieve
   * accurate and up-to-date information about the passengers.
   *
   * The information is retrieved from the Flight API.
   * The flight API can respond with bookings containing one or more journeys.
   * If there are more than one valid journeys, we need to present the user with the option to select the
   * journey which he wishes to have serviced.
   *
   * Once the journey is selected, we move to the next step.
   */
  const stepSubmit = useRef<(data: PNRFlightStepData) => Promise<any>>(null);
  const errorRef = useRef<(error: Error) => Errors>(null);

  const {translation} = useTranslations();
  const dispatch = useDispatch();
  const {validateFlightDate} = useValidation();
  const {executeRecaptcha} = useGoogleReCaptcha();
	const {options} = useOptions();

  const properties = useAppSelector((state) => state.channel.properties)
  const flow = useAppSelector((state) => state.app.currentFlow as Required<Flow>);
  const flight = useAppSelector((state) => state.flight.journey);
  const [reservation, setReservation] = useState<Omit<APIReservation, 'journey'>>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [useRecaptchaV2, setUseRecaptchaV2] = useState(false);
  const [isRecaptchaV2Verified, setIsRecaptchaV2Verified] = useState(false);
  const [formData, setFormData] = useState<any>({
    pnr: "",
    lastName: "",
  })

  const journeys = useMemo(() => reservation?.journeys || [], [reservation])

  useEffect(() => {
    /**
     * Automatically set the journey if the reservation only has a single journey
     * and reCAPTCHA v2 has been verified.
     */
    if (!stepSubmit.current || !reservation || !journeys) return;


    if (journeys.length === 1 && !useRecaptchaV2) {
      stepSubmit.current({reservation, journey: journeys[0]});
      setIsRecaptchaV2Verified(false);
    }
  }, [reservation, stepSubmit, journeys, isRecaptchaV2Verified, translation, useRecaptchaV2]);

  const onError = useCallback((error: Error): { pnr?: string, date?: string, lastName?: string } => {
    // Input handling errors
    if (error instanceof StepError) {
      return error.fields;
    }
    if (error instanceof InvalidFlightDate) {
      return {date: error.message};
    }

    // Network errors
    if (!Utils.network.isAxiosError(error)) {
      return {pnr: 'Unknown error'};
    }

    const responseData = error.response?.data as ErrorResponse;
    const errorCode = responseData?.code;

    // Flight error handling
    if (errorCode && errorCode in FlightErrorCodesEnum) {
      return {pnr: translation.get(`error:flights:${errorCode}`)};
    }

    if (error.response?.status === 400) {
      return {pnr: translation.get('error:flights:E_INVALID_PNR_FORMAT')};
    }

    if (error.response?.status === 404) {
      return {pnr: translation.get('error:flights:E_PNR_NOT_FOUND')};
    }

    // Default error handling
    return {pnr: translation.get('error:flights:E_UNKNOWN_ERROR')};
  }, [translation]);

  const getReCaptchaToken = useCallback(async () => {
    if (!executeRecaptcha) return;

    try {
      return await executeRecaptcha('flight');
    } catch (error) {
      console.error(error);
    }
  }, [executeRecaptcha]);

  const verifyReCaptchaV2 = useCallback(async (token: string): Promise<APIReservation> => {
    return FlightService.getFlightInfo(
      properties.flightSearch.connector,
      formData.pnr,
      formData.lastName,
      properties.flightSearch.mock,
      token,
      2
    )
  }, [formData, properties]);

  const handleCaptchaErrors = (message: string) => {
    setIsRecaptchaV2Verified(false);
    setUseRecaptchaV2(false);
    errorRef.current && errorRef.current(new StepError({pnr: message}));
  };

  const onRecaptchaV2Verify = useCallback(async (token: string | null) => {
    if (token === null) {
      handleCaptchaErrors(translation.get("validation:flight:recaptcha"));
      return;
    }

    try {
      const response = await verifyReCaptchaV2(token);
			const {recaptcha} = response;

      if (recaptcha.success) {
        setIsRecaptchaV2Verified(true);
        setUseRecaptchaV2(false);
				setReservation(response);
      } else {
        handleCaptchaErrors(translation.get("validation:flight:recaptcha"));
      }
    } catch (error) {
      console.error(error);
      handleCaptchaErrors(translation.get("validation:flight:recaptcha"));
    }
  }, [translation, verifyReCaptchaV2]);
	
	const onEnter = useCallback(() => {
    /**
     * This behavior is used to display an error if
		 * the user is redirected back here from the location
		 * step due to empty options.
		 * If flight data exists and there are no options
		 * available, inform the user with an error.
     */
    return new Promise<void>((resolve, reject) => {

        if (!!flight && Object.keys(options).length === 0) {
            reject(new StepError({
                pnr: translation.get('error:flights:no_available_timeslot')
            }));
        } else {
					resolve()
        }
    });
}, [flight, options, translation]);



  const onUpdate = async ({pnr, lastName}: PnrFlightFormData) => {
    /**
     * Retrieve the associated reservation from the flight API.
     * If the reservation has multiple journeys, show the modal to the customer and allow him to select the journey of choice.
     */

    const recaptcha = await getReCaptchaToken();

    return FlightService.getFlightInfo(
      properties.flightSearch.connector,
      pnr,
      lastName,
      properties.flightSearch.mock,
      recaptcha,
      3
    ).then((reservation) => {
      const {recaptcha} = reservation;

      if (recaptcha.success && recaptcha.score < 0.5) {
        setUseRecaptchaV2(true);
        setFormData({
          pnr,
          lastName
        })
        return;
      }

      const offset = Duration.fromObject(properties.logistics.boundaries[flow].today?.from || {});

      let journeys = reservation.journey && typeof reservation.journey === 'object'
        ? [reservation.journey]
        : reservation.journeys;

      reservation.journeys = journeys

      // Validate reservation content
      const errors: string[] = [];
      journeys = reservation.journeys.filter((journey) => {
        const error = validateFlightDate(
          DateTime.fromISO(journey.departure.schedule.datetime_local),
          offset
        );

        if (error) errors.push(error);

        // filter out the journey only if there's no error
        return !error;
      }) || [];

      if (!journeys.length && errors) {
        throw new StepError({
          pnr: errors ? errors[0] : 'Unknown error'
        })
      }

      const hasMultipleJourneys = journeys && journeys.length > 1;
      if (reservation && hasMultipleJourneys) setModalOpen(true);

      const allowanceSummary = getAllowanceSummary(reservation.passengers)
      if (allowanceSummary === 0) throw new StepError({
        pnr: translation.get("validation:flight:no_allowance")
      })

      // If valid submit
      setReservation({
        ...reservation,
        journeys
      });
    })
  }

  const onSubmit = useCallback(async ({reservation, journey}: PNRFlightStepData) => {
    /**
     * Save the collected data into the store and go to the next step.
     */

    return Promise.all([
      dispatch(saveJourney(journey)),
      dispatch(savePnrCode(reservation.record_locator)),
      dispatch(saveSessionId(reservation.session)),
      dispatch(setPassengers(reservation.passengers.map((passenger) => ({
        ...passenger,
        luggage: 0,
        passport: '',
      })))),
    ])
  }, [dispatch])

  const onEdit = useCallback(async () => {
    return dispatch(clearBooking())
  }, [dispatch])

  const onModalJourneySelection = useCallback((journey: Journey) => {
    /**
     * Close modal on journey selection and submit the information to the onSubmit method.
     */

    if (stepSubmit.current && reservation) {
      setModalOpen(false);
      stepSubmit.current({reservation, journey});
    }
  }, [reservation])

  return (
    <>
      <Modal
        isShown={useRecaptchaV2}
        modalContent={
          <div className={styles.recaptchaContainer}>
            <span className={styles.recaptchaText}>{translation.get("validation:flight:recaptcha_heading")}</span>
            <ReCAPTCHAv2 onVerify={onRecaptchaV2Verify} />
          </div>
        }
        headerText={""}
      />
      <Modal
        isShown={modalOpen}
        modalContent={<div className={styles.journeyList}>
          {journeys.map((journey: Journey) =>
            <Button
              key={journey.departure.id}
              color={ColourTypes.primary}
              className={styles.journeyButton}
              text={`${journey.departure.schedule.airport.city} => ${journey.arrival.schedule.airport.city}`}
              onClick={() => onModalJourneySelection(journey)}
            />
          )}
        </div>}
        hide={() => setModalOpen(false)}
        headerText={translation.get("flight:heading")}
      />
      <Step
        errRef={errorRef}
        ref={stepSubmit}
        header={translation.get("flight:heading")}
        Active={PnrFlightStepActive}
        Completed={PnrFlightStepCompleted}
				onEnter={onEnter}
        onSubmit={onSubmit}
        onUpdate={onUpdate}
        onError={onError}
        onEdit={onEdit}
        props={{
          Completed: {data: {flow}}
        }}
      />
    </>
  )
};

export default PnrFlightStep;
