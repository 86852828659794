import ENV from "../../utils/environments";
import Fetcher from "../../utils/fetcher";
import { AxiosResponse } from "axios";
import { IChannel } from "./channel.types";

interface IChannelServiceResponse {
  success: boolean;
  data?: IChannel;
  error?: string;
}

class ChannelService {
  static async getChannel(): Promise<IChannelServiceResponse> {
    try {
      const response: AxiosResponse<IChannel> = await Fetcher(`${ENV.API_URL}/channels/current/`, 'GET');
      return {
        success: true,
        data: response.data
      };
    } catch (error: any) {
      return {
        success: false,
        error: 'Something went wrong, please try again later.'
      };
    }
  }
}

export default ChannelService;
