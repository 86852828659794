import * as React from "react";
import styles from "./ValidationError.module.scss";

interface IValidationErrorProps {
  error?: string;
	className?: string;
	onClick?: () => void;
}

const ValidationError = ({error, className, onClick}: IValidationErrorProps) => {
  if (error)
    return (
      <div
				onClick={onClick}
        data-error={!!error}
        className={`${styles.message + " error"} ${onClick ? styles.pointer : ""} ${className}`}
      >
        {error || ""}
      </div>
    );

  return <></>;
};

export default ValidationError;
