import {useLayoutEffect, useMemo} from "react";
import useTranslations from "../../../../hooks/useTranslations";
import styles from "./DropOffStep.module.scss";
import sharedStyles from "../../../../styles/Shared.module.scss";
import {Location} from "../../../../store/booking/bookingTypes";
import {getLocationById} from "../../../../store/portal/portalReducer";
import {ITenant} from "../../../../store/portal/portalTypes";
import TenantInformation from "../../TenantInformation/TenantInformation";
import InfoCircle from "../../../../resources/svg/InfoCircle.svg";
import {Flow} from "../../../../store/channel/channel.types";
import {useAppDispatch, useAppSelector} from "../../../../store";
import {BookingState} from "../../../../store/booking/types/store";

interface DropOffStepProps {
  booking: BookingState;
  type: Flow;
  tenant?: ITenant;
  bookingDetails?: JSX.Element;
}

const DropOffStep = ({booking, type, tenant, bookingDetails}: DropOffStepProps) => {
  const dispatch = useAppDispatch();
  const {translation} = useTranslations();
  const dropOffLocationId = booking.details?.assignments ? booking.details.assignments[0].to_location : '';
  const dropOffLocation: Location | undefined = useAppSelector(state => {
    return state.portal.locations.private.find((loc: Required<Location>) => loc.id === dropOffLocationId)
      || state.portal.locations.bagpoint.find((loc: Required<Location>) => loc.id === dropOffLocationId)
      || null
  });

	const formattedAddress = useMemo(() => {
		const address = dropOffLocation?.address;

		if (!address || type === Flow.Airport) return dropOffLocation?.name;

		return `${address.number}, ${address.street ? `${address.street}, ` : ""}${address.city}, ${address.region}, ${address.postal_code}, ${address.country}`
	}, [dropOffLocation, type])

  useLayoutEffect(() => {
    if (!dropOffLocation && dropOffLocationId) dispatch(getLocationById(dropOffLocationId));
  }, [dropOffLocation, dropOffLocationId, dispatch]);

  return (
    <div className={styles.container}>
      {bookingDetails ? bookingDetails : <h2 className={sharedStyles.cardText}>{translation.get('thank_you:dropoff:title')}</h2>}
      <h2 className={`${sharedStyles.cardText}`}>{formattedAddress} </h2>
      {tenant && <div>
        <h2 className={sharedStyles.cardText}>{translation.get('thank_you:pickup:tenant')}
          <span className={`${sharedStyles.cardText}`}>{` ${tenant.name}`}</span>
        </h2>
        <TenantInformation icon={InfoCircle} tenant={tenant}/>
      </div>}
      {type === Flow.Airport ?
        <h2 className={sharedStyles.cardText}>{translation.get('thank_you:dropoff:subtitle')}</h2> : null}
    </div>
  );
}

export default DropOffStep;