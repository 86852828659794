import React, {useLayoutEffect, useState} from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import useTranslations from "../../../../../hooks/useTranslations";
import {Coordinates, Location} from "../../../../../store/booking/bookingTypes";
import Utils from "../../../../../utils/Utils";
import styles from "./PlacesAutocomplete.module.scss";
import {AddressStyle} from "../../../../../store/channel/channel.types";
import {useAppSelector} from "../../../../../store";

interface PlacesAutoCompleteProps {
  coordinates: Coordinates;
  style: React.CSSProperties;
  map: google.maps.Map | undefined;
  onUpdate: (location: Location) => void
}

export const PlacesAutoComplete: React.FC<PlacesAutoCompleteProps> = (
  {
    coordinates,
    style,
    map,
    onUpdate
  }
) => {

  const {translation} = useTranslations();

  const disableEmptyAddress = useAppSelector((state) => state.channel.properties.switches.addressStyle === AddressStyle.DEFAULT);
  const country = useAppSelector((state) => state.channel.country);
  const location = useAppSelector((state) => state.booking.meeting?.location);

  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions,
    init
  } = usePlacesAutocomplete({
    requestOptions: {
      location: window?.google?.maps && new window.google.maps.LatLng(coordinates.latitude, coordinates.longitude),
      radius: 5000,
      types: ["address"],
      componentRestrictions: {country: country.toLowerCase()},
    },
    initOnMount: false,
    debounce: 300,
  });

  useLayoutEffect(() => {
    if (map !== undefined) init();
  }, [init, map])

  useLayoutEffect(() => {
    setOptions(data);
  }, [data])

  useLayoutEffect(() => {
    if (location === undefined) return;

    setValue(location.name, false)
  }, [location, setValue])

  const ref = useOnclickOutside(() => clearSuggestions());
  const handleInput = (e: any) => setValue(e.target.value);
  const handleSelect = (val: any) => () => {
    /**
     * When user selects a place, we can replace the keyword without request data from API
     * by setting the second parameter to "false"
     */

    setValue(val.description, false);
    clearSuggestions();

    Utils.geo.getGoogleGeocode({placeId: val.place_id})
      .then((response) => {
        const address = Utils.geo.toLocation(response.addressInfo, response.coordinates)
        if (disableEmptyAddress && (!address.address.street || !address.address.number)) return;
        onUpdate(address)
      })
  };

  return (
    <div ref={ref}>
      <input
        type="text"
        placeholder={translation.get("location:address_placeholder")}
        value={value}
        onChange={handleInput}
        disabled={!ready}
        className={styles["input"]}
      />

      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {(options.length > 0 && status === "OK") && (
        <ul className={styles["list"]} style={style}>
          {
            options.map((suggestion) => {
              const {
                place_id,
                structured_formatting: {main_text, secondary_text},
              } = suggestion;

              return (
                <li
                  className={styles["list-item"]}
                  key={place_id}
                  onClick={handleSelect(suggestion)}>
                  {main_text}, {secondary_text}
                </li>
              );
            })
          }
        </ul>
      )}
    </div>
  );
};
