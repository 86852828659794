import Icon from "../Icon";
import Circle from "../../../resources/svg/InfoCircle.svg";
import React from "react";

interface Props {
  className?: string;
}

const InfoCircle: React.FC<Props> = ({className='' }) => {
  return <Icon
    className={`${className}`}
    src={Circle}
  />
}

export default InfoCircle;