import {motion, Transition} from "framer-motion";
import React, {useRef} from "react";
import Utils from "../../../utils/Utils";

export interface IMotionDivProps {
  child: React.ReactNode;
  isComplete?: (ref: HTMLDivElement) => void;
  onClick?: (...props: any[]) => any;
  transition?: Transition;
}


const MotionDiv = ({child, isComplete, onClick, transition}: IMotionDivProps) => {
  /**
   * A container which opens up vertically with an animation upon being made visible.
   */

  const ref = useRef<HTMLDivElement>(null);

  return (
    <motion.div
      ref={ref}
      onClick={onClick}
      onAnimationComplete={
        () => isComplete
          && ref.current !== null
          && !Utils.layout.isInViewport(ref.current)
          && isComplete(ref.current)
      }
      initial={{height: '60px', opacity: 0, padding: '30px 30px 0 30px', overflow: 'hidden'}}
      animate={{height: 'auto', opacity: 1, padding: '30px 30px 30px 30px', transitionEnd: {overflow: "initial"}}}
      transition={transition || {ease: "easeIn", duration: 0.5, overflow: 'hidden'}}
    >
      {child}
    </motion.div>
  )
}

export default MotionDiv;