import React from "react";
import styles from "./Spinner.module.scss";

interface ICustomSpinnerProps {
  className?: string;
}

const Spinner = ({className}: ICustomSpinnerProps) => {

  return (
    <div className={`${className} ${styles.container}`}>
      <div className={styles.spinner} >
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    </div>
  )
}

export default Spinner;