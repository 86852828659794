import React, {useCallback} from "react";
import useTranslations from "../../../../hooks/useTranslations";
import AltanfeethiActive from './AltanfeethiActive';
import VerificationInfoComplete from './AltanfeethiComplete';
import "react-datepicker/dist/react-datepicker.css";
import {useBookingMeta} from "../../../../hooks";
import {StepInfoProps} from "../../../types";
import Step from "../../../../components/containers/Step";
import {setMembershipCode} from "../../../../store/membership/membershipReducer";
import {updateProgress} from "../../../../store/booking/bookingReducer";
import {useAppDispatch} from "../../../../store";

const AltanfeethiStep: React.FC<StepInfoProps> = () => {
  /**
   * Used specifically for collecting the membership code for altanfeethi customers.
   *
   * This code is required for altanfeethi customers in order to make a booking.
   */
  const dispatch = useAppDispatch();
  const {translation} = useTranslations();
  const {step} = useBookingMeta();

  const onSubmit = useCallback(async (code: string) => Promise.all([
    dispatch(setMembershipCode(code)),
    dispatch(updateProgress(step + 1))
  ]), [dispatch, step])

  return (
    <Step
      header={translation.get("location:heading:airport")}
      Active={AltanfeethiActive}
      Completed={VerificationInfoComplete}
      onSubmit={onSubmit}
    />
  );
};

export default AltanfeethiStep;
