import React, {useCallback, useRef, useState} from "react";
import Button from "../../../../../components/inputs/buttons/Button";
import useTranslations from "../../../../../hooks/useTranslations";
import styles from "./AltanfeethiActive.module.scss";
import MembershipCode, {MembershipRef} from "./MembershipCode/MembershipCode";
import {ActiveStepProps} from "../../../../../components/containers/Step/types";

const AltanfeethiActive = ({onSubmit}: ActiveStepProps) => {
  /**
   * A form controller to collect the altanfeethi member code.
   */

  const {translation} = useTranslations();
  const formRef = useRef<MembershipRef>(null)
  const [error, setError] = useState<string>('');

  const onButtonPress = useCallback(()=>{
    if(!formRef.current) return;

    const form = formRef.current.getFormState();
    if(form.status === 'error') setError(form.value);
    else onSubmit(form.value);
  }, [onSubmit, formRef])

  return (
    <>
      <h2 className={styles.heading}>
        {translation.get("membership:heading")}
      </h2>

      <MembershipCode ref={formRef} error={error}/>

      <div className={styles["submit-button-wrapper"]}>
        <Button
          type="submit"
          id="membership-submit"
          text={translation.get("button:next")}
          onClick={onButtonPress}
        />
      </div>
    </>
  );
}

export default AltanfeethiActive;