import {useEffect, useState} from "react";

const useDirection = () => {
  const [dir, setDir] = useState(document.body.getAttribute("dir"));

  useEffect(() => {
    const updateDir = () => {
      setDir(document.body.getAttribute("dir"));
    };

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "attributes" && mutation.attributeName === "dir") {
          updateDir();
        }
      });
    });

    const config = {attributes: true};

    observer.observe(document.body, config);

    updateDir();

    return () => observer.disconnect();
  }, []);

  return dir;
}

export default useDirection;