import React, {memo, useLayoutEffect, useRef} from "react";
import {usePrevious} from "../../../hooks";
import {ChannelProperties} from "../../../store/channel/channel.types";
import styles from "./SingleInput.module.scss";

export interface SingleInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  properties: ChannelProperties
  focus?: boolean;
  customProperty?: boolean;
  onBlur?: (e: React.FocusEvent) => React.FocusEventHandler<HTMLInputElement> | void;
}

export function SingleInputComponent(
  {
    focus,
    autoFocus,
    properties,
    customProperty,
    onBlur,
    ...rest
  }: SingleInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <input
    autoComplete={"off"}
    data-customproperty={customProperty}
    onBlur={onBlur}
    className={styles["airline-code"]}
    ref={inputRef}
    maxLength={1}
    {...rest}
  />;
}

export default memo(SingleInputComponent);