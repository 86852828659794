import {createMigrate} from "redux-persist";
import migrations from "./migrations";
import Utils from "../utils/Utils";

class Version {
  private static map: { [key: string]: Function } = migrations

  static compare(a: string, b: string) {
    /**
     * Semantic version comparison.
     *
     * If given two versions M.m.p where (
     *    M = Major
     *    m = Minor
     *    p = Patch
     * ) the version which is semantically higher, which is to say that any numerical comparison from left to right
     * is higher than the other, will be returned.
     */
    // Input validation
    [a, b].forEach((value) => {
      if (value === "") throw Error("Value cannot be empty")
    })

    // Split version into parts
    const versionA = a.split('.').map(Number);
    const versionB = b.split('.').map(Number);

    // Compare versions
    for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
      if ((versionA[i] || 0) > (versionB[i] || 0)) return 1;
      if ((versionA[i] || 0) < (versionB[i] || 0)) return -1;
    }

    return 0;
  }

  static get(): number;
  static get(version: string): number;
  static get(version: number): string;
  static get(version?: string | number): number | string {
    /**
     * Return the specified version id.
     *
     * If this version does not exist or the version is not provided,
     * we return last version with a defined migration.
     *
     * If a number is provided, we return the matching semantic version instead.
     */
    const indexed = Utils.object
      .entries(Version.map)
      .sort(([a,], [b,]) => this.compare(a, b))

    if (typeof version === "string") {
      const index = indexed.findIndex(([key,]) => key === version);
      if (index > -1) return index;
    }

    if (version === undefined || typeof version === "string") return Object.keys(Version.map).length - 1;

    return indexed[version][0];
  }

  static migrations() {
    /**
     * Returns a MigrationManifest with keys mapped to a numerical representation as expected by redux-persist.
     *
     * This will convert a semantically versioned migration object to a numerical object.
     * {
     *   2.0.0: (_)=>({test: 1}),
     *   1.0.0: (_)=>({blah: 'super'}),
     *   1.0.2: (_)=>({blih: 'yo'}),
     * }
     *
     * would turn into:
     * {
     *   0: (_)=>({blah: 'super'}),
     *   1: (_)=>({blih: 'yo'}),
     *   2: (_)=>({test: 1}),
     * }
     *
     * Details regarding this format can be seen here:
     * https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
     */
    const mapped = Object.entries(Version.map)
      .sort(([a,], [b,]) => this.compare(a, b))
      .map(([, method], index) => [index, method])

    return Object.fromEntries(mapped);
  }
}

class Migration {
  private readonly version: string;
  private readonly storage: any;
  private readonly key: string;

  constructor(storage: any, version: string, key: string = 'root') {
    this.key = key;
    this.storage = storage;
    this.version = version;
  }

  getConfig(debug: boolean = false) {
    /**
     * Create the configuration required to handle redux persist migrations.
     */

    const map = Version.migrations();
    const version = Version.get(this.version);

    return {
      key: this.key,
      storage: this.storage,
      version,
      migrate: createMigrate(map, {debug})
    }
  }
}

export default Migration;