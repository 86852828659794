import "react-phone-number-input/style.css";
import "@adyen/adyen-web/dist/adyen.css";
import "./CheckoutStyles.scss";
import {useNavigate} from "react-router-dom";
import useTranslations from "../../../../hooks/useTranslations";
import {FlightState} from "../../../../store/flight/types/store";
import GoogleTagManager from "../../../../utils/GoogleTagManager";
import {calculatePrice} from "../../../../utils/price";
import PaymentStepActive from './PaymentStepActive';
import {useCallback, useMemo} from "react";
import {useBookingMeta, useProperties} from "../../../../hooks";
import Step from "../../../../components/containers/Step";
import {
  getAdyenSession,
  setContactDetails,
  clearPaymentData,
  setAdyenSession,
  setPaymentResult,
	sendPaymentLink
} from "../../../../store/payment/paymentReducer";
import {getUser} from "../../../../store/auth/authReducer";
import {setBookingPayment} from "../../../../store/booking/bookingReducer";
import {useAppDispatch, useAppSelector} from "../../../../store";
import {
  PaymentState,
  Payment,
  AdyenResult,
  PaymentContact
} from "../../../../store/payment/paymentTypes";
import {BookingState} from "../../../../store/booking/types/store";
import { toast } from "react-toastify";

const PaymentStep = (): JSX.Element => {
  /**
   * The payment info page requests the payment information from the customer
   * and charges him the amount specified in the API generated invoice.
   *
   * Furthermore, this step also handles user creation should the customer request
   * it.
   */

  const {properties} = useProperties();
  const {translation} = useTranslations();
  const {flow} = useBookingMeta();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createAccount = useAppSelector(state => state.auth.createAccount);
  const currentLanguage = useAppSelector(state => state.app.currentLanguage);
  const user = useAppSelector(state => state.auth.user)
  const booking = useAppSelector((state) => state.booking as Required<BookingState>);
  const payment = useAppSelector((state) => state.payment as Required<PaymentState>);
  const flight = useAppSelector((state) => state.flight as Required<FlightState>);
  const language = useMemo(() => currentLanguage || properties.language?.meta.default, [currentLanguage, properties.language]);

  const onEnter = useCallback(() => {
  }, [])

  const onExit = useCallback(() => {
    /**
     * Always remove the session data when exiting the page.
     *
     * Redirects are handled by extracting the session ID from the URL.
     */

    dispatch(clearPaymentData());
  }, [dispatch])

  const onPaymentCompleted = useCallback((result: AdyenResult) => {
    /**
     * Update the booking status if the payment or the booking details change.
     */
    dispatch(setPaymentResult(result));
    window.history.pushState({}, document.title, window.location.pathname);

    if (['Cancelled', 'Refused'].includes(result.resultCode)) {
      dispatch(getAdyenSession({bookingCode: booking.details.code, contact: payment.contact}));
    }

    if (['Authorised', 'Pending', 'Received'].includes(result.resultCode)) {
      const gross = {
        price: calculatePrice(booking.budget.price.gross, booking.budget.currency.exponent),
        currency: booking.budget.currency.code
      }

      GoogleTagManager.clearECommerce();
      GoogleTagManager.event("purchase", {
        ecommerce: {
          transaction_id: booking.details.code,
          currency: gross.currency,
          value: gross.price,
          items: [
            {
              item_name: booking.details.assignments[0].service,
              quantity: booking.details.assignments[0].luggage_count,
            }
          ]
        }
      })

      navigate('/summary', {
        state: {
          payment: payment.result
        }
      })
    }
  }, [dispatch, booking, navigate, payment.result, payment.contact])

  const onUpdate = useCallback(async (
    {
			paymentLink,
      email,
      payment,
      contact,
      resetBookingPayment
    }: {
			paymentLink: boolean,
      email?: string,
      payment?: Payment,
      contact?: PaymentContact;
      resetBookingPayment?: boolean;
    }) => {
    /**
     * Whenever the form data is updated we make a request to the API either to create or update
     * the booking information.
     */

    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

		// If payment link flow is used, there is no need to mount the Adyen component
    if (paymentLink && contact && booking?.details?.code) {
	
			const returnUrlObj = {
				flightNumber: flight?.journey?.departure.designator,
				departureDate: flight?.journey?.departure.schedule.datetime_local,
				bookingCode: booking.details?.code,
			};
			const returnUrl = new URLSearchParams(returnUrlObj).toString();

      const resultAction = await dispatch(sendPaymentLink({bookingCode: booking.details.code, contact, returnUrl: `?${returnUrl}`}));
      if (sendPaymentLink.fulfilled.match(resultAction)) {
        toast.success(translation.get("payment:link:success"));
      } else if (sendPaymentLink.rejected.match(resultAction)) {
        toast.error(translation.get("payment:link:error"));
      }
    } else {
      if (sessionId) {
        dispatch(setAdyenSession({id: sessionId}));
      } else if (contact && booking?.details?.code) {
        dispatch(getAdyenSession({bookingCode: booking.details.code, contact}));
      }
    }
		
    if (email) dispatch(getUser(email));
    if (payment) dispatch(setBookingPayment(payment));
    if (contact) dispatch(setContactDetails(contact));
    if (resetBookingPayment) dispatch(setBookingPayment(undefined));
  }, [flight?.journey?.departure.designator, flight?.journey?.departure.schedule.datetime_local, booking.details?.code, dispatch, translation])

  return (
    <Step
      header={translation.get("payment:heading")}
      Active={PaymentStepActive}
      onEnter={onEnter}
      onExit={onExit}
      onUpdate={onUpdate}
      ready={payment.session !== undefined && payment.methods !== undefined}
      props={{
        Active: {
          onPaymentCompleted,
          data: {
            createAccount,
            properties,
            booking,
            user,
            language,
            payment,
            flow,
            flight
          }
        },
      }}
    />
  );
};

export default PaymentStep;
