import styles from "./LanguageDropdown.module.scss";
import BaseDropdown, { DropdownItem } from "../BaseDropdown";
import LanguageOption from "./Options/LanguageOption";
import Icon from "../../../icons/Icon/Icon";
import World from "../../../../resources/svg/World.svg";
import React, { useEffect, useState, useCallback } from "react";
import { setLanguage } from "../../../../store/app/appReducer";
import Language from "../../../../hooks/useProperties/language";
import { useAppDispatch, useAppSelector } from "../../../../store";


interface LanguageSelectProps {
  containerClass?: string;
  selectClass?: string;
  listClass?: string;
  selectStyle?: { [key: string]: React.CSSProperties }
  languageOptionStyle?: React.CSSProperties
}

const LanguageDropdown = (
  {
    containerClass,
    selectClass,
    listClass
  }: LanguageSelectProps) => {
  /**
   * A language selection dropdown which allows our customers to select the language to display
   * within the webfunnel.
   *
   * Selecting a new language will dispatch a change to the redux store thereby defining the language
   * which will be retrieved by the useLanguage hook.
   */
  const dispatch = useAppDispatch();
  const properties = useAppSelector((state) => state.channel.properties)
  const currentLanguage = useAppSelector((state) => state.app.currentLanguage || properties.language?.meta.default);
  const availableLanguages = properties.language?.meta?.available;
  const [languageOptions, setLanguageOptions] = useState<DropdownItem<string>[]>([{ value: '', label: '' }]);

  const languages = properties.language?.meta?.repr;
  const languageLabel = languages[currentLanguage];

  const setCurrentLanguage = useCallback((language: DropdownItem<string>) => {
    dispatch(setLanguage(language.value));
    Language.change(language.value);
  }, [dispatch])

  useEffect(() => {
    const options: Array<DropdownItem<string>> = [{ value: '', label: '' }];

    if (languages && availableLanguages) {
      const languageCodes = Object.keys(languages);
      const languageNames = Object.values(languages);

      for (let i = 0; i < languageCodes.length; i++) {
        options[i] = {
          value: languageCodes[i],
          label: languageNames[i] as string,
          onClick: (item: DropdownItem<string>) => {
            setCurrentLanguage(item);
          }
        }
      }
      setLanguageOptions(() => options.filter((item) => availableLanguages.includes(item.value)).sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0)));
    }
  }, [languages, availableLanguages, setCurrentLanguage])

  return (
    <span className={containerClass || styles.dropdownContainer}>
      <BaseDropdown
        listClass={listClass}
        className={selectClass || styles.dropdown}
        value={languageLabel}
        items={languageOptions}
        Component={({item, index, open}) =>
          <LanguageOption
						selected={item.value === currentLanguage}
            language={item}
            key={index}
            open={open}
            onClick={(language: { value: string, label: string }) => setCurrentLanguage(language)}
          />}
        icon={() =>
          <Icon
            src={World}
            className={styles.icon}
          />
        }
      />
    </span>
  )
}

export default LanguageDropdown;