import Icon from "../Icon";
import React, {useMemo} from "react";
import styles from "./TimeIcon.module.scss";
import Time from "../../../resources/svg/Time.svg";

interface Props {
  className?: string;
}

const TimeIcon: React.FC<Props> = ({className}) => {
  const classNames = useMemo(()=>{
    return `${className ? className : ''} ${styles.timeIcon}`
  }, [className])

  return <Icon src={Time} className={classNames} />
}


export default TimeIcon;