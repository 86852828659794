import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";
import ENV from 'utils/environments';
import "./index.css";
import store from "./store";
import { persistor } from "./store/store";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import routes from 'routes';
import { ToastContainer } from 'react-toastify';

const container = document.getElementById('root');
const root = createRoot(container!);

const router = createBrowserRouter(routes, { basename: ENV.BASE_PATH });

root.render(
	<GoogleReCaptchaProvider reCaptchaKey={ENV.RECAPTCHA_V3_KEY}>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ToastContainer />
				<RouterProvider router={router} />
				<div id="modal" />
				<div id="cookieConsent" />
			</PersistGate>
		</Provider>
	</GoogleReCaptchaProvider>
);
