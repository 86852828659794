export interface IAuthState {
  token: string | null;
  response: string;
  user: UserResponse;
  createAccount: boolean
}

export interface OAuth2Error {
  body: {
    error: OAuthErrorTypes,
    error_description: string,
    error_uri?: string
  }
}

export enum OAuthErrorTypes {
  invalid_request = 'invalid_request',
  invalid_client = 'invalid_client',
  invalid_grant = 'invalid_grant',
  invalid_scope = 'invalid_scope',
  unauthorized_client = 'unauthorized_client',
  unsupported_grant_type = 'unsupported_grant_type'
}

export interface ContactResponse {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  user?: number
}

export interface UserRegistrationData {
  email: string,
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  password: string;
}

export interface UserResponse {
  id?: number,
  email: string,
  first_name: string;
  last_name: string;
  phone_number?: string;
  groups: GroupResponse[];
  verified: boolean
}

export interface GroupResponse {
  id: number;
  name: string;
  domain: {
    name: string;
    type: string
  }
}
