import Icon from "../Icon";
import BagpointRound from "../../../resources/svg/BagpointRound.svg";
import React from "react";
import styles from "./LuggageIcon.module.scss";

interface Props {
  className?: string;
}

const LuggageIcon: React.FC<Props> = ({className}) => {
  return <Icon
    className={`${className} ${styles.luggageIcon}`}
    src={BagpointRound}
  />
}

export default LuggageIcon;