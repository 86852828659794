import "react-phone-number-input/style.css";
import {useNavigate} from "react-router-dom";
import useTranslations from "hooks/useTranslations";
import {FlightState} from "store/flight/types/store";
import {useCallback, useMemo} from "react";
import {useBookingMeta, useProperties} from "hooks";
import Step from "components/containers/Step";
import {clearPaymentData, getVerificationCode, setContactDetails, clearVerificationCode, verifyCode, setPaymentResult} from "store/payment/paymentReducer";
import {useAppDispatch, useAppSelector} from "store";
import {PaymentState, PaymentContact} from "store/payment/paymentTypes";
import {BookingState} from "store/booking/types/store";
import NeomPaymentStepActive from "./PaymentStepActive/NeomPaymentStepActive";
import GoogleTagManager from "utils/GoogleTagManager";
import { calculatePrice } from "utils/price";

interface onUpdateCallBackParams {
  bookingCode?: string,
  contact?: PaymentContact,
  verificationCode?: string,
  reference?: string,
}

const NeomPaymentStep = (): JSX.Element => {
  const {properties} = useProperties();
  const {translation} = useTranslations();
  const {flow} = useBookingMeta();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createAccount = useAppSelector(state => state.auth.createAccount);
  const currentLanguage = useAppSelector(state => state.app.currentLanguage);
  const user = useAppSelector(state => state.auth.user)
  const booking = useAppSelector((state) => state.booking as Required<BookingState>);
  const payment = useAppSelector((state) => state.payment as Required<PaymentState>);
  const flight = useAppSelector((state) => state.flight as Required<FlightState>);
  const language = useMemo(() => currentLanguage || properties.language?.meta.default, [currentLanguage, properties.language]);

  const onEnter = useCallback(() => {
  }, [])

  const onExit = useCallback(() => {

    dispatch(clearPaymentData());
  }, [dispatch])

  const onPaymentCompleted = useCallback(() => {

		const {budget} = booking;

		const gross = {
			price: calculatePrice(budget.price.gross, budget.currency.exponent),
			currency: budget.currency.code
		}

		GoogleTagManager.clearECommerce();
		GoogleTagManager.event("purchase", {
			ecommerce: {
				transaction_id: booking.details.code,
				currency: gross.currency,
				value: gross.price,
				items: [
					{
						item_name: booking.details.assignments[0].service,
						quantity: booking.details.assignments[0].luggage_count,
					}
				]
			}
		})

		dispatch(setPaymentResult({sessionData: "", resultCode: "Authorised"}));
		navigate('/summary');
  }, [booking, navigate, dispatch])


	const onUpdate = useCallback(async ({ bookingCode, contact, verificationCode, reference }: onUpdateCallBackParams) => {
		// Clear verification if no contact or verificationCode is provided
		if (!contact && !verificationCode) {
			return dispatch(clearVerificationCode());
		}
	
		// Update contact details
		if (contact) {
			dispatch(setContactDetails(contact));
		}
	
		// Request verification code if only bookingCode and contact are provided
		if (bookingCode && contact && !verificationCode) {
			return dispatch(getVerificationCode({ bookingCode, contact, returnUrl: "/" }));
		}
	
		// Verify code if all required information is provided
		if (bookingCode && verificationCode && reference) {
			return dispatch(verifyCode({ bookingCode, verificationCode, paymentReference: reference, expiryTimestamp: payment.verification?.expiryTimestamp }));
		}
	}, [dispatch, payment.verification?.expiryTimestamp]);

  return (
    <Step
      header={translation.get("payment:heading")}
      Active={NeomPaymentStepActive}
      onEnter={onEnter}
      onExit={onExit}
      onUpdate={onUpdate}
      props={{
        Active: {
          onPaymentCompleted,
          data: {
            createAccount,
            properties,
            booking,
            user,
            language,
            payment,
            flow,
            flight
          }
        },
      }}
    />
  );
};

export default NeomPaymentStep;
