import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import Spinner from "../../components/Spinner";
import useTranslations from "../../hooks/useTranslations";
import AuthService from "../../store/auth/authService";
import styles from './EmailVerification.module.scss';

const EmailVerification = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {translation} = useTranslations();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedToken = urlParams.get('token');
    if (!encodedToken) return;

    let decodedToken = '';
    try {
      decodedToken = atob(encodedToken);
    } catch {
      navigate(location.pathname);
    }

    const tokenRegex  = decodedToken.match(/token=(.*?),/);
    const idRegex = decodedToken.match(/id=(.*?)$/);

    if (!tokenRegex || !idRegex) return;
    const id = idRegex[1];
    const token = tokenRegex[1];
    if (!id || !token) return;
    if (!translation.get("email:verified:success")) return;

    AuthService.verifyUser(id, token)
      .then(() => {
        toast.success(translation.get("email:verified:success"));
        navigate('/login');
      })
      .catch((error) => {
        console.error(error);
        toast.error(translation.get("email:verified:error"));
        navigate('/login');
      })
  }, [translation, location.pathname, navigate])

  return (
    <div className={styles.page}>
      <Spinner/>
    </div>
  )
}

export default EmailVerification;