import React, {useLayoutEffect, useState} from "react";
import useTranslations from "../../../hooks/useTranslations";
import {getInvoiceDetails, getTenantDetails} from "../../../store/portal/portalReducer";
import {IInvoiceResponse, ITenant} from "../../../store/portal/portalTypes";
import styles from './BookingDetails.module.scss';
import {price} from "../../../utils/price";
import {useAppDispatch, useAppSelector} from "../../../store";

export interface BookingDetailsProps {
  code: string;
  tenantId: number;
}

interface Prices {
  net: string,
  gross: string,
  vat: string
}

const BookingDetails = ({code, tenantId}: BookingDetailsProps) => {

  const dispatch = useAppDispatch();
  const {translation} = useTranslations();

  const formatCodes = useAppSelector(state => state.channel.properties.language.meta.ietf)
  const invoice = useAppSelector(state => state.portal.invoices.find((invoice: IInvoiceResponse) => invoice.bookingCode === code) || null);
  const tenant = useAppSelector(state => state.portal.tenants.find((tenant: ITenant) => tenant?.bookingCode === code) || null);
  const currentLanguage = useAppSelector(state => state.app.currentLanguage || state.channel.properties.language?.meta.default);

  const [prices, setPrices] = useState<Prices>({net: '', gross: '', vat: ''})
  const currentLanguageCode = formatCodes[currentLanguage];

  useLayoutEffect(() => {
    if (!invoice) {
      dispatch(getInvoiceDetails(code))
    }
    if (!tenant) {
      dispatch(getTenantDetails({tenantId, bookingCode: code}))
    }
  }, [invoice, tenant, dispatch, code, tenantId]);

  useLayoutEffect(() => {
    if (invoice && invoice.results) {
      setPrices({
        net: price(invoice.results[0].currency.code, invoice.results[0].price.net, invoice.results[0].currency.exponent, currentLanguageCode),
        gross: price(invoice.results[0].currency.code, invoice.results[0].price.gross, invoice.results[0].currency.exponent, currentLanguageCode),
        vat: `${(invoice.results[0].orders[0].tax.vat * 100).toFixed(0)}%`
      })
    }
  }, [currentLanguageCode, invoice])

  return (
    <div>
      <div className={styles.gridRow}>
        <div className={styles.rowTitle}>{translation.get('portal:booking:payment:title')}</div>
        <div className={styles.rowTitle}>{translation.get('portal:booking:partner:title')}</div>
      </div>

      <div className={styles.gridRow}>
        <div className={styles.bold}>
          <div className={styles.nestedGridContainer}>
            <div className={styles.nestedGridRow}>
              <div className={styles.nestedGridColumn}>
                {translation.get('portal:booking:payment:net')}:
              </div>
              <div className={styles.nestedGridColumn}>
                {prices.net}
              </div>
            </div>
            <div className={styles.nestedGridRow}>
              <div className={styles.nestedGridColumn}>
                {translation.get('portal:booking:payment:tax')}:
              </div>
              <div className={styles.nestedGridColumn}>
                {prices.vat}
              </div>
            </div>
            <div className={styles.nestedGridRow}>
              <div className={styles.nestedGridColumn}>
                {translation.get('portal:booking:payment:total')}:
              </div>
              <div className={styles.nestedGridColumn}>
                {prices.gross}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.bold}>
          <div className={styles.nestedGridContainer}>
            <div className={styles.nestedGridRow}>
              <div className={styles.nestedGridColumn}>
                {translation.get('portal:booking:partner:tenant')}:
              </div>
              <div className={styles.nestedGridColumn}>
                {tenant?.name}
              </div>
            </div>
            <div className={styles.nestedGridRow}>
              <div className={styles.nestedGridColumn}>
                {translation.get('portal:booking:partner:email')}:
              </div>
              <div className={styles.nestedGridColumn}>
                {tenant?.company?.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingDetails;