import * as React from "react";
import styles from "./Button.module.scss";

export enum ColourTypes {
  action = 'action',
  background = 'background',
  primary = 'primary',
  secondary = 'secondary',
  dark = 'dark'
}

export enum ButtonTypes {
  button = 'button',
  submit = 'submit',
  edit = 'edit'
}

interface ISubmitButtonProps {
  id?: string;
  text: string;
  onClick?: () => any;
  style?: React.CSSProperties;
  disabled?: boolean;
  color?: ColourTypes;
  type?: 'submit' | 'button' | 'reset';
  className?: string;
  "data-cy"?: string;
}

const Button = ({
  id,
  text,
  onClick,
  style,
  disabled,
  type,
  className,
  "data-cy": dataCy
}: ISubmitButtonProps) => {
  const typeClassNames = {
    submit: styles.submit,
    button: styles.button,
    reset: styles.reset,
  };
  const computedClassName = `${styles.button} ${type && typeClassNames[type]} ${className || ""}`;

return (
  <button
    data-cy={dataCy}
    id={id}
    style={style}
    className={computedClassName}
    type={type}
    value={text}
    onClick={onClick}
    disabled={disabled}
  >
    {text}
  </button>
);
};

export default Button;