import {DateTime} from 'luxon'
import {useMemo} from "react";
import {CompleteStepProps} from "../../../../../components/containers/Step/types";
import CompleteStep from "../../../../../components/CompleteStep/CompleteStep";
import {useBookingMeta} from "../../../../../hooks";
import useTranslations from "../../../../../hooks/useTranslations";
import { FlightSearch } from "store/flight/types/api/liveFlight";
import {Flow} from "../../../../../store/channel/channel.types";

interface Props extends CompleteStepProps {
  data: FlightSearch
}

const JourneyCreationStepCompleted = ({data}: Props) => {
  const {translation} = useTranslations();
  const {flow} = useBookingMeta();

  const departureDate = useMemo(() => {
    return DateTime.fromJSDate(new Date(data.schedules[0].departure_datetime)).toLocaleString({
      dateStyle: "short",
      timeZone: data.schedules[0].route.departure_airport.timezone
    })
  }, [data])

    const arrivalDate = useMemo(() => {
    return DateTime.fromJSDate(new Date(data.schedules[0].arrival_datetime)).toLocaleString({
      dateStyle: "short",
      timeZone: data.schedules[0].route.arrival_airport.timezone
    })
  }, [data])

  return (
    <CompleteStep
      id={"journey-edit"}
      headingText={flow === Flow.City ? translation.get('journey_creation:city:completed_heading', {
        flight_number: data.number,
          arrival_airport: data.schedules[0].route.arrival_airport.name,
        arrival_date: arrivalDate
      })
    : translation.get('journey_creation:airport:completed_heading', {
        flight_number: data.number,
        departure_airport: data.schedules[0].route.departure_airport.name,
        departure_date: departureDate
      })
    }
    />
  )
}

export default JourneyCreationStepCompleted;