import React, {useState} from "react";
import AddressSearch from "../../../AddressSelectorForm/AddressSearch";
import Icon from "../../../icons/Icon/Icon";
import LocationIcon from "../../../../resources/svg/Location.svg";
import {AddressSearchType, Coordinates, Location, PickupType} from "../../../../store/booking/bookingTypes";
import PlacesAutoComplete from "./PlacesAutocomplete";
import styles from "./AddressField.module.scss";
import Switch from "components/inputs/Switch/Switch";
import useTranslations from "hooks/useTranslations";

interface AddressFieldProps {
  country: string;
  type: PickupType;
  coordinates: Coordinates;
  radius?: number;
  map?: google.maps.Map;
  onError: (error: string | undefined) => void;
  onUpdate: (location: Location) => void;
}

const AddressField: React.FC<AddressFieldProps> = (
  {
    country,
    type,
    coordinates,
    map,
    onError,
    onUpdate
  }
) => {

  const {translation} = useTranslations();
  const [addressType, setAddressType] = useState<AddressSearchType>(AddressSearchType.Short);

  return (
    <>
      <Switch
        className={styles.addressSwitch}
        initial="left"
        onChange={(value) => setAddressType(value === "left" ? AddressSearchType.Short : AddressSearchType.Standard)}
        rightLabel={translation.get("location:switch_address")}
        leftLabel={translation.get("address:short_address")}
      />
      <div className={styles.container}>
        {type === PickupType.Address &&
          <>
            <Icon src={LocationIcon} className={styles["location-icon"]}/>
            {country === 'SA' ?
              <AddressSearch type={addressType} onError={onError} onUpdate={onUpdate}/> : coordinates &&
              <PlacesAutoComplete
                map={map}
                coordinates={coordinates}
                style={{}}
                onUpdate={onUpdate}
              />
            }
          </>
        }
      </div>
    </>
  )
}

export default AddressField;