import React, {useCallback} from "react";
import PhoneInput, {Value as InputValue} from 'react-phone-number-input'
import {GenericInput} from "../types";
import styles from "./Phone.module.scss";
import sharedStyles from "../Shared.module.scss";
import LabeledContainer from "../../containers/LabeledContainer";
import ValidationError from "../../ValidationError";

interface Props extends GenericInput<string> {
  defaultCountry: any;
  onChange: (event: InputValue) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => React.FocusEventHandler<HTMLInputElement> | void;
  error?: string;
  "data-cy"?: string;
}

const ConcretePhoneInput = React.forwardRef<typeof PhoneInput, Props>((
  {
    name,
    value,
    defaultCountry,
    onChange,
    style,
    label,
    icon,
    containerClassName = "",
    onBlur,
    error,
    "data-cy": dataCy
  }, ref) => {
  const onFieldChange = useCallback((value: InputValue) => {
    onChange(value);
  }, [onChange])

  return (
    <div className={styles.container}>
      {label
        ? (
          <div>
            <LabeledContainer
              style={{backgroundColor: style?.backgroundColor, border: style?.border}}
              label={label}
              className={`${containerClassName}`}
            >
              {icon && icon}

              <PhoneInput
                data-cy={dataCy}
                international
                name={name}
                className={styles.input}
                defaultCountry={defaultCountry}
                value={value || ""}
                onChange={(s: InputValue) => s && onFieldChange(s)}
                onBlur={onBlur}
                flagUrl={`https://flagcdn.com/w640/{xx}.png`}
              />
            </LabeledContainer><ValidationError error={error}/>
          </div>
        )
        : (
          <div>
            <div className={`${sharedStyles.container} ${containerClassName}`}
                 style={{"border": style?.border || "inherit"}}>
              {icon && icon}

              <PhoneInput
                data-cy={dataCy}
                international
                name={name}
                className={styles.input}
                defaultCountry={defaultCountry}
                value={value || ""}
                onChange={(s: InputValue) => s && onChange(s)}
                onBlur={onBlur}
                flagUrl={`https://flagcdn.com/w640/{xx}.png`}
              />
            </div>
            <ValidationError error={error}/>
          </div>
        )
      }
    </div>
  )
});

export default ConcretePhoneInput;
