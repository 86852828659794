import React from "react";
import useTranslations from "../../hooks/useTranslations";
import styles from './Footer.module.scss'
import Svg from '../../components/images/Svg/Svg'
import Faq from "../Faq/Faq";
import Modal from "../../components/modals/Modal";
import {useModal} from "../../hooks";
import {useAppSelector} from "../../store";
import Terms from "layouts/Terms/Terms";


const Footer = () => {
  /**
   * The footer which is displayed on every page.
   *
   * This footer must contain our logo and links to our term and conditions, privacy policy along with
   * Bagpoint's contact information.
   */

  const {translation} = useTranslations();
  const {isShown, toggle} = useModal();
  const {isShown: isTermsShown, toggle: toggleTerms} = useModal();
  const whitelabel = useAppSelector((state) => state.channel.properties.whitelabel);
  const fixedFooterDirection = useAppSelector((state) => state.channel.properties.switches.fixedFooterDirection);
  const displayProvider = useAppSelector((state) => state.channel.properties.switches.displayProvider);
  const terms = useAppSelector(state => state.channel.properties.terms);

  const formattedAddress: string[] = whitelabel.company.address.split(',')

  return (
    <section id="footer" className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div>
            <div className={styles.slogan}>
              {translation.get('footer:slogan')}
            </div>
            {displayProvider && <div className={styles.poweredByContainer}>
              <span>{translation.get('footer:powered_by')}</span>
              <Svg url={'./static/media/bagpoint-logo.svg'} className={styles.poweredByLogo}/>
            </div>}
          </div>
          <div className={styles.copyright}>
            <div>
              {translation.get('footer:trademark')}
            </div>
            <div>
              {translation.get('footer:copyright')}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.heading}>
              {translation.get('footer:about')}
            </div>
            <div className={styles.links}>
							{Object.keys(terms).length !== 0 ? 
								<>
									<div onClick={toggleTerms}>
										{translation.get('footer:terms:title')}
									</div>
									<Modal 
										isShown={isTermsShown}
										hide={toggleTerms}
										headerText={translation.get('footer:terms:title')}
										modalContent={<Terms terms={terms} />}
									/>
								</>
								:
								<a href={translation.get('footer:terms:link')}>
									{translation.get('footer:terms:title')}
								</a>
							}
              <a href={translation.get('footer:privacy:link')}>
                {translation.get('footer:privacy:title')}
              </a>
              <button onClick={toggle}>
                {translation.get("footer:faq")}
              </button>
              <Modal 
                isShown={isShown}
                hide={toggle}
                headerText={translation.get("footer:faq")}
                modalContent={<Faq whitelabel={whitelabel}/>}
              />
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div>
            <div className={styles.heading}>
              {translation.get('footer:contact')}
            </div>
            <div className={styles.wrapper}>
              <div>{translation.get('footer:company')}</div>
              <div>{whitelabel.company.email}</div>
              <div className={fixedFooterDirection ? styles.ltr : ""}>{whitelabel.company.phone}</div>
            </div>
          </div>
          <div className={styles.wrapper}>
            {formattedAddress.map((a: string, index: number) => (
              <div key={index}>{a}</div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
