interface Environment {
  REACT_APP_API_URL: string;
  REACT_APP_CLIENT_CUSTOMER_ID: string;
  REACT_APP_CLIENT_USERLESS_ID: string;
  REACT_APP_CLIENT_REGISTRATION_ID: string;
  REACT_APP_CLIENT_PASSWORD_RECOVER_ID: string;
  REACT_APP_GOOGLE_API_KEY: string;
  REACT_APP_ADYEN_CLIENT_KEY: string;
  REACT_APP_ADYEN_ENVIRONMENT: string;
  REACT_APP_ADYEN_PAYPAL_ENVIRONMENT: string;
  REACT_APP_BASE_PATH: string;
  REACT_APP_ENV: string;
  REACT_APP_CHANNEL: string;
  REACT_APP_VERSION: string;
  REACT_APP_GOOGLE_TAG_MANAGER: string
	REACT_APP_RECAPTCHA_V2_KEY: string;
	REACT_APP_RECAPTCHA_V3_KEY: string;

  [key: string]: string;
}

// @ts-ignore
const evars: Environment = process.env || {};

const ENV = {
  API_URL: `${evars.REACT_APP_API_URL}/1.0`,
  CLIENT_CUSTOMER_ID: evars.REACT_APP_CLIENT_CUSTOMER_ID,
  CLIENT_USERLESS_ID: evars.REACT_APP_CLIENT_USERLESS_ID,
  CLIENT_REGISTRATION_ID: evars.REACT_APP_CLIENT_REGISTRATION_ID,
  CLIENT_PASSWORD_RECOVER_ID: evars.REACT_APP_CLIENT_PASSWORD_RECOVER_ID,
  GOOGLE_API_KEY: evars.REACT_APP_GOOGLE_API_KEY,
  ADYEN_CLIENT_KEY: evars.REACT_APP_ADYEN_CLIENT_KEY,
  ADYEN_ENVIRONMENT: evars.REACT_APP_ADYEN_ENVIRONMENT,
  ADYEN_PAYPAL_ENVIRONMENT: evars.REACT_APP_ADYEN_PAYPAL_ENVIRONMENT,
  ENV: evars.REACT_APP_ENV,
  BASE_PATH: evars.REACT_APP_BASE_PATH,
  CHANNEL: evars.REACT_APP_CHANNEL,
  VERSION: evars.REACT_APP_VERSION || '2.3.15',
  GOOGLE_TAG_MANAGER: evars.REACT_APP_GOOGLE_TAG_MANAGER,
	RECAPTCHA_V2_KEY: evars.REACT_APP_RECAPTCHA_V2_KEY,
	RECAPTCHA_V3_KEY: evars.REACT_APP_RECAPTCHA_V3_KEY
};

export default ENV;
