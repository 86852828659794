import * as React from "react";
import {useEffect, useState} from "react";

export interface IconProps {
  src: string;
  className?: string;
  onClick?: () => void;
  fill?: string;
  stroke?: string;
  id?: string;
  "data-cy"?: string;
}

const Icon: React.FunctionComponent<IconProps> = (
  {
    src,
    className,
    onClick,
    fill,
    stroke,
    id,
    "data-cy": dataCy
  }) => {
  const [svg, setSvg] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(() => true);
    fetch(src)
      .then(res => res.text())
      .then(text => setSvg(() => text))
      .finally(() => setLoading(() => false));
    return () => {
      setLoading(() => false);
    };
  }, [src])

  if (loading) {
    return <div className="spinner"/>;
  } else if (!svg) {
    return <div className="error"/>
  }

  if (stroke) {
    return <div data-cy={dataCy} id={id} className={className} onClick={onClick} dangerouslySetInnerHTML={
      {__html: svg.replace('svg', 'svg style="stroke: ' + stroke + ';"')}
    }/>;
  }

  if (fill) {
    return <div data-cy={dataCy} id={id} className={className} onClick={onClick} dangerouslySetInnerHTML={
      {__html: svg.replace('svg', 'svg style="fill: ' + fill + ';"')}
    }/>;
  }

  return <div data-cy={dataCy} id={id} className={className} onClick={onClick} dangerouslySetInnerHTML={
    {__html: svg}
  }/>;
};

export default Icon;
