import {GenericInput} from "../types";
import React, {useCallback, useState, useEffect, useRef} from "react";
import styles from "./IntegerInput.module.scss";
import LabeledContainer from "../../containers/LabeledContainer";
import {IconProps} from "../../icons/Icon/Icon";
import TextInput from "../TextInput";

interface Props extends GenericInput<number> {
  onKeyPress?: (value: string) => void;
  value?: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  addIcon?: React.ReactElement<IconProps>;
  subtractIcon?: React.ReactElement<IconProps>;
  disabled: boolean;
  delay?: number;
  "data-cy"?: string;
}

const IntegerInput: React.FC<Props> = ((
  {
    name,
    value,
    onChange,
    disabled,
    inputClassName,
    min = 0,
    max = 18,
    label,
    icon,
    addIcon,
    subtractIcon,
    labelContainerClassName = '',
    containerClassName = "",
    delay = 800,
    'data-cy': dataCy,
  }) => {
  /**
   * A generic IntegerInput which extends the functionality of the TextInput field whilst leveraging the
   * LabeledContainer functionality.
   */

  const ref = useRef<HTMLInputElement | null>(null)

  const [count, setCount] = useState<number|undefined>(value);
  const [, setTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    /**
     * Reflect changes back onto the parent component provided that the previously submitted value
     * and the current value are not the same.
     *
     * We wrap the callback in a timer to avoid having multiple calls and to permit the customer to quickly
     * hammer down on the control icons.
     */
    if (count === undefined || ref.current === null || value === count) return;

    let event: NodeJS.Timeout;
    setTimer((timer) => {
      if(timer !== undefined) clearTimeout(timer);

      event = setTimeout(() => {
        onChange(count);
      }, delay)

      return event;
    });

    return () => clearTimeout(event);
  }, [count, value, delay, onChange])

  const onAdd = useCallback(() => {
    setCount(Math.min(Math.max((count || min) + 1 || 0, min), max))
  }, [count, min, max])

  const onSubtract = useCallback(() => {
    if (count === undefined) return;
    setCount(Math.min(Math.max((count || min) - 1 || 0, min), max))
  }, [count, min, max])

  const Content = <TextInput
    ref={ref}
    value={count?.toString() || min.toString()}
    inputClassName={`${inputClassName} ${styles.input}`}
    labelContainerClassName={styles.labelContainer}
    name={name}
    onClick={undefined}
    disabled={disabled}
    readonly
  />

  const Controls = <div className={styles.controls}>
    {(React.isValidElement<IconProps>(subtractIcon))
      && React.cloneElement(subtractIcon as React.ReactElement<IconProps>, {
        className: `${(subtractIcon.props.className || '')} ${styles.subtract} ${disabled || count === min ? styles.disabledButton : ""}`,
        onClick: () => {
          if (disabled) return;
          onSubtract();
          subtractIcon.props.onClick && subtractIcon.props.onClick();
        },
        id: 'subtract-luggage',
        ...subtractIcon.props,
      })}

    {(React.isValidElement<IconProps>(addIcon))
      && React.cloneElement(addIcon as React.ReactElement<IconProps>, {
        "data-cy": dataCy,
        className: `${(addIcon.props.className || '')} ${styles.add} ${disabled || count === max ? styles.disabledButton : ""}`,
        onClick: () => {
          if (disabled) return;
          onAdd();
          addIcon.props.onClick && addIcon.props.onClick();
        },
        id: 'add-luggage',
        ...addIcon.props,
      })}
  </div>

  if (label) return (
    <LabeledContainer
      label={label}
      icon={icon}
      className={`${labelContainerClassName} ${disabled && styles.disabled}`}
      inputContainerClassName={`${styles.container} ${containerClassName}`}>
      {Content}
      {Controls}
    </LabeledContainer>
  )

  return (<div className={`${styles.container} ${containerClassName} ${disabled && styles.disabled}`}>
    {Content}
    {Controls}
  </div>);
});

export default IntegerInput;
