import {useCallback, useMemo} from "react";
import useTranslations from "../../../../hooks/useTranslations";
import {Schedule} from "../../../../store/flight/types/api/liveFlight";
import {FlightState} from "../../../../store/flight/types/store";
import FlightStepCompleted from "./FlightStepCompleted";
import {clearBooking} from "../../../../store/booking/bookingReducer";
import FlightStepActive from "./FlightStepActive/FlightStepActive";
import {useAppDispatch, useAppSelector} from "../../../../store";
import Step from "../../../../components/containers/Step";
import {saveFlightInfo} from "../../../../store/flight/flightReducer";
import {Flow, IChannelTranslationsState} from "../../../../store/channel/channel.types";
import FlightService from "store/flight/flightService";
import {FlightSearch} from "store/flight/types/api/liveFlight";
import {AirportDirection} from "store/flight/types/enums";
import Utils from "utils/Utils";
import {DateTime, Duration} from "luxon";
import {useBookingMeta, useOptions, useProperties, useValidation} from "hooks";
import StepError from "../StepError";

export interface FlightFormData {
  date: string;
  identifier: string;
}

const FlightStep = () => {
  /**
   * The flight information collection step.
   */
  const {properties} = useProperties();
	const {options} = useOptions();
  const dispatch = useAppDispatch();
  const {direction} = useBookingMeta();
  const {translation} = useTranslations();
  const {
    validateFlightNumber,
    validateFlightDate,
    validateFlightDeparture,
    validateFlightArrival
  } = useValidation();
  const flow = useAppSelector((state) => state.app.currentFlow as Required<Flow>);
  const flight = useAppSelector((state) => {
    if (!state.flight.search) return undefined;

    return {
      ...state.flight.search,
      schedules: state.flight.search.schedules.filter((schedule: Schedule) => {
        return schedule.route[state.app.currentFlow === 'city' ? 'arrival_airport' : 'departure_airport'].serviceable;
      }) as Required<FlightState>
    };
  });

  const verification = useAppSelector(state => state.payment.verification)
  const verified = useMemo(() => {
    return verification?.status === "STATUS.PROGRESS.010"
  }, [verification])

  const onEdit = useCallback(async () => {
    return dispatch(clearBooking())
  }, [dispatch])

	const onEnter = useCallback(() => {
    /**
     * This behavior is used to display an error if
		 * the user is redirected back here from the location
		 * step due to empty options.
		 * If flight data exists and there are no options
		 * available, inform the user with an error.
     */

    return new Promise<void>((resolve, reject) => {
        if (!!flight && Object.keys(options).length === 0) {
            reject(new StepError({
                flight: translation.get('error:flights:no_available_timeslot')
            }));
        } else {
					resolve()
        }
    });
}, [flight, options, translation]);

  const validateFields = useCallback((data?: FlightSearch) => {
    /**
     * Validate flight data based on submitted information.
     * This method will return true if there are no errors.
     * It will return false if there is an error.
     */
    const offset = Duration.fromObject(properties.logistics.boundaries[flow].today?.from || {})

    const validators = {
      flight: validateFlightNumber(
        data?.number || '',
        direction === AirportDirection.Departure ? properties.flightSearch.airline.departure.whitelist : properties.flightSearch.airline.arrival.whitelist,
        direction
      ),
      ...(flow === Flow.Airport && data && {
        date: validateFlightDate(
          DateTime.fromISO(data.schedules[0].departure_datetime_local),
          offset
        ),
        departure: validateFlightDeparture(
          data.schedules[0].route.departure_airport.codes.iata,
          properties.flightSearch.airport.departure.whitelist
        )
      }),
      ...(flow === Flow.City && data && {
        date: validateFlightDate(
          DateTime.fromISO(data.schedules[0].arrival_datetime_local),
          offset
        ),
        arrival: validateFlightArrival(
          data.schedules[0].route.arrival_airport.codes.iata,
          properties.flightSearch.airport.arrival.whitelist
        )
      })
    };

    const errors: { [key: string]: string } = Object.fromEntries(
      Utils.object.entries(validators).filter(([_key, value]) => value !== undefined)
    ) as { [key: string]: string };

		return errors;
  }, [properties, flow, validateFlightNumber, direction, validateFlightDate, validateFlightDeparture, validateFlightArrival]);

  const onSubmit = useCallback(async ({identifier, date}: FlightFormData) => {
    /**
     * Validate fields against the provided validation methods.
     * If all the fields are valid, we save the pnr and retrieve the flight information.
     *
     * After receiving the flight information, we validate the flight departure datetime to see if we have
     * a sufficient amount of time to collect the luggage.
     *
     * If the flight is considered to be valid, we trigger the callback
     */

    if (!identifier) return;

    const data = await FlightService
      .getFlight({
        flight: identifier,
        date,
        flow
      })
      .catch((error: any) => {
        const errorCode = error?.response?.data?.code;
        const translationKey = errorCode ? `error:flights:${errorCode}` as keyof IChannelTranslationsState : "error:general_error";
        const flightError = translation.get(translationKey) || translation.get("error:general_error");

        throw new StepError({
          flight: flightError
        });
      })

    if (!data) return;

    const filteredSchedules = data.schedules.filter(schedule => flow === Flow.City ? schedule.route.arrival_airport.serviceable : schedule.route.departure_airport.serviceable);

    if (!filteredSchedules.length) {
      throw new StepError({
        flight: translation.get("validation:flight:wrong_arrival")
      })
    }

    const errors = validateFields({
      ...data,
      schedules: filteredSchedules
    });

		if(Object.keys(errors).length > 0) {
			throw new StepError(errors);
		}

    return dispatch(saveFlightInfo(data));
  }, [flow, translation, dispatch, validateFields])

  return (<Step
    header={translation.get("flight:heading")}
    Active={FlightStepActive}
    Completed={FlightStepCompleted}
    onEdit={onEdit}
		onEnter={onEnter}
    onSubmit={onSubmit}
    props={{
      Active: {data: flight},
      Completed: {data: {flow, search: flight, disableEdit: verified}}
    }}
  />)
}

export default FlightStep;
