import {createAction, createReducer, Reducer} from "@reduxjs/toolkit";
import {SET_MEMBERSHIP_CODE} from "./membershipConstants";
import {MembershipState} from './membershipTypes';

const initialState: MembershipState = {
  code: undefined
}

export const setMembershipCode = createAction<string>(SET_MEMBERSHIP_CODE)

const membership: Reducer<MembershipState> = createReducer(initialState, builder => {
  builder.addCase(setMembershipCode,  (state, action) => {
    state.code = action.payload;
  })
})

export default membership;