import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {LogisticWindowOptions} from "../components/forms/WindowSelector/types";
import {useAppSelector} from "../store";
import {FlightState} from "../store/flight/types/store";
import {Location} from "../store/booking/bookingTypes";
import {DateTime, Duration, Interval} from "luxon";
import {DurationRange, Flow, Validity} from "../store/channel/channel.types";
import {AirportDirection} from "../store/flight/types/enums";
import Utils from "../utils/Utils";

interface PickupContextProps {
  options: LogisticWindowOptions,
  calculateTravelTime: (location: Location, map: typeof google) => void,
  interval?: Interval,
  tz?: string,
  direction?: AirportDirection,
}

const PickupContext = React.createContext({} as PickupContextProps);

export const OptionsProvider = ({children}: PropsWithChildren<{}>): JSX.Element => {
  const [, setUpdated] = useState<DateTime>();
  const [travelTimeInMin, setTravelTimeInMin] = useState<number>(0);

  const vacations: Array<Validity> = useAppSelector(state => state.channel.properties.logistics.vacations)
  const {
    flight,
    flows,
    boundaries,
    windows,
  } = useAppSelector((state) => {
    const flight = state.flight as Required<FlightState>
    const flows = {
      options: state.channel.properties.flows,
      current: state.app.currentFlow || state.channel.properties.switches.flow.default
    }

    return {
      flight,
      flows,
      boundaries: state.channel.properties.logistics.boundaries[flows.current],
      windows: state.channel.properties.logistics.windows[flows.current],
    }
  });

  /**
   * Generate a valid set of variables used for determining the available timeslots
   *
   * direction:
   *  Returns either departure or arrival. This variable is used to correctly extract timezone and datetime logic
   *  based on currently selected flow.
   * tz:
   *  The timezone of the location in which the logistics will be happening.
   * dates:
   *  An object containing today's date and the flight's arrival or departure date depending on the selected service.
   * interval:
   *  The returned interval represents the range of dates in which a window may be selected.
   */
  const {tz, interval, direction} = useMemo(() => {
    if (flight.journey === undefined) return {tz: undefined, interval: undefined};

    const direction = flows.current === Flow.Airport ? AirportDirection.Departure : AirportDirection.Arrival;
    const tz = flight.journey[direction].schedule.airport.timezone;
    const now = DateTime.now().setZone(tz)
    const dates: { [key: string]: DateTime } = {
      today: now.set({hour: 0, minute: 0, second: 0, millisecond: 0}),
      flight: DateTime.fromISO(flight.journey[direction].schedule.datetime_local, {setZone: true})
    }
    const intervals = Utils.object
            .entries(boundaries)
            .map(([key, value]) => {
              let to = {
                ...value.to,
                minutes: (value.to.minute || 0) - travelTimeInMin
              }

              return (Interval.fromDateTimes(
                      dates[key].plus(Duration.fromObject(value.from || {year: -1})),
                      dates[key].plus(Duration.fromObject(to || {year: 1}))
              ))
            })
    intervals.push(Interval.fromDateTimes(now, now.plus(Duration.fromObject({year: 100}))))

    return {
      tz,
      interval: Utils.date.intersection(intervals) || undefined,
      direction
    };
  }, [flight, travelTimeInMin, boundaries, flows])

  /**
   * Checks if a given day is open for business based on the provided configuration.
   *
   * @param {Interval} day - The interval specifying the day to check.
   * @returns {boolean} - Returns true if the day is open for business, false otherwise.
   * @throws {Error} - Throws an error if the configuration is invalid.
   */
  const isOpenedForBusiness = useCallback((day: Interval): boolean => {
    // Type guard
    if (flight.journey === undefined) return false;
    if (!interval) return false;
    if (!Array.isArray(vacations)) throw Error("Invalid configuration");

    // Handle mapping
    const closingDays = vacations.map<Interval>(pickupDay => Interval.fromDateTimes(
            DateTime.fromObject({
              minute: pickupDay.from.minute,
              hour: pickupDay.from.hour,
              day: pickupDay.from.day,
              month: pickupDay.from.month,
              year: pickupDay.from.year || day.start.year,
            }, {zone: pickupDay.from.timeZoneName || tz}),
            DateTime.fromObject({
              minute: pickupDay.to.minute,
              hour: pickupDay.to.hour,
              day: pickupDay.to.day,
              month: pickupDay.to.month,
              year: pickupDay.to.year || day.end.year,
            }, {zone: pickupDay.to.timeZoneName || tz})
    ));

    return !closingDays.find((interval) => {
      return interval.overlaps(day);
    })
  }, [flight.journey, interval, vacations, tz]);

  /**
   * Generate a dictionary which maps dates with lists of selectable pickup ranges.
   *
   * The dictionary keys are string formatted millisecond representation of dates.
   * The values are lists of dictionaries containing target pickup times along with a label meant to be used for
   * a visual representations.
   */
  const options = useMemo(() => {
    const pickupTimeLimit = flight.journey ? DateTime.fromISO(flight.journey.departure.schedule.datetime_local).plus(boundaries.flight.from) : null;

    const options: LogisticWindowOptions = {}
    if (interval === undefined) return undefined;

    const filteredDays = Array.from(Utils.date.days(interval)).filter(isOpenedForBusiness);
		const flightDeparture = DateTime.fromISO(flight.journey[direction].schedule.datetime_local, {setZone: true});

    for (let day of filteredDays) {
      let timeslots = windows
              .map((range: Required<DurationRange>) => Interval.fromDateTimes(
                      day.start.startOf('day').set(range.from),
                      day.start.startOf('day').set(range.to),
              ))
              .filter((timeslot: Interval) => {
								const timeslot18to20 = Interval.fromDateTimes(
									day.start.set({ hour: 18, minute: 0 }),
									day.start.set({ hour: 20, minute: 0 })
								);
								const timeslot20to22 = Interval.fromDateTimes(
									day.start.set({ hour: 20, minute: 0 }),
									day.start.set({ hour: 22, minute: 0 })
								);

								const flightDay16to18Interval = Interval.fromDateTimes(
									flightDeparture.set({ hour: 16, minute: 0 }),
									flightDeparture.set({ hour: 18, minute: 0 })
								);
								const flightDay18to20Interval = Interval.fromDateTimes(
									flightDeparture.set({ hour: 18, minute: 0 }),
									flightDeparture.set({ hour: 20, minute: 0 })
								);
				
								if (timeslot.equals(timeslot18to20)) {
									return flightDay16to18Interval.contains(flightDeparture);
								}
				
								if (timeslot.equals(timeslot20to22)) {
									return flightDay18to20Interval.contains(flightDeparture);
								}

								if (!pickupTimeLimit) return timeslot.start >= day.start && timeslot.end < day.end;
					
								return timeslot.start > pickupTimeLimit && timeslot.start >= day.start && timeslot.end < day.end;
              })

      if (!timeslots.length) continue;

      options[day.start.startOf('day').toMillis().toString()] = timeslots.map((timeslot: Interval) => ({
        value: timeslot,
        label: timeslot.start.hour.toString().padStart(2, "0")
                + ":" + timeslot.start.minute.toString().padStart(2, "0")
                + " - " + timeslot.end.hour.toString().padStart(2, "0")
                + ":" + timeslot.end.minute.toString().padStart(2, "0")
      }));
    }

    return options;
  }, [flight.journey, boundaries.flight.from, interval, isOpenedForBusiness, windows, direction])

  /**
   * This will calculate the average amount of time in minutes required to transport
   * the luggage item between the pickup location and the delivery location.
   */
  const calculateTravelTime = useCallback((location: Location, map: typeof google): void => {
    if (!direction || !location.coordinates) return;
    const flightLocation = flight.journey[direction].schedule.airport;
    const distance = map.maps.geometry.spherical.computeDistanceBetween(new map.maps.LatLng(flightLocation.latitude, flightLocation.longitude), new google.maps.LatLng(location.coordinates.latitude, location.coordinates.longitude));
    const travelTime = distance / 1000 / 100 * 90

    setTravelTimeInMin(travelTime);
  }, [flight, direction])

  useEffect(() => {
    /**
     * Regenerate options on a given interval.
     */
    let interval: NodeJS.Timeout | null = null;
    let timer: NodeJS.Timeout | null = null;

    timer = setTimeout(() => {
      setUpdated(DateTime.now());

      interval = setInterval(() => {
        setUpdated(DateTime.now());
      }, Duration.fromObject({minute: 15}).toMillis())
    }, Duration.fromObject({minute: 15}).toMillis())

    return () => {
      interval && clearInterval(interval);
      timer && clearTimeout(timer);
    }
  }, [])

  return (<PickupContext.Provider
          value={{
            options: options || {},
            calculateTravelTime,
            interval,
            tz,
            direction
          }}>{children}</PickupContext.Provider>)
}

export const useOptions = () => {
  return React.useContext(PickupContext)
}

