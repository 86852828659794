import React from 'react';
import styles from './Options.module.scss';
import {DropdownItem} from "../../BaseDropdown";

interface Props {
  language: DropdownItem<string>;
  style?: React.CSSProperties;
  onClick: (language: DropdownItem<string>) => void;
  open: Function;
  selected: boolean;
}

const LanguageOption: React.FC<Props> = ({language, style, onClick, open, selected}) => {
  return (
    <div onClick={() => {
      onClick(language);
      open(() => false);
    }} style={style} className={styles.languageOption}>
      <div className={styles.languageCode}>{language.value}</div>
      <div className={`${styles.languageName} ${selected && styles.selected}`}>{language.label}</div>
    </div>
  );
}

export default LanguageOption;
