import {createAction, createAsyncThunk, createReducer, Reducer} from "@reduxjs/toolkit";
import {
  GET_CURRENT_USER,
  SET_CREATE_ACCOUNT,
  UPDATE_USER,
} from "./authConstants";
import AuthService from "./authService";
import {IAuthState, UserResponse} from "./authTypes";

const initialState: IAuthState = {
  token: localStorage.getItem("token"),
  response: "",
  user: {} as UserResponse,
  createAccount: false,
};

export const getUser = createAsyncThunk(GET_CURRENT_USER, async (email: string) => await AuthService.getUser(email));
export const updateUser = createAsyncThunk(UPDATE_USER, async (user: UserResponse) => await AuthService.updateUser(user));
export const setCreateAccount = createAction<boolean>(SET_CREATE_ACCOUNT);

const auth: Reducer<IAuthState> = createReducer(initialState, builder => {
  builder.addCase(getUser.fulfilled, (state, action) => {
    state.user = action.payload
  })
  builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload
  })
  builder.addCase(setCreateAccount, (state, action) => {
      state.createAccount = action.payload
  })
});

export default auth;
