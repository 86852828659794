import { Navigate } from 'react-router-dom';
import App from 'App';
import Login from 'pages/Login';
// import Registration from 'pages/Registration';
import PasswordReset from 'pages/PasswordReset';
import PasswordChange from 'pages/PasswordChange';
import EmailVerification from 'pages/EmailVerification';
import PrivateRoute from 'pages/PrivateRoute';
import CustomerPortal from 'pages/CustomerPortal';
import BookingSummary from 'pages/BookingSummary';
import BookingFlow from 'pages/BookingFlow';

const routes = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      // {
      //   path: 'registration',
      //   element: <Registration />,
      // },
      {
        path: 'password/forgot',
        element: <PasswordReset />,
      },
      {
        path: 'password/change',
        element: <PasswordChange />,
      },
      {
        path: 'email-verification',
        element: <EmailVerification />,
      },
      {
        path: 'portal',
        element: <PrivateRoute />,
        children: [
          {
            path: '',
            element: <CustomerPortal />,
          },
        ],
      },
      {
        path: 'summary',
        element: <BookingSummary />,
      },
      {
        path: '',
        element: <BookingFlow />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ],
  },
];

export default routes;
