import { Allowance } from "store/flight/types/api/reservation";
import {APIStatus} from "../../utils/apiStatus";
import {FlightState} from "../flight/types/store";
import {LocationType} from "../portal/portalTypes";

export enum PickupType {
  Address = 'Address',
  Hub = 'Hub'
}

export enum AddressSearchType {
  Standard = 'address_string',
  Short = 'short_address'
}

export interface Job {
  locations: [
    { location: number, datetime: string },
    { location: number, datetime: string }
  ],
  flight: string;
  pnr?: string;
  session?: string
}

export interface BookingRequestPassenger {
  first_name?: string;
  last_name?: string;
  items: number;
	allowance?: Omit<Allowance, 'flight_id'>[];
}

export interface BookingRequestBody {
  passengers?: BookingRequestPassenger[];
  jobs?: Job[];
  meta: { [key: string]: number | string | object }
}

export interface BookingRequest {
  code?: string;
  data: BookingRequestBody
}

export interface SelectableAddress {
  street: string;
  number: string;
  addition: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
  coordinates: {
    longitude: number;
    latitude: number;
  }
  extra: {
    short_address: string;
    district: string;
    representation: string[];
  }
}

export interface SpecificAddressParams {
	country_code: string,
	language: string,
	page?: string,
	short_address?: string,
	latitude?: number,
	longitude?: number,
	address_string?: string
}

interface IPassenger {
  first_name: string | null;
  last_name: string | null;
  items: number;
}

export interface BookingResponse {
  code: string;
  status: APIStatus;
  customer: number;
  passengers: IPassenger[];
  assignments: IAssignment[];
  meta: BookingMetaResponse;
}


export interface IAssignment {
  tenant_assignment: number;
  tenant: number;
  service: string;
  zone: number;
  luggage_count: number;
  from_location: number;
  from_datetime: string;
  to_location: number;
  to_datetime: string;
}

export interface Zone {
  id: number;
  name: string;
  areas: Areas;
  tenant?: any;
}
export interface Areas {
  type: string;
  coordinates: number[][][][];
}

export interface Location {
  id?: number;
  name: string;
  description: string;
  type: LocationType;
  coordinates: Coordinates;
  address: Address;
  favourite?: boolean;
}


export interface IDateTime {
  time: string;
  timeLabel: string;
  formatted: string;
}

export interface Coordinates {
  longitude: number;
  latitude: number;
}

export interface MapAddressResponse {
  administrative_area_level_1: string;
  administrative_area_level_2: string;
  country: string;
  locality: string;
  name: string;
  political: string;
  postal_code: string;
  route: string;
  street_number: string;

  [key: string]: string
}

export interface Address {
  street: string;
  number: string;
  addition: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
  coordinates: Coordinates;
  description?: string;
	address_type?: string;
}

export interface NeomAddress {
	community: string;
	number: string;
	country: string;
	coordinates: Coordinates;
	address_type: string;
	postal_code: string;
}

export interface BudgetResponse {
  budgets: Array<Budget>;
  currency: Currency;
  price: Price;
}

export interface InvoiceResponse {
  contact: any;
  currency: {
    name: string;
    code: string;
    symbol: string;
    exponent: number;
  };
  orders: {
    price: number;
    tax: {
      vat: number;
      sales: number;
    }
    hash: string;
    assignment: number
  }[];
  price: {
    net: number;
    gross: number;
  };
  reference: string;
  status: string;
}

export interface Budget {
  tenant: string;
  products: Array<Product>;
  currency: Currency;
  price: Price;
  tax: Tax;
}

export interface Product {
  price: Price;
  name: string;
  properties: Properties;
}

export interface Properties {
  service_cost: number;
  ppu: number;
  luggage_item_count: number;
  journey__id: number;
  service__name: string;
  tax__vat: number;
}

export interface Currency {
  name: string;
  code: string;
  symbol: string;
  exponent: number;
}

export interface Price {
  net: number;
  gross: number;
}

export interface Tax {
  vat: number;
  sales: number;
}

interface BookingCustomerData {
  passengers: {
    first_name?: string;
    last_name?: string;
    items: number;
  }[]
}

interface BookingLocationData {
  flight: Required<FlightState>;
  location: number;
  datetime: string;
}

interface BookingData {
  meta?: BookingMeta & {
    [key: string]: number | string | object
  }
}

export interface BookingMeta {
  membership?: string;
  timeslot: Timeslot;
  window?: {
    label: string;
    start: string,
    end: string
  };
}

export interface BookingMetaResponse {
  membership?: string;
  timeslot: Timeslot;
  window?: {
    label: string;
    start: string,
    end: string
  };
}

export interface Timeslot {
  time: string;
  formatted: string;
  timeLabel: string;
}

export interface BookingCreate extends BookingData, BookingLocationData, BookingCustomerData {
}

export interface BookingCustomerUpdate extends BookingData, BookingCustomerData {
}

export interface BookingLocationUpdate extends BookingData, BookingLocationData {
}