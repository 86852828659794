import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ActiveStepProps} from "components/containers/Step/types";
import InfoCard from "components/InfoCard/InfoCard";
import ValidationError from "components/ValidationError";
import DateInput from "components/inputs/DateInput/DateInput";
import Button from "components/inputs/buttons/Button";
import {useProperties, useBookingMeta} from "hooks";
import useTranslations from "hooks/useTranslations";
import {DateTime, Duration} from "luxon";
import {useAppSelector} from "store";
import FlightNumber from "../FlightNumber/FlightNumber";
import styles from './FlightStepActive.module.scss';
import sharedStyles from "styles/Shared.module.scss";
import CalendarIcon from "components/icons/CalendarIcon";
import CaretIcon from "components/icons/CaretIcon";
import {FlightSearch} from "store/flight/types/api/liveFlight";
import {FlightFormData} from "../FlightStep";

declare function UpdateCallback(data: FlightFormData): void;

interface Props extends ActiveStepProps {
  data: FlightSearch;
  onSubmit: typeof UpdateCallback;
  onUpdate?: (...args: any[]) => any;
}

const FlightStepActive: React.FC<Props> = ({data: flight, errors, onSubmit}) => {
  /**
   * The active state of the flight information step.
   *
   * The flight info active step is in charge of collecting flight related information
   * for the given service. In the case where a flight is composed of multiple legs,
   * we request additional information via the MultiLegFlight component.
   *
   * When the flight data and the appropriate leg has been selected, the onSubmit method is triggered.
   *
   * Collect flight information from the customer.
   * If the selected flow is Airport delivery, we also ask the customer to provide his PNR
   *
   * Upon form completion, the information is passed back to FlightInfoActive step using a callback.
   */
  const {properties} = useProperties();
  const {translation} = useTranslations();
  const {flow} = useBookingMeta();

  const boundaries = useMemo(() => {
    const now = DateTime.now();

    return {
      'min': now.plus(
        Duration.fromObject(properties.logistics.boundaries[flow].today?.from || {})
      ),
      'max': now.plus(
        Duration.fromObject(properties.logistics.boundaries[flow].today?.to || {month: 1})
      )
    }
  }, [properties, flow])

  const [date, setDate] = useState<DateTime>(DateTime.fromJSDate(new Date()));
  const [identifier, setIdentifier] = useState<string>(flight?.number || "");

  const flightNumberPlaceholder: string | undefined = useAppSelector(state => state.channel.properties.content.flightNumberPlaceholder)

  useEffect(() => {
    /**
     * Default values should be overridden by query parameters if they are provided.
     */
    const urlParams = new URLSearchParams(window.location.search);

    const urlDate = urlParams.get('date');
    const urlFlightNumber = urlParams.get('flightNumber');

    if (urlDate) setDate(() => DateTime.fromISO(urlDate));
    if (urlFlightNumber) setIdentifier(urlFlightNumber);
  }, [])

  const handleSubmit = useCallback(async () => {
    onSubmit({
      date: date.toISODate().slice(0, 10),
      identifier,
    });
  }, [date, identifier, onSubmit])

  const onDateChange = useCallback((date: Date) => {
    setDate(DateTime.fromJSDate(date))
  }, [])

  const disableSubmit = useMemo(() => identifier.trim() === "" || !date, [identifier, date])

  return (
    <>
      <h2 className={styles.heading}>{translation.get("flight:heading")}</h2>

      <div className={styles.form}>
        <div className={styles.innerContainer}>
          <DateInput
            name="departureDate"
            labelContainerClassName={styles.dateInput}
            label={translation.get("flight:number:label_departure-date")}
            value={date.toJSDate()}
            icon={CalendarIcon}
            caretIcon={CaretIcon}
            onChange={onDateChange}
            min={boundaries.min.toJSDate()}
            max={boundaries.max.toJSDate()}
          />
          <div className={styles.flightNumberContainer}>
            <FlightNumber
              autoFocus
              placeholder={flightNumberPlaceholder}
              inputs={['text' || 'number', 'text' || 'number', 'number', 'number', 'number', 'number']}
              onChange={(flightNumber: string) => setIdentifier(() => flightNumber)}
              flightNumber={identifier || ''}
            />
          </div>
          {Object.entries(errors)
            .filter(err => Boolean(err))
            .map(([, value], index) => (
              <ValidationError key={index} error={value}/>
            ))}
        </div>

        <InfoCard
          type="info"
          title={translation.get("city:luggage:pickup:title")}
          subtitle={translation.get("city:luggage:pickup:subtitle")}
        />

      </div>
      <div className={sharedStyles["submit-button-wrapper"]}>
        <Button
          type="submit"
          data-cy="flight-submit"
          id="flight-submit"
          disabled={disableSubmit}
          text={translation.get("button:next")}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}

export default FlightStepActive;
