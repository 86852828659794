import React from "react";
import {IconProps} from "../../icons/Icon/Icon";
import ValidationError from "../../ValidationError";
import Label from "./Label/Label";
import styles from "./LabeledContainer.module.scss";

interface Props {
  label: string | React.ReactElement;
  component?: React.ReactElement;
  labelClassName?: string;
  className?: string;
  inputContainerClassName?: string;
  style?: React.CSSProperties;
  onClick?: (element: React.MouseEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
  error?: string;
  icon?: React.ReactElement<Omit<IconProps, 'src'>>
  caret?: React.ReactElement<Omit<IconProps, 'src'>>
}

const LabeledContainer: React.FC<Props> = (
  {
    label,
    component,
    labelClassName = "",
    className = "",
    inputContainerClassName = "",
    style = {},
    onClick = () => {
    },
    error,
    icon,
    caret,
    ...props
  }) => {

  return (<div className={`${styles.wrapper} ${className || ""}`}>
    <div className={styles.labelContainer}>
      <Label text={label} className={`${labelClassName}`}/>
      {component}
    </div>
    <div
      onClick={onClick}
      className={`${styles.container} ${inputContainerClassName}`}
    >
      {icon && React.cloneElement(icon, {
        className: `${icon.props.className} ${styles.icon}`
      })}
      {props.children}
      {caret && React.cloneElement(caret, {
        className: `${caret.props.className} ${styles.caret}`
      })}
    </div>
    {error && <ValidationError error={error}/>}
  </div>)
};

export default LabeledContainer;