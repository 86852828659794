import React from 'react';
import AccordionItem from "./AccordionItem";
import styles from './Accordion.module.scss'
import {IAccordionSection} from "../../../store/channel/channel.types";

const Accordion = ({title, data}: { title: string, data: Array<IAccordionSection> }) => {

  return (
    <React.Fragment>
      <div className={styles.title}>{title}</div>
      <div className={styles.accordion}>
        {data.map((item, i) => (
          <AccordionItem title={item.title} content={item.content} key={i}/>
        ))}
      </div>
    </React.Fragment>
  )
}

export default Accordion;