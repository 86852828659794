import {Location} from '../booking/bookingTypes'
import {ContactResponse} from "../auth/authTypes";
import {APIStatus} from "../../utils/apiStatus";

export interface IPortalState {
  bookings: IBookingState;
  locations: ILocations;
  invoices: Array<IInvoiceState>;
  tenants: Array<ITenant>;
}

export interface IBookingState {
  bookings: Array<IBooking>;
  offset?: number;
  count?: number
}

export interface IBooking {
  contact: ContactResponse;
  code: string;
  status: APIStatus;
  passengers: Array<IPassenger>;
  assignments: Array<IAssignment>;
  customer: number;
}

interface IPassenger {
  first_name: string;
  last_name: string;
  items: number;
}

interface IAssignment {
  id: number;
  tenant_assignment: number;
  tenant: number;
  service: string;
  zone: number;
  luggage_count: string;
  from_location: number;
  from_datetime: string;
  to_location: number;
  to_datetime: string;
}

export interface ILocations {
  bagpoint: Array<Required<Location>>;
  private: Array<Required<Location>>;
  offset?: number;
  count?: number
}

export enum LocationType {
  bagpoint = 'bagpoint',
  hotel = 'hotel',
  airport = 'airport',
  private = 'private',
  business = 'business'
}

export interface IInvoiceResponse {
  count: number;
  next: number;
  previous: number;
  results: Array<IInvoice>;
  bookingCode: string;
}

export interface IInvoice {
  orders: Array<IOrder>;
  contact: ContactResponse;
  currency: ICurrency;
  price: IPrice;
  status: APIStatus;
}

export interface IInvoiceState extends IInvoice {
  bookingCode: string;
}
interface IPrice {
  net: number;
  gross: number;
}

export interface IOrder {
  price: number;
  tax: ITax;
  hash: string;
  assignment: number;
}

interface ITax {
  vat: number;
  sales: number;
}

interface ICurrency {
  name: string;
  code: string;
  symbol: string;
  exponent: number;
}

export interface ITenant {
  id: number;
  name: string;
  network_host: string;
  network_path: string;
  currency: ICurrency;
  status: number;
  domain: number;
  company: ICompany;
  bookingCode?: string;
}

export interface ICompany {
  id: number;
  address: IAddress;
  phone_number: string;
  email: string;
  vat_number: string;
  registration_number: string;
}

export interface IAddress {
  street: string;
  number: string;
  addition: string;
  postal_code: string;
  city: string;
  region: string;
  country: string;
}

export enum FormType {
  number = "number",
  tel = "tel",
  email = "email",
  text ="text",
  password = "password"
}