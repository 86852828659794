import { RootState } from "store/store";

const migrations: { [key: string]: Function } = {
  '1.0.0': (state: RootState) => {
    return {
      ...state,
      migration: {
        versions: [...(state.migration?.versions || []), '1.0.0']
      }
    }
  },
  '2.3.1': (state: RootState) => {
    return {
      ...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.1']
      },
    }
  },
	'2.3.7': (state: RootState) => {
		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.7']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					terms: {
						en: {
							1: {
								statements: [
									"I agree that all loaded luggage should comply with the airline's safety and security requirements in the light of the airline's safety and the regulations of the General Authority of Civil Aviation in Saudi Arabia.",
									"I agree to be responsible for the allowable weight of the airline's luggage and the airline's requirements. I acknowledge that we may refuse to accept your loaded luggage, and we will pay any airline fees if one of the bags exceeds the allowable weight on the aircraft."
								]
							},
							2: {
								subtitle: "I understand that the following items are prohibited in the luggage.",
								statements: [
									"The Banned Carriage Of Substances Among The Luggage"
								]
							},
							title: "The customer's agreement to the terms and conditions."
						}
					},
				}
			}
		}
	},
	'2.3.8': (state: RootState) => {
    /**
     * This migration is a noop.
     */
		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.8']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					content: {
						headerMenu: [], showHeaderSlogan: true, showPriceOverview: true, flightNumberPlaceholder: "AB1234"
					}
				}
			}
		}
	},
	'2.3.9': (state: RootState) => {
    /**
     * This migration adds content options to the channel properties.
     */
		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.9']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					content: {
						headerMenu: [], showHeaderSlogan: true, showPriceOverview: true, flightNumberPlaceholder: "AB1234"
					}
				}
			},
		}
	},
	'2.3.10': (state: RootState) => {
    /**
     * This migration introduces announcements to the channel properties.
     */

		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.10']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					announcements: state.channel.properties?.announcements || []
				}
			},
		}
	},
	'2.3.11': (state: RootState) => {
    /**
     * This migration introduces excluded pickup days to the channel properties.
     */

		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.11']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					logistics: {
						...state.channel.properties.logistics,
						vacations: state.channel.properties.logistics.vacations || []
					}
				}
			},
		}
	},
	'2.3.13': (state: RootState) => {
    /**
     * This migration introduces additional image to be displayed with the logo.
     */

		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.13']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					content: {
						...state.channel.properties.content,
						logoAddition: ""
					}
				}
			},
		}
	},
	'2.3.14': (state: RootState) => {
    /**
     * This migration introduces feature to hide percentage of passengers last name last name as a channel prop.
     */

		return {
			...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.14']
      },
			channel: {
				...state.channel,
				properties: {
					...state.channel.properties,
					content: {
						...state.channel.properties.content,
						passengerNamePercentageShown: 1,
					}
				}
			},
		}
	},
  '2.3.15': (state: RootState) => {
    /**
     * This migration fixes the previous badly run one. It also checks if properties.content
     * is the only key in properties, if so it resets the state.
     */

    const propertiesKeys = Object.keys(state.channel.properties);
    if (propertiesKeys.length === 1 && propertiesKeys.includes('content')) {
      return {
        ...state,
        migration: {
          versions: [...(state.migration?.versions || []), '2.3.15']
        }
      };
    }
    
    return {
      ...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2.3.15']
      },
      channel: {
        ...state.channel,
        properties: {
          ...state.channel.properties,
          content: {
            ...state.channel.properties.content,
            passengerNamePercentageShown: 1,
          }
        }
      },
    };
  },
	'2024.2.0': (state: RootState) => {
    /**
     * This migration introduces payment link.
     */

    const propertiesKeys = Object.keys(state.channel.properties);
    if (propertiesKeys.length === 1 && propertiesKeys.includes('content')) {
      return {
        ...state,
        migration: {
          versions: [...(state.migration?.versions || []), '2024.2.0']
        }
      };
    }
    
    return {
      ...state,
      migration: {
        versions: [...(state.migration?.versions || []), '2024.2.0']
      },
      channel: {
        ...state.channel,
        properties: {
          ...state.channel.properties,
          content: {
            ...state.channel.properties.content,
						paymentLink: false,
          }
        }
      },
    };
  },
}

export default migrations;