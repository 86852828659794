import React, {useCallback, useMemo, useRef} from 'react';
import CaretIcon from "../../../icons/CaretIcon";
import sharedStyles from "../../Shared.module.scss";
import styles from "./IntervalDropdown.module.scss";

import Icon from '../../../icons/Icon';
import {GenericInput} from "../../types";
import LabeledContainer from "../../../containers/LabeledContainer";
import BaseDropdown, {DropdownItem} from '../BaseDropdown';
import {Interval} from 'luxon';
import IntervalOption from "./IntervalOption";

interface Props extends GenericInput<DropdownItem<Interval>> {
  items: DropdownItem<Interval>[];
  caretIcon?: React.ReactElement<typeof Icon>;
  disabled: boolean;
  onChange: (item: DropdownItem<Interval>) => any;
  error?: string;
  "data-cy"?: string
}

const IntervalDropdown: React.FC<Props> = (
  {
    items,
    value,
    icon,
    label,
    labelContainerClassName = '',
    containerClassName = '',
    onChange,
    caretIcon= CaretIcon,
    disabled = false,
    error,
    'data-cy': dataCy,
  }
) => {
  /**
   * A dropdown which allows the customer to select a time slot from a predefined set of intervals.
   */
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const map: DropdownItem<number>[] = useMemo(() => items.map(({label, value}) => ({
    label,
    value: value.start.toMillis()
  })), [items])

  const onSelect = useCallback((item: DropdownItem<number>) => {
    /**
     * Triggered when an option is selected.
     */
    const selected = items.reduce((a, b) => {
      return a.value.start.toMillis() === item.value ? a : b;
    })

    onChange(selected);
  }, [items, onChange])

  const onOutsideClick = useCallback(()=>{
    /**
     * Forward clicks on the input container to the dropdown element.
     */
    if(!dropdownRef || dropdownRef.current == null) return;
    dropdownRef.current?.click();
  }, [dropdownRef])

  const Dropdown = <BaseDropdown
    data-cy={dataCy}
    ref={dropdownRef}
    className={styles.dropdown}
    items={map || []}
    value={value && value.label}
    onSelect={onSelect}
    Component={IntervalOption}
  />

  if (label) return (
    <LabeledContainer
      onClick={onOutsideClick}
      label={label}
      icon={icon}
      caret={caretIcon}
      className={`${disabled && styles.disabled} ${labelContainerClassName}`}
      inputContainerClassName={`${styles.container} ${containerClassName}`}
      error={error}
    >
      {Dropdown}
    </LabeledContainer>
  )

  return (<div
    className={`${sharedStyles.container} ${containerClassName || ""} ${styles.container || ""}`}>
    {Dropdown}
  </div>);
}

export default IntervalDropdown;
