import { useNavigate } from "react-router-dom";
import useTranslations from "../../../hooks/useTranslations";
import { clearPaymentData } from "../../../store/payment/paymentReducer";
import styles from "./ThankYouMessage.module.scss";
import Button from "../../../components/inputs/buttons/Button/Button";
import { useCallback, useLayoutEffect } from "react";
import { IChannelTranslationsState } from "../../../store/channel/channel.types";
import { clearFlight } from "../../../store/flight/flightReducer";
import { clearBooking } from "../../../store/booking/bookingReducer";
import Authentication from "../../../utils/authentication";
import { useAppDispatch, useAppSelector } from "../../../store";

const ThankYouMessage = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { translation } = useTranslations();
  const isLoggedIn = Authentication.isLoggedIn();
  const result = useAppSelector(state => state.payment.result);

  const redirectTo = useCallback((path: string): void => {
    dispatch(clearFlight());
    dispatch(clearBooking());
    dispatch(clearPaymentData());
    navigate(path);
  }, [dispatch, navigate])

  useLayoutEffect(() => {
    if (result !== undefined && !result.resultCode) {
      redirectTo('/')
    }
  }, [result, redirectTo])

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <h2 className={styles.title} id={'slogan'}>
          {translation.get(`thank_you:message:title:${result?.resultCode}` as keyof IChannelTranslationsState)}
        </h2>
        <h2 className={styles.subtitle}>
              {translation.get(`thank_you:message:subtitle:${result?.resultCode}` as keyof IChannelTranslationsState)}
        </h2>
        <h2 className={styles.subtitle}
            dangerouslySetInnerHTML={{__html: translation.get('thank_you:message:contact')}}/>
      </div>
      <div className={styles.footer}>
        {isLoggedIn &&
          <Button
            type="submit"
            onClick={() => redirectTo('/portal')} 
            text={translation.get('thank_you:message:myBookings')}
            className={styles.button}
          />
        }
        <Button 
          type="submit"
          onClick={() => redirectTo('/')} 
          text={translation.get('thank_you:message:bookAgain')}
          className={styles.button}
        />
      </div>
    </div>
  )
}

export default ThankYouMessage;