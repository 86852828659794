import React, {useCallback, useEffect, useMemo} from "react";
import PriceOverview from "../../layouts/PriceOverview/PriceOverview";
import styles from "./BookingFlow.module.scss"
import FlowSelector from "./FlowSelector";
import {StepInfoProps} from "../types";
import {Navigate} from "react-router-dom";
import {clearBooking} from "../../store/booking/bookingReducer";
import {clearFlight} from "../../store/flight/flightReducer";
import {Flow, Steps} from "../../store/channel/channel.types";
import {setCurrentFlow} from "store/app/appReducer";
import {useAppDispatch, useAppSelector} from "../../store";
import {MetaProvider} from "../../hooks/useBookingMeta";
import {AltanfeethiStep, PnrFlightStep, LocationStep, PaymentStep, FlightStep, NeomLocationStep} from "./steps";
import JourneyCreationStep from "./steps/JourneyCreationStep/JourneyCreationStep";
import PaxSelectionStep from "./steps/PaxSelectionStep";
import { PaymentResultCode } from "store/payment/paymentTypes";
import NeomPaymentStep from "./steps/NeomPaymentStep";
import LoginStep from "./steps/LoginStep/LoginStep";

const BookingFlow = () => {
  const dispatch = useAppDispatch();
  const flows = useAppSelector((state) => state.channel.properties.flows);
  const currentFlow = useAppSelector(state => state.app.currentFlow)
  const flow = useAppSelector((state) => state.app.currentFlow || state.channel.properties.switches.flow.default);
  const flowRules = useAppSelector((state) => state.channel.properties.switches.flow);
  const progress = useAppSelector((state) => state.booking.flow.progress);
  const paymentResult = useAppSelector((state) => state.payment.result);
	const showPriceOverview = useAppSelector((state) => state.channel.properties.content.showPriceOverview);

  const components = useMemo(() => ({
    [Steps.VERIFICATION]: AltanfeethiStep,
    [Steps.LOGIN]: LoginStep,
    [Steps.JOURNEY_CREATION]: JourneyCreationStep,
    [Steps.FLIGHT]: FlightStep,
    [Steps.PNR_FLIGHT]: PnrFlightStep,
    [Steps.PNR_PAX_SELECTION]: PaxSelectionStep,
    [Steps.LOCATION]: LocationStep,
    [Steps.NEOM_LOCATION]: NeomLocationStep,
    [Steps.NEOM_PAYMENT]: NeomPaymentStep,
    [Steps.PAYMENT]: PaymentStep,
  } as { [key in Steps]: React.ComponentType<StepInfoProps> }), []);

  const setSelectedFlow = useCallback((flow: Flow) => {
    /**
     * When the flow is changed, we reset the entire booking and flight information
     * before dispatching the new flow.
     */

    Promise.all([
      dispatch(clearBooking()),
      dispatch(clearFlight())
    ]).then(() => dispatch(setCurrentFlow(flow)));
  }, [dispatch]);

  useEffect(() => {
    /**
     * Listening to service defined by booking started on bagpoint.com
     */
    const urlParams = new URLSearchParams(window.location.search);
    const service = urlParams.get('service');
    if (service) {
      setSelectedFlow(service as Flow)
      return
    }

    if (!currentFlow)
      setSelectedFlow(flow)
  }, [setSelectedFlow, flow, currentFlow])

  if (paymentResult?.resultCode === PaymentResultCode.Authorised)
    return <Navigate to="/summary" replace />

  return (
    <div className={`${styles.container}`}>
      <FlowSelector
        availableFlows={flows}
        onSelectedFlow={setSelectedFlow}
        currentFlow={flow}
        behaviour={flowRules}
      />
      <div className={`${styles.steps}`}>
        {flows[flow] && flows[flow].components
          .filter((key: Steps) => {
            let isComponent = key in components;
            if (!isComponent) console.debug('Invalid step name defined in channel properties.');

            return isComponent;
          })
          .map((key: Steps, index: number) => {
            const Component = components[key];
            return (
              <MetaProvider key={key} progress={progress} flow={flow} step={index + 1} stepName={key}>
                <Component />
              </MetaProvider>
            )
          })}
      </div>
      {showPriceOverview !== false ? <PriceOverview /> : <div />}
    </div>
  );
};

export default BookingFlow;
