import ClientOAuth2, {Token} from "client-oauth2";
import ENV from "./environments";
import store from "../store";
import Fetcher from "./fetcher";

interface StoredToken {
  data: ClientOAuth2.Data;
  expires: Date;
}

interface Client {
}

abstract class BaseClient implements Client {
  abstract client: ClientOAuth2;

  abstract refresh(): Promise<Token>;

  abstract generate(): Promise<Token>;

  protected clientId: string;
  protected storageKey: string;
  public token?: Token;

  protected constructor(key: string, clientId?: string) {
    if (clientId === undefined) throw new Error("No client id defined");
    this.storageKey = key;
    this.clientId = clientId;
  }

  public get storage(): StoredToken {
    let storage = JSON.parse(localStorage.getItem(this.storageKey) || "{}");
    if (this.clientId in storage) {
      return storage[this.clientId];
    }
    storage = {...storage, [this.clientId]: {data: {}}};
    localStorage.setItem(this.storageKey, JSON.stringify(storage));
    return storage[this.clientId];
  }

  public isAuthenticated(): boolean {
    /**
     * Validates the availability of an accessToken in the local storage
     */
    const storage: StoredToken = this.storage;
    return 'access_token' in storage.data;
  }

  public async auth(): Promise<Token> {
    /**
     * Restores a token. The method first looks at the class object to avoid restoring the object from storage
     * every time. If the object is null, it will attempt to restore it from local storage.
     */

    let token: Token;
    if (this.token === undefined && this.isAuthenticated()) {
      // Handle stored authentication details
      const storage: StoredToken = this.storage;
      token = this.client.createToken(storage.data);
      token.expiresIn(new Date(storage.expires));
    } else if (this.token !== undefined) {
      // Retrieve the instanced authentication token
      token = this.token;
    } else {
      // Generate a new authentication token
      token = await this.generate();
    }

    // Validate token and refresh if necessary
    if (token.expired() && this instanceof AuthenticatedClient) {
      token = await this.refresh();
    } else if (token.expired()) {
      token = await this.generate();
    }
    this.token = token;
    return this.token;
  }

  public async refreshToken(): Promise<Token> {
    let token: Token;
    if (this.token !== undefined) {
      // Retrieve the instanced authentication token
      token = this.token;
    } else {
      // Generate a new authentication token
      token = await this.generate();
    }

    // Refresh token
    if (token && this instanceof AuthenticatedClient) {
      token = await this.refresh();
    } else {
      token = await this.generate();
      localStorage.clear();
    }
    this.token = token;
    return this.token;
  }

  public clear() {
    localStorage.removeItem(this.storageKey);
    window.location.replace(ENV.BASE_PATH);
  }
}

class AuthenticatedClient extends BaseClient {
  public client: ClientOAuth2;
  private username?: string;
  private password?: string;

  constructor(username?: string, password?: string) {
    super('authenticated', ENV.CLIENT_CUSTOMER_ID);
    this.username = username;
    this.password = password;

    this.client = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: "",
      accessTokenUri: `${ENV.API_URL}/o/token/`,
      authorizationUri: `${ENV.API_URL}/o/authorize/`,
    });
  }

  public async login(username: string, password: string): Promise<Token> {
    this.username = username;
    this.password = password;
    return this.generate()
  }

  public async refresh(): Promise<Token> {
    if (this.token === undefined) throw Error('Invalid token');

    return this.token.refresh().then((token) => {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify({
          [this.clientId]: {
            data: token.data,
            expires: token.expiresIn(parseInt(token.data.expires_in))
          }
        })
      );

      return token;
    });
  }

  public async generate(): Promise<Token> {
    /**
     * Generate a new token for use with the given application and store the resulting information into
     * our local storage.
     */

    if (this.username === undefined || this.password === undefined) {
      this.clear();
      throw Error(
        "Authenticated Client may only generate a token if both a username and password are provided. " +
        "Try refreshing the token instead."
      );
    }

    return this.client.owner
      .getToken(this.username, this.password, {
        body: {
          grant_type: "password",
        },
      })
      .then((token: Token) => {
        this.token = token;
        localStorage.setItem(
          this.storageKey,
          JSON.stringify({
            [this.clientId]: {
              data: token.data,
              expires: token.expiresIn(parseInt(token.data.expires_in))
            }
          })
        );
        return this.token;
      });
  }
}

class UserlessClient extends BaseClient {
  public client: ClientOAuth2;

  public constructor() {
    super('userless', ENV.CLIENT_USERLESS_ID);

    this.client = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: "",
      accessTokenUri: `${ENV.API_URL}/o/token/`,
    });
  }

  async refresh(): Promise<Token> {
    throw Error('Userless token cannot be refreshed');
  }

  async generate(): Promise<Token> {
    /**
     * Generate a new token for use with the given application and store the resulting information into
     * our local storage.
     */

    return this.client.credentials.getToken().then((token: Token) => {
      let storage = JSON.parse(localStorage.getItem(this.storageKey) || "{}");
      storage = {
        ...storage, [this.clientId]: {
          data: token.data,
          expires: token.expiresIn(parseInt(token.data.expires_in))
        }
      };
      localStorage.setItem(this.storageKey, JSON.stringify(storage));
      return token;
    });
  }
}

class RegistrationClient extends BaseClient {
  public client: ClientOAuth2;

  public constructor() {
    super('registration', ENV.CLIENT_REGISTRATION_ID);
    this.client = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: "",
      accessTokenUri: `${ENV.API_URL}/o/token/`,
    });
  }

  async refresh(): Promise<Token> {
    throw Error('Registration token cannot be refreshed');
  }

  async generate(): Promise<Token> {
    /**
     * Generate a new token for use with the given application and store the resulting information into
     * our local storage.
     */

    return this.client.credentials.getToken().then((token: Token) => {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify({
          [this.clientId]: {
            data: token.data,
            expires: token.expiresIn(parseInt(token.data.expires_in))
          }
        })
      );

      return token;
    });
  }
}

class PasswordRecoverClient extends BaseClient {
  public client: ClientOAuth2;

  public constructor() {
    super('passwordRecover', ENV.CLIENT_PASSWORD_RECOVER_ID);
    this.client = new ClientOAuth2({
      clientId: this.clientId,
      clientSecret: "",
      accessTokenUri: `${ENV.API_URL}/o/token/`,
    });
  }

  async refresh(): Promise<Token> {
    throw Error('Password recover token cannot be refreshed');
  }

  async generate(): Promise<Token> {
    /**
     * Generate a new token for use with the given application and store the resulting information into
     * our local storage.
     */

    return this.client.credentials.getToken().then((token: Token) => {
      localStorage.setItem(
        this.storageKey,
        JSON.stringify({
          [this.clientId]: {
            data: token.data,
            expires: token.expiresIn(parseInt(token.data.expires_in))
          }
        })
      );

      return token;
    });
  }
}

class Authentication {
  private static clients: { [name: string]: BaseClient } = {
    authenticated: new AuthenticatedClient(),
    userless: new UserlessClient(),
    registration: new RegistrationClient(),
    passwordRecover: new PasswordRecoverClient()
  }

  public static isLoggedIn(): boolean {
    return Authentication.isAuthenticated("authenticated");
  }

  public static isAuthenticated(client: "authenticated" | "registration" | "userless"): boolean {
    return Authentication.clients[client].isAuthenticated();
  }

  private static get client(): BaseClient {
    return Authentication.isLoggedIn() ? Authentication.clients.authenticated : Authentication.clients.userless;
  }

  public static async login(username: string, password: string): Promise<Token> {
    const bookingCode = store.getState().booking?.details?.code;
    const oldToken = Authentication.clients?.userless?.token;

    let client = Authentication.clients.authenticated
    if (!(client instanceof AuthenticatedClient)) throw Error("Invalid client type");

    const login = await client.login(username, password);

    if (bookingCode && oldToken) this.changeBookingOwnership(oldToken, bookingCode).catch((error) => console.debug('Error changing ownership', error));
    return login
  }

  private static changeBookingOwnership(oldToken: Token, bookingCode: string): Promise<Token> {
    return Fetcher(
      `${ENV.API_URL}/bookings/${bookingCode}/claim/`,
      'POST',
      {access_token: oldToken.accessToken}
    ).then((response) => response.data)
  };

  public static logout(): Promise<Token> {
    /**
     * Removes the authenticated clients and creates a new token
     */
    Authentication.clients.authenticated.clear();

    return Authentication.client.auth();
  }

  public static refreshToken(): Promise<Token> {
    return Authentication.client.refreshToken();
  }


  public static async auth(username: string, password: string): Promise<Token>;
  public static async auth(client: "authenticated" | "registration" | "userless" | "passwordRecover"): Promise<Token>;
  public static async auth(): Promise<Token>;
  public static async auth(
    ...rest: string[]
  ): Promise<any> {
    /**
     * Retrieve the current authenticated token.
     *
     * If a username and password is passed, the application will attempt to login using the AuthenticatedClient.
     *
     * If a client is passed, the application will return the requested client
     */
    if (rest.length === 2) {
      let username: string = rest[0];
      let password: string = rest[1];
      return Authentication.login(username, password);
    }

    let client = rest.length ? Authentication.clients[rest[0]] : Authentication.client;

    return client.auth().catch(error => console.error(error.message)
	);
  }
}

export default Authentication;
