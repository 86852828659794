import React, {useCallback} from "react";
import useTranslations from "../../../hooks/useTranslations";
import styles from "./FlowSelector.module.scss";
import Icon from "../../../components/icons/Icon/Icon";
import {Flow, FlowOption, IChannelTranslationsState, Switch} from "../../../store/channel/channel.types";
import Checkbox from "../../../resources/svg/CheckBox.svg";
import {useLocation, useNavigate} from "react-router-dom";

interface FlowInfoProps {
  onSelectedFlow: (flow: Flow) => void;
  availableFlows: FlowOption;
  currentFlow: Flow;
  behaviour: Switch<Flow>;
}

const FlowSelector: React.FC<FlowInfoProps> = ({onSelectedFlow, availableFlows, currentFlow, behaviour}) => {

  const {translation} = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();

  const onSelect = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const flow = e?.currentTarget?.value as Flow;
    if (flow === currentFlow) return;
    onSelectedFlow(flow);
    navigate(location.pathname);
  }, [onSelectedFlow, currentFlow, navigate, location])

  return (
    <>
      {behaviour.display &&
        <div className={`${styles.page} ${styles.wrapper}`}>
          <div className={styles.container}>
            <div className={styles.tabs}>
              {availableFlows && Object.entries(availableFlows).map(([key, value]) => (
                <React.Fragment key={key}>
                  <button
                    data-cy="flow-select"
                    className={currentFlow === key ? `${styles.selected} ${styles.button}` : styles.button}
                    value={key} onClick={onSelect}>
                    <div id={`${value.title} checkbox`}
                         className={currentFlow === key ? `${styles.selected} ${styles.checkboxContainer}` : styles.checkboxContainer}>
                      {currentFlow === key && <Icon className={styles.checkboxIcon} src={Checkbox}/>}
                    </div>
                    <div className={styles.content}>
                      <div className={styles.iconContainer}>
                        <Icon className={styles.icon} src={value.icon || ''}/>
                      </div>
                      <div className={styles.textContainer}>
                        <div className={styles.title}>
                          {translation.get(value.title as keyof IChannelTranslationsState)}
                        </div>
                        <div>
                          {translation.get(`flow:subtitle:${key}` as keyof IChannelTranslationsState)}
                        </div>
                      </div>
                    </div>
                  </button>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default FlowSelector;