import "react-phone-number-input/style.css";

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Svg from "components/images/Svg";
import Switch from "components/inputs/Switch/Switch";
import useTranslations from "hooks/useTranslations";
import { useAppSelector } from "store";
import { BookingState } from "store/booking/types/store";
import { FlightState, Passenger } from "store/flight/types/store";
import styles from "./NeomPaymentStepActive.module.scss";
import Authentication from "utils/authentication";
import ContactForm, { Fields } from '../ContactForm/ContactForm'
import ValidationError from "components/ValidationError";
import { ActiveStepProps } from "components/containers/Step/types";
import { ChannelProperties, Flow } from "store/channel/channel.types";
import {
  PaymentContact,
  Payment,
  PaymentState
} from "store/payment/paymentTypes";
import { OAuth2Error, UserResponse } from "store/auth/authTypes";
import AuthService from "store/auth/authService";
import PaymentSuccess from 'resources/svg/PaymentSuccess.svg';
import Button from "components/inputs/buttons/Button/Button";
import VerificationForm from "components/VerificationForm/VerificationForm";

const errorInitialState = { general: '' }

declare function UpdateCallback(data: { email: string }): void;
declare function UpdateCallback(data: { payment?: Payment, bookingCode?: string }): void;
declare function UpdateCallback(data: { resetBookingPayment: boolean }): void;
declare function UpdateCallback(data: { bookingCode?: string, contact?: PaymentContact, verificationCode?: string, reference?: string }): void;

export type PaymentStepCallbackProps = typeof UpdateCallback;


interface Props extends ActiveStepProps {
  onUpdate: PaymentStepCallbackProps;
  onPaymentCompleted: () => void;
  data: {
    createAccount: boolean;
    properties: ChannelProperties;
    booking: Required<BookingState>;
    user: UserResponse;
    language: string;
    payment: Partial<Required<PaymentState>>;
    flow: Flow;
    flight: Required<FlightState>;
  }
}

const NeomPaymentStepActive: React.FC<Props> = ({ onUpdate, onPaymentCompleted, data }: Props) => {
  /**
   * Handle customer payment information and user creation.
   */
  const { translation } = useTranslations();
  const [errors, setErrors] = useState<{ general: string }>(errorInitialState);

  const passengers = useMemo(() => data.flight.passengers.filter(passenger => passenger.luggage > 0) as Required<Passenger>[], [data.flight])
  
  const properties = useAppSelector(state => state.channel.properties);
  const userDisplay = useAppSelector(state => state.channel.properties.switches.user.display)
  const verification = useAppSelector(state => state.payment.verification)
  const contact = useAppSelector(state => state.payment.contact)
  const bookingCode = useAppSelector(state => state.booking.details.code)

  const initialState = {
    email: contact.email,
    confirmEmail: contact.email,
    firstName: contact.first_name,
    lastName: contact.last_name,
    passportNumber: passengers.find(pax => pax.first_name === contact.first_name)?.passport || "",
    phoneNumber: contact.phone_number,
    password: "",
    confirmPassword: "",
  }

  const [login, setLogin] = useState<boolean>(false);
  const [register, setRegister] = useState<boolean>(false);

  const [formData, setFormData] = useState<Fields>(initialState);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formRef, languageRef] = [
    useRef<Fields>(formData),
    useRef<string>(""),
  ];

  const {
    email,
    confirmEmail,
    firstName,
    lastName,
    passportNumber,
    phoneNumber,
    password,
    confirmPassword,
    general
  } = formData;

  useEffect(() => {
    /**
     * These are necessary in order to update the reference in calls made outside this execution context.
     */
    formRef.current = { email, confirmEmail, firstName, lastName, passportNumber, phoneNumber, password, confirmPassword, general };
    languageRef.current = data.language;
  }, [formRef, languageRef, data.language, email, firstName, lastName, passportNumber, phoneNumber, password, confirmPassword, general, confirmEmail]);

	const verified = useMemo(() => {
		return verification?.status === "STATUS.PROGRESS.010"
	}, [verification])

  const handleLogin = useCallback(async () => {
    /**
     * Authenticate a given user.
     */
    if (!formRef.current.email || !formRef.current.password) throw new Error('no userdata');
    await Authentication.login(formRef.current.email, formRef.current.password).then(() => {
      setErrors(() => errorInitialState);
      onUpdate({ email: formRef.current.email });
    }).catch((error: OAuth2Error) => {
      throw error
    })
  }, [formRef, onUpdate])

  const handleRegistration = useCallback(() => {
    /**
     * Register and authenticate a given user.
     */
    return AuthService.register(formRef.current as Required<Fields>)
      .then(async () => {
        setRegister(false);
        await handleLogin();
      })
      .catch((error) => {
        const { email } = error?.response?.data?.detail;
        setErrors(() => ({ general: email }));
        throw Error(error);
      })
  }, [formRef, handleLogin])

  const handleFormChange = (formData: Fields) => {
    setFormData(() => formData);
  }

  const submitContactForm = () => {
    onUpdate({
			bookingCode,
      contact: {
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
				language: data.language,
        ...formData.phoneNumber && { phone_number: formData.phoneNumber }
      }
    })
  }

  const editContactForm = () => {
		onUpdate({
			bookingCode,
      contact: undefined
    })
  }

	const submitVerificationForm = (code: string) => {
		onUpdate({bookingCode, verificationCode: code, reference: verification.reference})
	}

	useEffect(() => {
		let timeoutId:ReturnType<typeof setTimeout>;;
	
		if (verified) {
			timeoutId = setTimeout(() => {
				onPaymentCompleted();
			}, 3000);
		}
	
		// Cleanup
		return () => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		};
	}, [verified, onPaymentCompleted]);

  return (
    <React.Fragment>
      <h2 className={styles.heading}>
        {translation.get("payment:heading")}
      </h2>

      <div className={styles.wrapper}>
        <div className={styles.selector}>
          {properties.switches.user.display && <Switch
            leftLabel={translation.get("payment:switch_new")}
            rightLabel={translation.get("payment:switch_login")}
            initial={"left"}
            onChange={(selected) => setLogin(selected === "right")}
          />}
        </div>
        <div className={styles.container}>
          <div className={`${verification && styles.disableContactForm}`}>
            {
              /*
              * Contact information
              */
            }

            {userDisplay && !login && <div className={styles.checkbox}>
              <input
                type="checkbox"
                id="createAccount"
                value={`${register}`}
                checked={register}
                onChange={() => setRegister(!register)}
              />
              <label htmlFor="createAccount">
                {translation.get("payment:label_create")}
              </label>
            </div>}

            <ContactForm
              formData={formData}
              login={login}
              register={register}
              handleLogin={handleLogin}
              handleRegistration={handleRegistration}
              onChange={handleFormChange}
              setValidForm={setFormIsValid}
							disabled={!!verification}
            />
            {errors.general && (
              <ValidationError error={errors.general} />
            )}

          </div>

          {verification ?
            <Button
              id="edit-contact"
              data-cy="edit-contact"
              type="submit"
              className={styles.submitContact}
              onClick={editContactForm}
              text={translation.get("button:edit")}
							disabled={verified}
            />
            :
            <Button
              id="submit-contact"
              data-cy="submit-contact"
              type="submit"
              className={styles.submitContact}
              onClick={submitContactForm}
              text={translation.get("button:submit")}
              disabled={!formIsValid}
            />
          }

        </div>
					<div className={styles.verificationForm}>

            {verified && <div className={styles.verificationSuccessful}>
							<Svg url={PaymentSuccess} className={styles.successIcon} />
							<span>{translation.get("payment:verification:success")}</span>
            </div>}

						{verification && !verified &&
							<VerificationForm
								data-cy="verification-form"
								autoFocus
								resendCode={submitContactForm}
								onChange={() => {}} 
								inputs={['text' || 'number', 'text' || 'number', 'text' || 'number', 'text' || 'number', 'text' || 'number', 'text' || 'number']}
								onSubmit={submitVerificationForm}
							/>
						}
					</div>
      </div>
    </React.Fragment>
  );
}

export default NeomPaymentStepActive;