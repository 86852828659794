import React from "react";
import styles from "./StepNumber.module.scss";
import {useAppSelector} from "../../store";

export interface IStepNumberProps {
  number: number;
}

const StepNumber = ({number}: IStepNumberProps) => {
  const progress = useAppSelector((state) => state.booking.flow.progress);

  let numberClass: 'done' | 'active' | null = null;
  let lineClass: 'line-done' | 'active' | null = null;

  if (progress > number) {
    lineClass = "line-done";
    numberClass = "done";
  } else if (progress === number) {
    lineClass = "active";
    numberClass = "active";
  }

  return (
    <>
      <div className={`${styles["step-number"]} ${numberClass && styles[numberClass]}`}>
        <span>{number}</span>
      </div>

      {number && (
        <div className={styles["line-container"]}>
          <div className={`${styles["line"]} ${lineClass && styles[lineClass]}`} />
        </div>
      )}
    </>
  );
};

export default StepNumber;
