import Icon from "components/icons/Icon";
import PnrIcon from "components/icons/PnrIcon";
import Button from "components/inputs/buttons/Button";
import TextInput from "components/inputs/TextInput";
import SuperscriptedText from "components/SuperscriptedText";
import React, { createRef, useCallback, useMemo, useState } from "react";
import { ActiveStepProps } from "../../../../../components/containers/Step/types";
import useTranslations from "../../../../../hooks/useTranslations";
import {
  FlightLeg,
  PnrFlight,
  PnrPassenger
} from "../../../../../store/flight/flightTypes";
import {PNRFlightStepData} from "../PnrFlightStep";
import styles from "./PnrFlightStepActive.module.scss";
import InfoIcon from '../../../../../resources/svg/Info.svg'
import User from '../../../../../resources/svg/User.svg';
import sharedStyles from "../../../../../styles/Shared.module.scss";
import ValidationError from "components/ValidationError";

declare function UpdateCallback(data: { info?: PnrFlight; pnr?: string; leg?: FlightLeg; }): void;
declare function UpdateCallback(data: { passengers?: PnrPassenger[] }): void;

declare function SubmitCallback(data: PNRFlightStepData): void;

interface Props extends ActiveStepProps {
  onUpdate: (data: { pnr: string, lastName: string }) => typeof UpdateCallback;
  onSubmit: (data: PNRFlightStepData) => Promise<typeof SubmitCallback>;
}

const PnrFlightStepActive: React.FC<Props> = ({ errors, onUpdate }) => {
  const { translation } = useTranslations();
  const pnrRef = createRef<HTMLInputElement>();
  const [formData, setFormData] = useState<{ pnr: string; lastName: string; }>({ pnr: '', lastName: '' });
  const { pnr, lastName } = formData;

  const onFieldChange = (value: string, field: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit = useCallback(async () => {
    onUpdate({pnr: pnr.trim(), lastName: lastName.trim()})
  }, [pnr, lastName, onUpdate])

  const disableSubmit = useMemo(() => pnr.trim() === "" || lastName.trim() === "", [pnr, lastName])

  return (
    <div className={styles.container}>
      <h2>{translation.get("flight:heading")}</h2>
      <h4>{translation.get("flight:subHeading:pnr")}</h4>
      <div className={styles.formWrapper}>
        <div className={styles.flightInputContainer}>
          <div className={styles.pnrContainer}>
            <TextInput
              data-cy="flight-pnr"
              ref={pnrRef}
              icon={PnrIcon}
              label={
                <SuperscriptedText
                  text={translation.get("flight:number:label_pnr")}
                  superscriptIcon={InfoIcon}
                  info={<div style={{ width: '150px' }}>{translation.get('flight:number:label_pnr:info')}</div>}
                />}
              name="pnr"
              value={pnr}
              onChange={(e) => onFieldChange(e.target.value.toUpperCase(), 'pnr')}
              error={errors.pnr && errors.pnr}
            />
          </div>

          <div className={styles.nameContainer}>
            <TextInput
              data-cy="flight-lastname"
              icon={<Icon
                src={User}
                className={`${styles.icon}`}
              />}
              label={translation.get("flight:number:label_last_name")}
              name="lastName"
              value={lastName}
              onChange={(e) => onFieldChange(e.target.value, 'lastName')}
              inputClassName={styles.nameInput}
              error={errors.lastName && errors.lastName}
            />
          </div>
          <ValidationError error={errors.date}/>
        </div>
      </div>
      <div className={sharedStyles["submit-button-wrapper"]}>
        <Button
          type="submit"
          data-cy="flight-submit"
          text={translation.get("button:next")}
          onClick={handleSubmit}
          disabled={disableSubmit}
        />
      </div>
    </div>
  )
    ;
}

export default PnrFlightStepActive;