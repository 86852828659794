import React, {createContext, useContext, useEffect, useState} from "react";
import useTranslations from "./useTranslations";

export interface Cookie {
  title: string;
  content: string;
  type: string;
  value: boolean;
  disabled: boolean;
}

interface Consent {
  necessary: boolean;
  statistics: boolean;
}

interface CookieContextInterface {
  consent: Consent;
  cookies: Array<Cookie>;
  checkCookies: () => boolean;
  setCookies: React.Dispatch<React.SetStateAction<Cookie[]>>;
  acceptCookies: (cookies: { necessary: boolean, statistics: boolean }) => void;
}

export const CookieContext = createContext({} as CookieContextInterface)

export const CookieProvider = ({children}: { children: React.ReactNode }): JSX.Element => {
  /**
   * Provider for cookies. Work similarly to the package we used to use.
   * https://www.npmjs.com/package/@use-cookie-consent/react
   */
  const {translation} = useTranslations();

  const getCookies = () => {
    let initialCookies = {necessary: true, statistics: false} as Consent;
    const cookieArray = document.cookie.split(";");
    if (!cookieArray?.length) return initialCookies;
    const consent = cookieArray.find((item) => item.includes('consent') || item.includes("USE_COOKIE_CONSENT_STATE"));
    if (!consent) return initialCookies;
    return JSON.parse(consent.split('=')[1]) as Consent;
  }

  const checkCookies = () => {
    const consent = document.cookie.split(";")
      .find((item) => item.includes('consent') || item.includes("USE_COOKIE_CONSENT_STATE"))
    return !!consent;
  }

  const [consent, setConsent] = useState(getCookies());
  const [cookies, setCookies] = useState<Array<Cookie>>([]);

  useEffect(() => {
    /*
      Initializes cookie options with translations.
     */
    if (translation === undefined) return;

    setCookies(() => ([{
      title: translation.get('cookies:necessary:title'),
      content: translation.get('cookies:necessary:content'),
      value: consent.necessary,
      disabled: true,
      type: 'necessary'
    }, {
      title: translation.get('cookies:statistic:title'),
      content: translation.get('cookies:statistic:content'),
      value: consent.statistics,
      disabled: false,
      type: 'statistic'
    }]))
  }, [translation, consent.necessary, consent.statistics])

  const acceptCookies = ({necessary, statistics}: { necessary: boolean, statistics: boolean }) => {
    /**
     * Cookie is valid for a year using `max-age=31536000`
     */
    setConsent(() => ({necessary, statistics}));
    document.cookie = `consent=${JSON.stringify({necessary, statistics})}; Max-Age=31536000`;
  }

  return <CookieContext.Provider
    value={{consent, cookies, checkCookies, setCookies, acceptCookies}}>{children}</CookieContext.Provider>
}

export const useCookies = () => {
  return useContext(CookieContext);
};