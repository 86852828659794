import Icon from "../Icon";
import Circle from "../../../resources/svg/WarningCircle.svg";
import React from "react";

interface Props {
  className?: string;
}

const WarningCircle: React.FC<Props> = ({className='' }) => {
  return <Icon
    className={`${className}`}
    src={Circle}
  />
}

export default WarningCircle;