import useTranslations from "../../hooks/useTranslations";
import { AddressValidators } from "../../hooks/useValidation";
import Utils from "../../utils/Utils";
import TextArea from "../inputs/TextArea";
import styles from "./AddressForm.module.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import TextInput from "../inputs/TextInput";
import { Address, Location } from "../../store/booking/bookingTypes";
import { useProperties, useValidation } from "../../hooks";

export interface AddressFormErrors {
  [key: string]: string
}

type AddressFields = Omit<Address, 'coordinates' | 'region' | 'community' | 'address_type' | ''>
interface AddressFormProps {
  disabled: boolean;
  location?: Location;
  className: string;
  country: string;
  showDescription?: boolean;
	errors: AddressFormErrors | undefined;
  onUpdate: (data: AddressFields) => void;
  onError: (errors: AddressFormErrors) => void;
}


const AddressForm = ({ className, disabled, location, country, showDescription, errors: propErrors, onUpdate, onError }: AddressFormProps) => {
  /**
   * This component collects and validates address information from the customer using
   * a standardized address form.
   *
   * It also ties in to the map address selector using the redux store.
   */

  const { locale } = useProperties();
  const { translation } = useTranslations();
  const { addressValidators } = useValidation();
  const [errors, setErrors] = useState<AddressFormErrors>(propErrors || {})

  const [timeoutId, setTimeoutId] = useState<number | null>(null);

  const fieldFocusStatus = useRef({
    street: false,
    number: false,
    addition: false,
    city: false,
    postal_code: false,
    country: false,
  });

  const countryName = useMemo(() => {
    /**
     * Fix location country name
     */
    return Utils.geo.getCountryNameFromISO(country, locale);
  }, [locale, country])

  const emptyForm = useMemo(() => ({
    street: "",
    number: "",
    addition: "",
    city: "",
    postal_code: "",
    description: "",
    country: countryName
  }), [countryName])

  const [form, setForm] = useState<AddressFields>(emptyForm);

  const isFilledIn = useMemo(() => {
    const fields = Utils.object
      .entries(form)
      .filter(([key, value]) => value === ""
        && key !== 'addition'
        && key !== 'description'
      )

    return fields.length === 0;
  }, [form])

  const validateFields = useCallback((fields: AddressFields) => {
    let errors: AddressFormErrors = Object.fromEntries(Utils.object
      .entries(fields)
      .map(([key, value]) => [key, addressValidators[key](value)])
      .filter(([_key, value]) => {
        return typeof value === 'string'
      })
    )

    setErrors(errors)
    onError(errors)

    return errors
  }, [addressValidators, onError])

  const getLocation = useCallback(() => {
    if (!isFilledIn || Object.keys(errors).length) return;

    if (
      location?.address.street === form.street
      && location?.address.number === form.number
      && location?.address.postal_code === form.postal_code
      && location?.address.city === form.city
      && location?.address.addition === form.addition
    ) return;

		onUpdate(form)
  }, [isFilledIn, errors, location, form, onUpdate]);
  

  const onFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    /**
     * Listen to form changes and update the form data according.
     *
     * In the case where any field which is not a number or an addition is modified,
     * the street number and addition will be removed.
     */
    const field = event.target.name as AddressValidators;
    let data = { [field]: event.target.value };
    if (field !== 'number' && field !== 'addition') {
      data = { ...data, number: '', addition: '' }
    }

    validateFields({ ...form, ...data })

    setForm((form) => ({ ...form, ...data }));

    // Check if all fields are not focused
    if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
      getLocation()
    }
  }, [form, getLocation, validateFields])

  useEffect(() => {
    /**
     * If the location or country is changed, we update the form data to match
     * the selected location.
     */

    if (location === undefined) {
      setForm(emptyForm);
      return;
    }

    let fields = {
      street: location?.address.street || "",
      number: location?.address.number || "",
      addition: location?.address.addition || "",
      postal_code: location?.address.postal_code || "",
      city: location?.address.city || "",
      country: countryName,
    }

    validateFields(fields)

    setForm(fields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

	useEffect(() => {
		if(propErrors) setErrors(propErrors);
	}, [propErrors])

  return (
    <div className={`${styles.address} ${className}`}>
      <div>
        <TextInput
          data-cy="city"
          name={"city"}
          value={form.city}
          label={translation.get("address:city")}
          onChange={onFieldChange}
          labelContainerClassName={styles.narrowColumn}
          error={errors.city && errors.city}
          disabled={disabled}
          onFocus={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            fieldFocusStatus.current.city = true;
          }}
          onBlur={() => {
            fieldFocusStatus.current.city = false;
            const id = window.setTimeout(() => {
              if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
                getLocation();
              }
            }, 10);
            setTimeoutId(id);
          }}
        />
        <TextInput
          data-cy="postal-code"
          customProperty
          name={"postal_code"}
          value={form.postal_code}
          label={translation.get("address:postalcode")}
          labelContainerClassName={styles.narrowColumn}
          onChange={onFieldChange}
          error={errors.postal_code && errors.postal_code}
          disabled={disabled}
          onFocus={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            fieldFocusStatus.current.postal_code = true;
          }}
          onBlur={() => {
            fieldFocusStatus.current.postal_code = false;
            const id = window.setTimeout(() => {
              if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
                getLocation();
              }
            }, 10);
            setTimeoutId(id);
          }}
        />
      </div>
      <div>
        <TextInput
          data-cy="street"
          customProperty
          name={"street"}
          value={form.street}
          label={translation.get("address:street:name")}
          labelContainerClassName={styles.wideColumn}
          onChange={onFieldChange}
          error={errors.street && errors.street}
          disabled={disabled}
          onFocus={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            fieldFocusStatus.current.street = true;
          }}
          onBlur={() => {
            fieldFocusStatus.current.street = false;
            const id = window.setTimeout(() => {
              if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
                getLocation();
              }
            }, 10);
            setTimeoutId(id);
          }}
        />
        <TextInput
          data-cy="address-number"
          customProperty
          name={"number"}
          value={form.number}
          label={translation.get("address:street:number")}
          labelContainerClassName={styles.maxWidth}
          onChange={onFieldChange}
          error={errors.number && errors.number}
          disabled={disabled}
          onFocus={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            fieldFocusStatus.current.number = true;
          }}
          onBlur={() => {
            fieldFocusStatus.current.number = false;
            const id = window.setTimeout(() => {
              if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
                getLocation();
              }
            }, 10);
            setTimeoutId(id);
          }}
        />
        <TextInput
          data-cy="address-addition"
          customProperty
          name={"addition"}
          value={form.addition}
          label={translation.get("address:street:addition")}
          labelContainerClassName={styles.maxWidth}
          onChange={onFieldChange}
          error={errors.addition && errors.addition}
          disabled={disabled}
          onFocus={() => {
            if (timeoutId) {
              clearTimeout(timeoutId);
            }
            fieldFocusStatus.current.addition = true;
          }}
          onBlur={() => {
            fieldFocusStatus.current.addition = false;
            const id = window.setTimeout(() => {
              if (Object.values(fieldFocusStatus.current).every(focusStatus => !focusStatus)) {
                getLocation();
              }
            }, 10);
            setTimeoutId(id);
          }}
        />
      </div>
      <div>
        <TextInput
          disabled
          name={"country"}
          value={form.country}
          label={translation.get("address:country")}
          onChange={onFieldChange}
          error={errors.country && errors.country}
        />
      </div>
      {showDescription && <div>
        <TextArea
          disabled
          name={"description"}
          value={form.country}
          label={translation.get("geo_form:description")}
          onChange={onFieldChange}
        />
      </div>
      }
    </div>
  )
}

export default AddressForm
