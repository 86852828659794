import React from 'react';
import {Location, PickupType} from '../../../../store/booking/bookingTypes'
import styles from './InfoWindowContent.module.scss'

interface InfoWindowContentProps {
  type: PickupType;
  description?: string;
  location: Location;
}

const InfoWindowContent = ({location, description, type}: InfoWindowContentProps) => {
  const address = {
    ...location.address.street && {street: location.address.street},
    ...location.address.number && {number: location.address.number},
    ...location.address.postal_code && {postal_code: location.address.postal_code},
    ...location.address.city && {city: location.address.city},
    ...location.address.country && {country: location.address.country},
  }

  if (type === PickupType.Hub)
    return (
      <div className={styles.container}>
        <div className={styles.name}>{location.name}</div>
        <div className={styles.address}>{`${Object.values(address).map((item) => ' ' + item)}`}</div>
        <div className={styles.description}>{location.description}</div>
      </div>
    )

  if (type === PickupType.Address)
    return (
      <div className={styles.container}>
        <div className={styles.address}>{location.name}</div>
        <div className={styles.description}>{description || location.description}</div>
      </div>
    )

  return <></>;
}

export default InfoWindowContent
