import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ITenant} from "../../store/portal/portalTypes";
import BookingDetails from "./BookingDetails";
import styles from "./Summary.module.scss";
import PickupStep from "./steps/Pickup/PickupStep";
import DropOffStep from "./steps/Dropoff/DropOffStep";
import FlightStep from "./steps/Flight/FlightStep";
import StepCard from "./StepCard/StepCard";
import {Flow, StepOrder} from "../../store/channel/channel.types";
import Suitcase from "../../resources/svg/Suitcase.svg"
import LuggageCart from "../../resources/svg/LuggageCart.svg";
import PlaneDeparture from "../../resources/svg/PlaneDeparture.svg";
import ThankYouMessage from "./ThankYouMessage/ThankYouMessage";
import {useAppSelector, useAppDispatch} from "../../store";
import {BookingState} from "../../store/booking/types/store";
import FlightService from "store/flight/flightService";
import PaymentService from "store/payment/paymentService";
import {PnrJourney} from "store/flight/flightTypes";
import {toast} from "react-toastify";
import {Journey} from "store/flight/types/api/reservation";
import Spinner from "components/Spinner";
import useTranslations from "hooks/useTranslations";
import {setCurrentFlow} from "store/app/appReducer";

const BookingSummary: React.FC = () => {
  /**
   * For now, we just set the delivery type here, but eventually we'll get it from somewhere.
   *
   * Components are more or less ready to handle the difference between airport and home delivery:
   *    Components are not showing unnecessary airport data for home delivery but also, they are not
   *    showing anything extra that we would likely want to show besides the locations and times.
   */
  const storeBooking: BookingState = useAppSelector(state => state.booking);
  const storeJourney: PnrJourney = useAppSelector(state => state.flight.journey);
  const flow = useAppSelector(state => state.app.currentFlow || state.channel.properties.switches.flow.default);
  const tenant = useAppSelector(state => state.portal.tenants.find((tenant: ITenant) => tenant.bookingCode === storeBooking.details?.code) || null);

  const [journeyState, setJourneyState] = useState<Journey | null>(null);
  const [bookingState, setBookingState] = useState<BookingState | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const {translation} = useTranslations();
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const flightResponse = await PaymentService.getFlightData({
          flight: params.flightNumber,
          date: params.departureDate.slice(0, 10)
        }, params.token);
        const journey = FlightService.createJourney(flightResponse);
        setJourneyState(journey);

        const bookingResponse = await PaymentService.getBookingData(params.bookingCode, params.token);

        const {service, from_location, to_location} = bookingResponse.assignments[0];

        const isAirportDelivery = service === "injection";
        const locationId = isAirportDelivery ? from_location : to_location;
        const flow = isAirportDelivery ? Flow.Airport : Flow.City;

        dispatch(setCurrentFlow(flow))

        const locationResponse = await PaymentService.getLocationData(locationId, params.token);

        setBookingState({
          details: {...bookingResponse},
          meeting: {...locationResponse},
					flow: {
						progress: 1
					}
        });

      } catch (error) {
        toast.error(translation.get("error:general_error"));
      } finally {
        setLoading(false);
      }
    };

    if (!storeJourney || !storeBooking.details) {
      fetchData();
    }
  }, [params.bookingCode, params.departureDate, params.flightNumber, params.token, storeJourney, storeBooking.details, dispatch, flow, translation]);

  const journey = useMemo(() => storeJourney || journeyState, [journeyState, storeJourney]);
  const booking = useMemo(() => storeBooking.details ? storeBooking : bookingState, [bookingState, storeBooking]);

  const timezone = useMemo(() => {
    return journey?.departure?.schedule?.airport?.timezone || "";
  }, [journey]);

  const stepOrder: StepOrder[] = useMemo(() => {
    if (!booking?.details || !journey?.departure) return [];

    if (flow === Flow.Airport) return [
      {
        step: 1,
        icon: Suitcase,
        component: <PickupStep bookingDetails={<BookingDetails booking={booking} service={'pickup'}/>} booking={booking}
                               type={flow} tenant={tenant} journey={journey}/>,
        date: booking.details.assignments ? booking.details.assignments[0].from_datetime : '',
        hour: booking.details.meta?.window?.label || ''
      },
      {step: 2, icon: LuggageCart, component: <DropOffStep booking={booking} type={flow}/>},
      {
        step: 3,
        icon: PlaneDeparture,
        component: <FlightStep journey={journey} type={flow}/>,
        timeZone: timezone,
        date: journey.departure.schedule.datetime_local || ''
      }
    ];

    return [
      {
        step: 1,
        icon: PlaneDeparture,
        component: <FlightStep journey={journey} type={flow}/>,
        timeZone: timezone,
        date: journey.departure.schedule.datetime_local || ''
      },
      {step: 2, icon: Suitcase, component: <PickupStep booking={booking} type={flow} journey={journey}/>},
      {
        step: 3,
        icon: LuggageCart,
        component: <DropOffStep bookingDetails={<BookingDetails booking={booking} service={'dropoff'}/>}
                                booking={booking} type={flow} tenant={tenant || undefined}/>,
        date: booking.details.assignments ? booking.details.assignments[0].to_datetime : '',
        hour: booking.details.meta?.window?.label || ''
      }
    ];
  }, [booking, flow, tenant, journey, timezone]);

  useEffect(() => {
    if (!loading && (!booking?.details || !booking?.details.assignments || !journey?.departure?.schedule?.datetime)) {
      navigate("/");
    }
  }, [booking?.details, journey?.departure?.schedule?.datetime, loading, navigate]);


  return (
    <div data-cy="booking-summary" className={styles.container}>
      {loading ? <Spinner/> :
        <>
          <div className={styles.thankYou}>
            <ThankYouMessage/>
          </div>
          <div className={styles.content}>
            {stepOrder.map((step, index) => (
              <StepCard
                key={index}
                iconUrl={step.icon}
                date={step.date}
                hour={step.hour}
                component={step.component}
                timeZone={step.timeZone}
              />
            ))}
          </div>
        </>}
    </div>
  );
};

export default BookingSummary;
