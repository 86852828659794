import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import {Token} from "client-oauth2";
import Authentication from "./authentication";

export const requestConfig = async (client?: "authenticated" | "registration" | "userless" | "passwordRecover"): Promise<AxiosRequestConfig | undefined> => {
	/**
   * Generate the authentication headers required to communicate with the API
   */

  try {
		let token;

		if(!client) token = await Authentication.auth();
    else token = await Authentication.auth(client);

    return {
      headers: {
        Authorization: `Bearer ${token.accessToken}`,
      }
    };
  } catch (error) {
    console.error("Authentication failed:", error);
    throw new Error("Failed to get authentication token");
  }
};

const Fetcher = async (endpoint: string, method: Method, body?: object, params?: object, headers?: AxiosRequestConfig): Promise<AxiosResponse> => {
  axios.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    })

  axios.interceptors.response.use(
    async (res: AxiosResponse) => {
      const response = {...res};
      if (response?.data?.next) {
        const urlParams = new URLSearchParams(response.data.next);
        response.data.offset = parseInt(urlParams.get('offset') || '', 10);
      }

      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;

      // Refresh token if it's not an email verification error
      if (
        status === 401
        && !error.response.config.url.endsWith('/verify/')
        && !error.response.config.url.endsWith('/password/')
        && !error.response.config.url.endsWith('/change_password/')
      ) {
        return Authentication.refreshToken()
          .then((token: Token) => {
            error.config.headers['Authorization'] = 'Bearer ' + token.accessToken;
            error.config.baseURL = undefined;
            return axios.request(error.config);
          })
          .catch((error: AxiosError) => {
            console.debug(error);
          });
      }

      return Promise.reject(error);
    });


	try {
		let config = await requestConfig();

		if (!config && !headers) {
      throw new Error("No valid headers or configuration generated");
    }

		return await axios(endpoint, {
			method: method,
			headers: headers?.headers || config?.headers,
			...(body && { data: body }),
			params
		});
	} catch (error) {
		console.error("Request failed with:", error);
		return Promise.reject(error);
	}
}

export default Fetcher;
