import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, useMapEvents, Marker, Polygon, Tooltip } from 'react-leaflet';
import {Map, Marker as LeafletMarker, LatLngExpression, LeafletMouseEvent, Icon, LatLng} from 'leaflet'
import {polygon, union, intersect, point, booleanPointInPolygon, Polygon as PolygonType, Feature, Properties,  MultiPolygon as MultiPolygonType, Position } from '@turf/turf';
import markerIconPng from "leaflet/dist/images/marker-icon.png"

import { useBookingMeta } from "../../../hooks";
import useTranslations from "../../../hooks/useTranslations";
import Bagpoint from '../../../resources/svg/Bagpoint.svg';
import { useAppSelector } from "../../../store";
import BookingService from "../../../store/booking/bookingService";
import { Coordinates, Location, PickupType } from "../../../store/booking/bookingTypes";
import { FlightState } from "../../../store/flight/types/store";
import Utils from "../../../utils/Utils";
import Switch from "../../inputs/Switch/Switch";
import AddressField from "./AddressField/AddressField";
import InfoWindowContent from "./InfoWIndowContent/InfoWindowContent";
import styles from "./MapSelector.module.scss";
import 'leaflet/dist/leaflet.css';
import Spinner from "components/Spinner";

interface MapSelectorProps {
  center: Coordinates;
  zoom: number;
  hubs: Required<Location>[];
  location?: Location;
  type: PickupType;
  allowTypeSelection?: boolean;
  searchBar?: boolean;
	clickable?: boolean;
	communityDelivery?: boolean;
	formLoading: boolean;
  onError: (value: string | undefined) => void;
  onChange: (location?: Omit<Location, 'id'>) => any;
  setType: Dispatch<SetStateAction<PickupType>>;
}

export const BagpointIcon = new Icon({
  iconUrl: (Bagpoint),
  iconSize: [30, 60],
  tooltipAnchor: [10, 0]
})

const MapSelector: React.FC<MapSelectorProps> = (
  {
    zoom = 11,
    hubs = [],
    location,
    center,
    type,
    allowTypeSelection = false,
    searchBar = false,
		clickable,
		communityDelivery,
		formLoading,
    setType,
    onChange,
    onError,
  }) => {
  /**
   * This component will display an interactive map which will generate location objects upon being interacted with.
   *
   * Upon loading, the map will center on the provided coordinates.
   *
   * The map object may be interacted with in the following manner:
   *
   *  If the type is set to PickupType.Address:
   *   - The customer may search for his address in the search field
   *   - The customer may select his location by clicking on the map
   *   - The customer may correct his location by dragging an already positioned marker onto a new location
   *
   *  If the type is set to PickupType.Hub:
   *   - The customer may select his location by clicking on a list of available markers
   *   - * NA: The customer may search for a location in the address search field (This will lock the address search field to the defined hub locations)
   *
   * Markers are expected to behave as follows:
   *
   *  If the type is set to PickupType.Address:
   *   - Markers will be placed with no additional information on top of the selected location
   *   - Markers may be dragged onto a new location
   *
   *  If the type is set to PickupType.Hub
   *   - Markers will be placed on every available hub location
   *   - If a marker is clicked, the selected location will be set to the marker in question.
   *   - An infobox will be displayed next to the selected marker.
   *
   * Whenever a location is selected, a callback to onChange is triggered thereby passing the location to the parent
   * container.
   *
   * It should be noted that it is not the role of the MapSelector to create the location object in the API.
   * This role is reserved to the parent.
   */

  const mapRef = useRef<Map>(null);

  const { translation } = useTranslations();
  const { direction } = useBookingMeta();

  const flight = useAppSelector((state) => state.flight as Required<FlightState>);

  const [focus, setFocus] = useState<Coordinates>(center);
  const [zones, setZones] = useState<Position[][]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const mergeZones = (zonePolygons: Feature<PolygonType | MultiPolygonType, Properties>[]) => {
    let mergedZones: Feature<PolygonType | MultiPolygonType, Properties>[] = [];
    const zoneIntersectionCount = Array(zonePolygons.length).fill(0);

    for (let i = 0; i < zonePolygons.length; i++) {
      for (let j = i + 1; j < zonePolygons.length; j++) {
        if (intersect(zonePolygons[i], zonePolygons[j])) {

          const mergedPolygon = union(zonePolygons[i], zonePolygons[j])

          if (mergedPolygon !== null)
            mergedZones.push(mergedPolygon)
          zoneIntersectionCount[i]++;
          zoneIntersectionCount[j]++;
        }
      }
    }

    return {
      totalIntersections: mergedZones.length,
      mergedZones: [...mergedZones, ...zonePolygons.filter((_zone, zoneIndex: number) => zoneIntersectionCount[zoneIndex] === 0)]
    }
  }

  useEffect(() => {
    BookingService.getZones().then(res => {
      const zones = res.map(item => item.areas.coordinates[0])
      const invertedZones = zones.map(item => item[0].map(item => item.reverse()));

      const zonePolygons = invertedZones.map(zone => polygon([zone])) as Feature<PolygonType | MultiPolygonType, Properties>[];
      let mergedZones: Feature<PolygonType | MultiPolygonType, Properties>[] = [];
      let currentMergedZones = { totalIntersections: -1, mergedZones: zonePolygons }

      while (currentMergedZones.totalIntersections !== 0) {
        currentMergedZones = mergeZones(currentMergedZones.mergedZones);
        mergedZones = currentMergedZones.mergedZones;
      }

      const zoneCoordinates = mergedZones.map(zone => zone.geometry.coordinates[0]) as Position[][];

      setZones(zoneCoordinates)
    })
  }, [])

  useEffect(() => {
    const map = mapRef.current;

    if (!location || !map) return;

		const zoom = map.getMaxZoom() - (communityDelivery ? 4 : 0);

    const latLng = new LatLng(location.coordinates.latitude, location.coordinates.longitude)
    map.flyTo(latLng, zoom)
  }, [location, communityDelivery])

  const formatAddress = async (lat: number, lng: number) => {
    const { data } = await Utils.geo.getOSMGeocodeFromCoordinates(lat, lng);
    const location = Utils.geo.toLocationOSM(data);

    if (!location.address.city) return null;

    const separate = data.address.house_number?.split(/(\d+)/g).filter((item: string) => item !== "");
    const number = separate?.shift() || "";
    const addition = separate?.join("").replace("-", "") || "";

    return {
      ...location,
      address: {
        ...location.address,
        addition,
        number
      }
    }
  }

  const HubSelector = () => {
    const hubEventHandler = {
      async click(e: LeafletMouseEvent) {
        const { lat, lng } = e.latlng;

        const selectedHub = hubs.find(hub => hub.coordinates.latitude === lat && hub.coordinates.longitude === lng);
        onChange(selectedHub);
      }
    }
    const hubMarkers = hubs.map(hub => (
      <Marker
        icon={BagpointIcon}
        key={hub.id}
        draggable={false}
        eventHandlers={hubEventHandler}
        position={{ lat: hub.coordinates.latitude, lng: hub.coordinates.longitude }}
      >
        <Tooltip>
          <InfoWindowContent
            type={PickupType.Hub}
            description={translation.get('map:info_window:explanation')}
            location={hub}
          />
        </Tooltip>
      </Marker>
    ))

    return <>{type === PickupType.Hub ? hubMarkers : null}</>
  }

  const AddressSelector = () => {
    const markerRef = useRef<LeafletMarker>(null);

    const map = useMapEvents({
      async click(e) {
				if(!clickable) return;

        if (type !== PickupType.Address) return;

				setLoading(true)

				try {
					const { lat, lng } = e.latlng;
					const clickPoint = point([lat, lng]);
	
					const containsClick = zones.some((zone: Position[]) => booleanPointInPolygon(clickPoint, polygon([zone])))
					if (!containsClick) return;
	
					const formattedAddress = await formatAddress(lat, lng);
	
					if (formattedAddress === null) return;
	
					onChange(formattedAddress);
	
					map.flyTo(e.latlng, map.getMaxZoom());
				} catch (error) {
					console.error(error)
				} finally {
					setLoading(false);
				}

      },
			async drag(e) {
			}
    })

    const markerEventHandler = {
      async dragend() {
        if (type !== PickupType.Address) return;

        const marker = markerRef.current;
        if (marker === null) return;

        const { lat, lng } = marker.getLatLng();

        const formattedAddress = await formatAddress(lat, lng);

        if (formattedAddress === null) return;

        onChange(formattedAddress);

        map.flyTo(marker.getLatLng(), map.getMaxZoom());

      }
    }

    const positions = [[[90, -180], [90, 180], [-90, 180], [-90, -180]], ...zones] as LatLngExpression[] | LatLngExpression[][] | LatLngExpression[][][]

    return (
      <>
      <Polygon
        bubblingMouseEvents={false}
        fillColor="red"
        opacity={0}
				className={styles.disabled}
        positions={positions} 
      />
      {location && type === PickupType.Address && !communityDelivery ? 
        <Marker
          icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
          eventHandlers={markerEventHandler}
          ref={markerRef} 
          draggable 
          position={{lat: location.coordinates.latitude, lng: location.coordinates.longitude}} 
        /> : null}
      </>
    );
  }

  const onTypeChange = useCallback((type: PickupType) => {
    /**
     * When the switch is changed, we change the type value and reset the selected and focused
     * states.
     */
    setFocus(center)
    onChange(undefined);
    setType(type);

    mapRef.current?.setZoom(zoom);
    const latLng = new LatLng(center.latitude, center.longitude)
    mapRef.current?.flyTo(latLng)

  }, [zoom, center, onChange, setType])

  return (
    <div className={styles.wrapper}>
      {allowTypeSelection && <section className={styles.controls}>
        <Switch
          id="location-switch"
          leftLabel={translation.get("location:switch_address")}
          rightLabel={translation.get("location:switch_bagpoint")}
          initial={type === PickupType.Address ? "left" : "right"}
          onChange={(value) => onTypeChange(value === "left" ? PickupType.Address : PickupType.Hub)}
        />
      </section>}
      <section className={styles.container}>
				{(formLoading || loading) && 
					<>
						<div className={styles.loadingOverlay} />
						<Spinner />
					</>
				}
        {
          focus && searchBar && <AddressField
            country={flight.journey[direction].schedule.airport.country}
            coordinates={focus}
            type={type}
            map={undefined}
            onError={(error) => onError(error)}
            onUpdate={(location) => onChange(location)}
          />
        }
        <div className={`${styles.map} ${!clickable && styles.disabled }`}>
          <MapContainer
						dragging={clickable}
						doubleClickZoom={clickable}
            data-cy="map"
            className={styles.mapContainer}
            worldCopyJump
            maxBounds={[[-90, -180], [90, 180]]}
            center={{ lat: center.latitude, lng: center.longitude }}
            zoom={zoom}
            scrollWheelZoom={false}
            ref={mapRef}
          >
            <TileLayer
              noWrap
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <HubSelector />
            <AddressSelector />
          </MapContainer>
        </div>
      </section>
    </div>
  )
}

export default MapSelector;
