import React from "react";
import IconSection from "../IconSection/IconSection";
import {useAppSelector} from "../../../store";

const Regulations = () => {

  const regulations = useAppSelector(state => state.channel.properties.regulations);

  return (
    <div>
      <IconSection section={regulations.prohibited}/>
      <IconSection section={regulations.cabin}/>
      <IconSection section={regulations.checkedIn}/>
      <IconSection section={regulations.onPerson}/>
    </div>
  );
}

export default Regulations;