import React, {ChangeEvent, useCallback, useEffect, useState} from "react"
import useTranslations from "../../../../../hooks/useTranslations";
import sharedStyles from "../../../../../styles/Shared.module.scss";
import styles from "./ContactForm.module.scss";
import ValidationError from "../../../../../components/ValidationError";
import {Flow} from "../../../../../store/channel/channel.types";
import Authentication from "../../../../../utils/authentication";
import TextInput from "../../../../../components/inputs/TextInput";
import ConcretePhoneInput from "../../../../../components/inputs/PhoneInput";
import SuperscriptedText from "../../../../../components/SuperscriptedText/SuperscriptedText";
import Info from "../../../../../resources/svg/Info.svg";
import {useBookingMeta, useValidation} from "../../../../../hooks";
import {Value as InputValue} from 'react-phone-number-input/input'
import {useAppSelector} from "../../../../../store";
import Utils from "utils/Utils";
import Button from "components/inputs/buttons/Button";

export interface Fields {
  email: string;
  confirmEmail: string;
  firstName: string;
  lastName: string;
  passportNumber?: string;
  phoneNumber: string;
  password?: string;
  confirmPassword?: string;
  general?: string;
}

interface IContactFormProps {
  formData: Fields;
  onChange: (formData: Fields) => void;
  handleLogin: () => Promise<void>;
  handleRegistration: () => Promise<void>;
  setValidForm: (value: boolean) => void;
  register: boolean;
  login: boolean;
}

type GenericValidationFields = Capitalize<keyof Omit<Fields, 'confirmEmail' | 'password' | 'confirmPassword' | 'phoneNumber' | 'general'>>

const ContactForm = ({formData, onChange, handleLogin, handleRegistration, setValidForm, register, login}: IContactFormProps) => {
  const {translation} = useTranslations();
  const validation = useValidation();

  const {flow} = useBookingMeta();
  const isLoggedIn = Authentication.isLoggedIn();
  const properties = useAppSelector(state => state.channel.properties);
  const {switches} = properties;
  const booking = useAppSelector(state => state.booking);
  const defaultCountry = useAppSelector(state => state.channel.country);

  const errorsInitialState = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    passportNumber: register ? "" : undefined,
    password: register ? "" : undefined,
    confirmPassword: register ? "" : undefined,
  }

  const [errors, setErrors] = useState<Partial<Fields>>(errorsInitialState);
  const [disabled, setDisabled] = useState<boolean>(false)
  const {email, confirmEmail, firstName, lastName, passportNumber, phoneNumber, password, confirmPassword} = formData;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const validator: GenericValidationFields = `${target.name[0].toUpperCase()}${target.name.substring(1)}` as GenericValidationFields

    setErrors((old) => {
      const newErrors = {
        ...old,
        [target.name]: validation[`validate${validator}`](target.value)
      }

      onChange({...formData, [target.name]: target.value,})

      return newErrors;
    })

  }, [validation, formData, onChange]);

  const handleBlur = (e: ChangeEvent, validationFn: Function) => {
    const target = e.target as HTMLInputElement;
    setErrors({
      ...errors,
      [target.name]: validationFn(target.value)
    })
  };

  const handlePasswordValidation = (e: ChangeEvent, password: string) => {
    const target = e.target as HTMLInputElement;

    setErrors({
      ...errors,
      password: validation.validatePassword(target.value, password) || '',
      confirmPassword: validation.validatePassword(password, target.value) || '',
    });
  }

  const handleEmailConfirmation = (e: ChangeEvent, email: string) => {
    const target = e.target as HTMLInputElement;

    setErrors({
      ...errors,
      confirmEmail: validation.validateConfirmEmail(target.value, email) || ''
    })
  }

  const handlePhoneChange = (phone: InputValue) => {
    setErrors((errors) => {
      const newErrors = {
        ...errors,
        phoneNumber: validation.validatePhoneNumber(`${phone}`, switches.phoneNumberRequired) || undefined,
      }

      onChange({...formData, phoneNumber: phone})

      return newErrors;
    })
  };

  useEffect(() => {
    if (booking.status) {
      setDisabled(true)
    }
  }, [booking.status])

  useEffect(() => {
    if(flow === Flow.City) {
      setValidForm(!Utils.object.checkForFalsyProperties({
        email,
        confirmEmail,
        firstName,
        lastName,
        phoneNumber
      }))
      return;
    }

    const validForm = !Utils.object.checkForFalsyProperties({email, confirmEmail, firstName, lastName, phoneNumber, passportNumber}) && Utils.object.checkForFalsyProperties(errors, true)
    setValidForm(validForm);
  }, [confirmEmail, email, errors, firstName, flow, formData, lastName, passportNumber, phoneNumber, setValidForm])

  return (
    <>
      {(!login || isLoggedIn) && <>
        <TextInput
          data-cy="contact-email"
          label={`${translation.get("payment:label_email")}*`}
          name="email"
          value={email}
          containerClassName={disabled ? styles.disabled : ''}
          inputClassName={styles.ltr}
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={(e) => handleBlur(e, validation.validateEmail)}
          error={(errors.email) || undefined}
        />

        <TextInput
          data-cy="confirm-contact-email"
          label={`${translation.get("payment:label_confirm_email")}*`}
          name="confirmEmail"
          disablePaste
          value={confirmEmail}
          containerClassName={disabled ? styles.disabled : ''}
          inputClassName={styles.ltr}
          onChange={(e) => {
            handleChange(e);
            email && handleEmailConfirmation(e, email);
          }}
          error={(errors.confirmEmail) || undefined}
        />

        <ConcretePhoneInput
          data-cy="contact-phone-number"
          defaultCountry={defaultCountry.toUpperCase()}
          label={`${translation.get("payment:label_phone")}${switches.phoneNumberRequired ? '*' : ''}`}
          name="phoneNumber"
          value={phoneNumber}
          containerClassName={disabled ? styles.disabled : ''}
          onChange={handlePhoneChange}
          error={(errors.phoneNumber) || undefined}
        />

        <div className={styles.nameInputContainer}>
          <div>
            <TextInput
              data-cy="contact-first-name"
              label={`${translation.get("payment:label_first_name")}*`}
              name="firstName"
              value={firstName}
              disabled={flow === Flow.Airport}
              containerClassName={disabled ? styles.disabled : ""}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => handleBlur(e, validation.validateFirstName)}
              error={(errors.firstName) || undefined}
            />
          </div>

          <div>
            <TextInput
              data-cy="contact-last-name"
              label={`${translation.get("payment:label_last_name")}*`}
              name="lastName"
              value={lastName}
              disabled={flow === Flow.Airport}
              containerClassName={disabled ? styles.disabled : ""}
              onChange={(e) => {
                handleChange(e);
                handleBlur(e, validation.validateLastName)
              }}
              error={(errors.lastName) || undefined}
            />
          </div>
        </div>

        {flow === Flow.Airport && <TextInput
          data-cy="contact-passport-number"
          label={
            <SuperscriptedText
              text={`${translation.get("payment:label_passport")}* `}
              superscriptIcon={Info}
              info={<div style={{width: '150px'}}>{translation.get('payment:label_passport:info')}</div>}
            />}
          name="passportNumber"
          value={passportNumber?.toUpperCase() || ""}
          containerClassName={disabled ? styles.disabled : ""}
          inputClassName={styles.ltr}
          onChange={(e) => {
            handleChange(e);
            handleBlur(e, validation.validatePassportNumber)
          }}
          disabled={true}
          max={12}
          error={(errors.passportNumber) || undefined}
        />}

        {(switches.user.display && !isLoggedIn) && (
          <>


            {register && (
              <div className={styles.loginContainer}>
                <TextInput
                  label={translation.get("payment:label_password")}
                  type="password"
                  name="password"
                  containerClassName={disabled ? styles.disabled : ""}
                  value={password}
                  onChange={(e) => {
                    handleChange(e);
                    confirmPassword && handlePasswordValidation(e, confirmPassword);
                  }}
                  error={(errors.password) || undefined}
                />
                <TextInput
                  label={translation.get("payment:label_repeat_password")}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  containerClassName={disabled ? styles.disabled : ""}
                  onChange={(e) => {
                    handleChange(e);
                    password && handlePasswordValidation(e, password);
                  }}
                  error={(errors.confirmPassword) || undefined}
                />

                <Button
                  type="submit"
                  id="register-payment"
                  disabled={register && (!formData.password || !formData.confirmPassword)}
                  style={{marginTop: "15px"}}
                  onClick={handleRegistration}
                  text={translation.get("payment:switch_registration")}
                />
              </div>
            )}
          </>
        )}
      </>}

      {login && !isLoggedIn && (
        <div className={styles.loginContainer}>
          <TextInput
            label={translation.get("payment:label_email")}
            type="email"
            name="email"
            value={email}
            onChange={(e) => {
              handleChange(e);
              handleBlur(e, validation.validateEmail)
            }}
            error={(!login && errors?.email) || undefined}
          />

          <TextInput
            label={translation.get("payment:label_password")}
            type="password"
            name="password"
            value={password}
            onChange={(e) => {
              handleChange(e);
              handleBlur(e, validation.validatePassword)
            }}
            component={<a className={sharedStyles.link}
                          href={'/password/forgot'}>{translation.get("login:forgot_password")}</a>}
            error={errors.password && errors.password}
          />

          <Button
            type="submit"
            id="login-payment"
            disabled={!(!errors.email && !errors.password)}
            style={{marginTop: "15px"}}
            onClick={handleLogin}
            text={translation.get("payment:switch_login")}
          />
          {false && (
            <ValidationError error={''}/>
          )}
        </div>
      )}
    </>
  )
}

export default ContactForm;
