import React from "react";

import {useEffect, useState} from "react";
import Language, {Translation} from "./useProperties/language";

interface Properties {
  children: React.ReactNode;
}

export interface PropertiesContext {
  translation: Translation;
}

const Context = React.createContext({} as PropertiesContext);

export const TranslationProvider = ({children}: Properties) => {
  /**
   * Translation provider which reacts to language changes
   */

  const [translation, setTranslation] = useState<Translation>(Language.getTranslation());

  useEffect(() => {
    function handleLanguageChange(translations: Translation) {
      setTranslation(translations);
    }

    Language.subscribe(handleLanguageChange);
    return () => {
      Language.unsubscribe(handleLanguageChange);
    };
  }, []);

  return (<Context.Provider value={{translation,}}>{children}</Context.Provider>);
}

export default function useTranslations<T extends PropertiesContext>() {
  return React.useContext(Context) as T;
}
