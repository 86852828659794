import React, {FunctionComponent, useState} from 'react';
import useTranslations from "../../../hooks/useTranslations";
import styles from './CookieConsent.module.scss';
import {ChannelProperties, WhiteLabel} from "../../../store/channel/channel.types";
import Button from "../../inputs/buttons/Button/Button";
import {useCookies} from "../../../hooks";

export interface CookieProps {
  whitelabel: WhiteLabel;
  properties: ChannelProperties;
}

const CookieConsent: FunctionComponent<CookieProps> = ({whitelabel}) => {

  const {translation} = useTranslations();
  const {cookies, setCookies, acceptCookies} = useCookies();
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const savePreference = (statisticCookies = false) => {
    acceptCookies({
        necessary: true,
        statistics: statisticCookies || cookies[1].value
      });
  }

  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span>
          <p className={styles.title}>{translation.get('cookies:title')}</p>
        </span>
        <div>
          {!showSettings &&
            <Button
              className={styles.button}
              text={translation.get('cookies:button:openSettings')}
              onClick={() => setShowSettings(() => !showSettings)}
            />
          }
          {showSettings && <>
            <Button 
              className={styles.button}
              text={translation.get("cookies:button:rejectAll")}
              onClick={() => savePreference(false)}
            />
          </>}
          <Button 
            type="submit"
            data-cy="accept-cookies" 
            className={styles.button}
            text={translation.get('cookies:button:acceptAll')}
            onClick={() => savePreference(true)}
          />
        </div>
      </div>
      {showSettings && <div className={styles.cookieSettings}>
        {cookies.map((cookie) => (
          <div className={styles.cookieSettings} key={cookie.title}>
            <div className={styles.reactSwitchTitle}>
              <input
                checked={cookie.value}
                onChange={(e) => {
                  setCookies((prev) => (
                    prev.map(item => (item.title === cookie.title ? Object.assign(item, {
                      ...item,
                      value: e.target.checked
                    }) : item))
                  ))
                }}
                className={styles.reactSwitchCheckbox}
                id={cookie.title}
                type="checkbox"
                disabled={cookie.disabled}
              />
              <label
                style={{
                  opacity: cookie.disabled ? 0.3 : 1,
                  cursor: cookie.disabled ? 'not-allowed' : 'pointer'
                }}
                className={`${styles.reactSwitchLabel} ${cookie.value ? styles.enabled : styles.disabled}`}
                htmlFor={cookie.title}
              >
                <span className={styles.reactSwitchButton}/>
              </label>
              <span className={styles.cookieTitle}>{cookie.title}</span>
            </div>
            <div className={styles.cookieText}>
              {cookie.content}
            </div>
          </div>
        ))}
        <div className={styles.footer}>
          <Button type="submit" text={translation.get("cookies:button:saveSetting")} onClick={() => savePreference()}/>
        </div>
      </div>}
    </div>
  )
};

export default CookieConsent;