export enum APIStatus {
  PROCESSING = 'STATUS.PROGRESS.000',
  CREATED = 'STATUS.PROGRESS.001',
  READY = 'STATUS.PROGRESS.002',
  AWAITING_PICKUP = 'STATUS.PROGRESS.003',
  IN_PROGRESS = 'STATUS.PROGRESS.005',
  DROPPED_OFF = 'STATUS.PROGRESS.006',
  COMPLETED = 'STATUS.PROGRESS.007',
  PICKED_UP = 'STATUS.PROGRESS.008',
  AWAITING_PAYMENT = 'STATUS.PROGRESS.009',
  PAID = 'STATUS.PROGRESS.010',

  LABEL_REQUESTED = 'STATUS.EVENT.001',
  LABEL_ISSUED = 'STATUS.EVENT.002',
  CHECKED_IN = 'STATUS.EVENT.003',
  UPDATED = 'STATUS.EVENT.004',
  ASSIGNED = 'STATUS.EVENT.005',
  SUBMITTED = 'STATUS.EVENT.006',
  VALIDATED = 'STATUS.EVENT.007',
  VERIFIED = 'STATUS.EVENT.008',
  BANNED = 'STATUS.EVENT.009',

  CANCELLED = 'STATUS.ERROR.001',
  DELAYED = 'STATUS.ERROR.002',
  LOST = 'STATUS.ERROR.003',
  LABEL_ERROR = 'STATUS.ERROR.004',
  INVALID = 'STATUS.ERROR.005',
  FAILED = 'STATUS.ERROR.006',

  REGISTERED = 'STATUS.REGISTERED.001'
}