import {Errors} from "../../../components/containers/Step/types";

export default class StepError extends Error {
  public fields: Errors;

  constructor(fields: Errors = {}) {
    super();
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error()).stack;
    }
    this.fields = fields;
  }
}