import {useLayoutEffect, useMemo, useState} from "react";
import {DateTime} from "luxon";
import {useCookies} from "../../../hooks";
import useTranslations from "../../../hooks/useTranslations";
import {useAppDispatch, useAppSelector} from "../../../store";
import {updateAnnouncement} from "../../../store/app/appReducer";
import {
  DateTimeObj,
  IAnnouncement,
  IChannelState,
} from "../../../store/channel/channel.types";
import ENV from "../../../utils/environments";
import GoogleTagManager from "../../../utils/GoogleTagManager";
import CookieConsent from "../CookieConsent";
import Modal from "../Modal";
import Utils from "utils/Utils";

interface Props {
  channel: IChannelState;
}

const ModalHandler = ({channel}: Props) => {
  const announcements: IAnnouncement[] = useAppSelector((state) => state.app.announcements);

  const dispatch = useAppDispatch();
  const {translation} = useTranslations();
  const {consent, checkCookies} = useCookies();

  const [displayedAnnouncement, setDisplayedAnnouncement] = useState<{ item: IAnnouncement, index: number }>();
  const [isCookieSet, setIsCookieSet] = useState<boolean>(checkCookies());
  const {whitelabel} = useMemo(() => channel.properties, [channel.properties]);
  const [hasAnalytics, setHasAnalytics] = useState<boolean>(false);

  const closeAnnouncement = (announcement: IAnnouncement, index: number) => {
    dispatch(updateAnnouncement({announcements, title: announcement.title}));
    setDisplayedAnnouncement(() => ({item: announcements[index], index}));
  }

  const isAnnouncementValid = (announcement: IAnnouncement) => {
    const {validity} = announcement;

    if (!validity || !validity.from || !validity.to) {
      console.error("Invalid announcement validity structure:", announcement);
      return false;
    }

    const convertToDateTime = (dateObj: DateTimeObj) => {
      if (dateObj.year == null || dateObj.month == null || dateObj.day == null ||
        dateObj.hour == null || dateObj.minute == null || !dateObj.timeZoneName) {
        console.error("Invalid date object structure:", dateObj);
        return null;
      }

      return DateTime.fromObject({
        year: dateObj.year,
        month: dateObj.month,
        day: dateObj.day,
        hour: dateObj.hour,
        minute: dateObj.minute,
      }, {zone: dateObj.timeZoneName});
    };

    const from = convertToDateTime(validity.from);
    const to = convertToDateTime(validity.to);

    if (!from || !to) {
      return false;
    }

    const now = DateTime.now();
    return now >= from && now <= to;
  };


  useLayoutEffect(() => {
    setIsCookieSet(checkCookies())
  }, [consent, checkCookies])

  useLayoutEffect(() => {
    /**
     * Initialize Google Analytics with the correct dimensions.
     */
    if (!consent.statistics || hasAnalytics) return;

    GoogleTagManager.initialize(ENV.GOOGLE_TAG_MANAGER);
    setHasAnalytics(true); // Avoid Initializing Google Analytics multiple times.
  }, [consent, hasAnalytics])

  useLayoutEffect(() => {
    /**
     * Define which announcements need to be shown to the customer.
     */
    if (!announcements || !announcements.length) return;

    const validAnnouncement = announcements.find(announcement => isAnnouncementValid(announcement));

    if (validAnnouncement) {
      setDisplayedAnnouncement({item: validAnnouncement, index: announcements.indexOf(validAnnouncement)});
    }
  }, [announcements]);

  return (
    <>
      <Modal
        isShown={!isCookieSet}
        headerText={translation.get("cookies:modal:title")}
        modalContent={<CookieConsent properties={channel.properties} whitelabel={whitelabel}/>}
      />
      {consent.necessary && displayedAnnouncement && displayedAnnouncement.item &&
        <Modal
          key={displayedAnnouncement.index}
          isShown={isCookieSet && !!displayedAnnouncement}
          hide={displayedAnnouncement.item.closable ? () => closeAnnouncement(displayedAnnouncement.item, displayedAnnouncement.index + 1) : undefined}
          modalContent={
            <div className="announcement">
              {Utils.string.parseText(displayedAnnouncement.item.message, translation)}
            </div>
          }
          headerText={Utils.string.parseText(displayedAnnouncement.item.title, translation)}
        />
      }
    </>
  )
}

export default ModalHandler;