import { useEffect, useMemo, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import styles from "./Menu.module.scss";
import Hamburger from "../Hamburger";
import { Link, useNavigate } from "react-router-dom";
import Svg from "../../../components/images/Svg";
import BaseDropdown, { DropdownItem } from '../../../components/inputs/dropdowns/BaseDropdown';
import { MenuItem, Switches } from "../../../store/channel/channel.types";
import User from '../../../resources/svg/User.svg';
import Icon from '../../../components/icons/Icon/Icon';
import LanguageDropdown from "../../../components/inputs/dropdowns/LanguageDropdown";
import { useAppSelector } from "../../../store";
import Authentication from "utils/authentication";

interface MenuProps {
  items: MenuItem[]
  switches: Switches;
  handleLogout: (item: DropdownItem<string>) => any;
  pathName: string;
  images: { [key: string]: string };
  className?: string;
}

const Menu = (
  {
    items,
    switches,
    handleLogout,
    images,
    className,
  }: MenuProps) => {

  const navigate = useNavigate();
  const { translation } = useTranslations();
  const user = useAppSelector(state => state.auth.user);
	const logoAddition = useAppSelector((state) => state.channel.properties.content.logoAddition);

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [dir, setDir] = useState(document.body.getAttribute("dir"));
	const [logo, setLogo] = useState<string>(dir === "ltr" ? images.desktopLogo : images.invertedLogo)
  const width = window.innerWidth;
  const MOBILE_BREAKPOINT = 768;

	const isLoggedIn = Authentication.isLoggedIn();

  useEffect(() => {
    const updateDir = () => {
			const dir = document.body.getAttribute("dir");
			setLogo(dir === "ltr" ? images.desktopLogo : images.invertedLogo)
      setDir(document.body.getAttribute("dir"));
    };

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === "attributes" && mutation.attributeName === "dir") {
          updateDir();
        }
      });
    });

    const config = { attributes: true };

    observer.observe(document.body, config);

    updateDir();

    return () => observer.disconnect();
  }, [images.desktopLogo, images.invertedLogo]);

  useEffect(() => {
    setIsMobile(width <= MOBILE_BREAKPOINT)
  }, [width])

  const menuOptions: DropdownItem<string>[] = useMemo(() => {
    if(!items) return []

    return items.map(({value, label, isLink}) => ({
      value,
      label: translation.get(label),
      isLink
    }))
  }, [items, translation])

  const accountOptions: DropdownItem<string>[] = [
    {
      value: "/portal",
      label: translation.get("account_selector:account"),
      onClick: (item) => navigate(item.value)
    },
    { value: "/", label: translation.get("account_selector:logout"), onClick: handleLogout },
  ]

  return (
    <nav className={`${styles.navigation} ${className ? className : ""}`}>
      <div className={styles.menu}>
				<div className={styles.logoContainer}>
					<Link to="/" className={styles.logo}>
						<Svg url={logo} className={styles.desktop} />
						<Svg url={logo} className={styles.tablet} />
						<Svg url={images.mobileLogo} className={styles.mobile} />
					</Link>

					{logoAddition ? 
					<Svg 
						url={logoAddition}
						className=""
					/> : null}
				</div>



        <div className={styles.links}>
          {menuOptions.map(({ value, label }) => <a rel="noreferrer" target="_blank" href={value} key={value}>{label}</a>)}
        </div>
        <div className={styles.dropdownContainer}>
          <LanguageDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdown}
          />
          {<>
            {isLoggedIn ?
              <BaseDropdown
                className={styles.dropdown}
                value={user?.first_name || ''}
                items={accountOptions}
                icon={() => <Icon src={User} className={`${styles.icon}`} />}
              />
              :
              <div>
                {switches.user.display && 
								<Link to="/login" className={styles.login}>
                  {isMobile ?
                    <Icon
                      src={User}
                      className={`${styles.icon}`} />
                    : translation.get('payment:switch_login')
                  }
                </Link>}
              </div>
            }

            {menuOptions.length ? <BaseDropdown
              className={`${styles.dropdown} ${styles.burgerMenu}`}
              label={false}
              onClick={(value) => setMenuIsOpen(value)}
              items={menuOptions}
              icon={() => <Hamburger isOpen={menuIsOpen} />}
            /> : null}
          </>}
        </div>
      </div>
    </nav>
  )
}

export default Menu;
