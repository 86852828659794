import { useState } from 'react';
import styles from './InfoCard.module.scss';
import {InfoCircle} from 'components/icons/InfoCircle';
import {WarningCircle} from 'components/icons/WarningCircle';

interface Props {
  type: "info" | "error";
  title?: string;
  subtitle?: string;
  className?: string;
}

const InfoCard = ({ type, title, subtitle, className }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <section 
      className={`${styles.info} ${type === "error" && styles.error} ${className} ${isExpanded ? styles.expanded : styles.collapsed}`}
      onClick={toggleExpand}
    >
      <header>
				<div className={styles.icon}>
        	{type === "info" ? <InfoCircle /> : <WarningCircle />}
				</div>
        {isExpanded && (
          <div>
            <h2>{title}</h2>
            <div className={styles.subtitle}>{subtitle}</div>
          </div>
        )}
      </header>
    </section>
  );
};

export default InfoCard;