import React, {useState, useEffect, useCallback} from 'react'
import useOnclickOutside from "react-cool-onclickoutside";
import styles from './BaseDropdown.module.scss';

// https://fettblog.eu/typescript-react-generic-forward-refs/
declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

interface Stringable {
  toString: () => string;
}

export interface DropdownItem<A> {
  value: A;
  label: string;
  onClick?: (item: DropdownItem<A>) => any;
  isLink?: boolean;
}

interface Props<A> {
  icon?: (open: boolean) => JSX.Element;
  style?: {
    container?: React.CSSProperties;
    active?: { [key: string]: string };
    label?: { [key: string]: string };
    list?: { [key: string]: string };
    menu?: { [key: string]: string };
    option?: { [key: string]: string };
    icon?: { [key: string]: string };
    wrapper?: { [key: string]: string };
  };
  Component?: React.FC<{
    item: DropdownItem<A>,
    index: number,
    open: Function,
    onClick: (item: DropdownItem<A>) => void;
  }>;
  listClass?: string;
  className?: string;
  items: DropdownItem<A>[];
  label?: boolean;
  onClick?: (open: boolean) => void;
  onSelect?: (item: DropdownItem<A>) => void;
  value?: string
  "data-cy"?: string
}

const BaseDropdown = <A extends Stringable>(
  {
    className,
    listClass,
    style,
    items,
    icon,
    Component,
    onClick,
    onSelect,
    value,
    'data-cy': dataCy
  }: Props<A>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  /**
   * A generic, reusable dropdown with customizable visuals and list components.
   */
  const [open, setOpen] = useState(false);
  const outsideRef = useOnclickOutside(() => setOpen(false));

  const onOpen = useCallback(() => {
    setOpen(!open);
  }, [open])

  useEffect(() => {
    if (onClick) onClick(open);
  }, [open, onClick])

  const handleSelect = useCallback((item: DropdownItem<A>) => {
    if (item.onClick) item.onClick(item);
    if (onSelect) onSelect(item);
    setOpen(false);
  }, [onSelect])

  return (
    <div ref={outsideRef} onClick={() => {
      setOpen(!open)
      onClick && onClick(open)
    }} className={className} style={style?.wrapper}>
      <div
        data-cy={dataCy}
        style={open ? style?.active : style?.container}
        ref={ref}
        onClick={onOpen}
        className={"container"}
      >
        {icon && <div className={'icon'} style={style?.icon}>
          {icon(open)}
        </div>}
        {value && <div className={"label"} style={style?.label}>{value}</div>}
      </div>

      <div className={`list ${open && 'listActive'} ${listClass}`} style={style?.list}>
        {Component
          ? items.map((item: DropdownItem<A>, index: number) => {
            return <Component item={item} index={index} key={index} open={setOpen} onClick={() => handleSelect(item)}/>
          })
          : items.map((item: DropdownItem<A>, index: number) => {
            return item.isLink ?
              <a
                key={index}
                href={item.value.toString()}
                className={`option ${styles.item}`}
              >
                {item.label}
              </a>
              :
              <div
                onClick={() => handleSelect(item)}
                key={index}
                className={`option ${styles.item}`}
              >
                {item.label}
              </div>
          })
        }
      </div>
    </div>
  )
}

export default React.forwardRef(BaseDropdown)
