import React, {useEffect, useState} from "react";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  url: string;
  className: string;
  errorClassName?: string;
  loadingClassName?: string;
}

const Svg: React.FC<IconProps> = ({
  url,
  style,
  className,
  errorClassName,
  loadingClassName,
  ...rest
}): JSX.Element | null => {
  /**
   * Dynamically retrieve a svg file from a given URL and return it as a style-able component.
   *
   * As it stands only the fill property of the svg can be defined using the color property.
   * With that said, the parent div element will inherit any provided style or classname.
   */

  const [svg, setSvg] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)
    fetch(url)
      .then(res => res.text())
      .then(text => {
        setSvg(text)
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, [url])

  if (loading) {
    return <div className={loadingClassName || "spinner"}/>;
  } else if (!svg) {
    return <div className={errorClassName || "error"}/>
  }

  return <div style={style} className={className} dangerouslySetInnerHTML={
    {__html: svg}
  }/>;

};

export default Svg
