import React, {useEffect} from 'react'
import styles from './CustomerPortal.module.scss'
import BookingList from './BookingList/BookingList'
import LocationList from './LocationList/LocationList'
import Account from './Account/Account'
import {getBookings, getLocations} from '../../store/portal/portalReducer'
import {useAppDispatch, useAppSelector} from "../../store";

const CustomerPortal: React.FC = () => {
  /**
   * The user portal is the area of the webfunnel where customers may login
   * and view their locations, contact information and past bookings along with their status.
   */
  const dispatch = useAppDispatch();

  const bookings = useAppSelector(state => state.portal.bookings?.bookings || []);
  const locations = useAppSelector(state => state.portal.locations.private);
  const locationOffset = useAppSelector(state => state.portal.locations.offset);
  const locationCount = useAppSelector(state => state.portal.locations.count);
  const bookingOffset = useAppSelector(state => state.portal.bookings.offset);
  const bookingCount = useAppSelector(state => state.portal.bookings.count);
  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getBookings({offset: undefined, status: undefined}));
    dispatch(getLocations({offset: undefined, type: undefined}));
  }, [dispatch])

  const loadMore = (type: string): void => {
    if (type === 'booking') dispatch(getBookings(bookingOffset));
    if (type === 'location') dispatch(getLocations(locationOffset));
  }

  return (
    <div className={styles.portal}>
      <BookingList bookings={bookings} offset={bookingOffset} count={bookingCount} onLoadMore={() => loadMore('booking')}/>
      <div className={styles.container}>
        <Account user={user}/>
        <LocationList locations={locations} offset={locationOffset} count={locationCount} onLoadMore={() => loadMore('location')}/>
      </div>
    </div>
  )
}

export default CustomerPortal
