import React from 'react'
import useTranslations from "../../../hooks/useTranslations";
import styles from './LocationList.module.scss'
import {Location} from "../../../store/booking/bookingTypes";
import Button, {ColourTypes} from "../../../components/inputs/buttons/Button/Button";

interface ILocationListProps {
  locations: Array<Location>;
  offset: number | undefined;
  count: number | undefined;
  onLoadMore: () => void;
}

const LocationList = ({locations, offset, count, onLoadMore}: ILocationListProps) => {

  const {translation} = useTranslations();

  const locationTableHeaders = [
    `${translation.get('portal:location:street')}`,
    `${translation.get('portal:location:number')}`,
    `${translation.get('portal:location:zip')}`,
    `${translation.get('portal:location:city')}`
  ];

  return (
    <div className={styles.list}>
      <div className={styles.heading}>{translation.get('portal:location:title')}</div>
      <div>
        <div className={styles.container}>
          <div className={styles.row}>
            {locationTableHeaders.map((header: string, index: number) => <span
              key={index}
              className={styles.columnHeading}>{header}</span>)}
          </div>
          {locations.map((location: Location, index: number) =>
            <div key={index} className={styles.row}>
              <div className={styles.column}>
                {location.address?.street || ""}
              </div>
              <div className={styles.column}>
                {location.address?.number || ""} {location.address?.addition || ""}
              </div>
              <div className={styles.column}>
                {location.address?.postal_code || ""}
              </div>
              <div className={styles.column}>
                {location.address?.city || ""}
              </div>
            </div>
          )}
        </div>
        {offset && count !== locations.length && <div className={styles.footer}>
          <Button text={translation.get('portal:location:loadMore')} onClick={onLoadMore}
                  color={ColourTypes.secondary}/>
        </div>}
      </div>
    </div>
  )
}

export default LocationList
