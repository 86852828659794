import * as React from "react";
import styles from "./Label.module.scss";

interface ILabelProps {
  text?: string | React.ReactElement;
  className?: string,
  style?: React.CSSProperties
}

const Label: React.FC<ILabelProps> = (
  {
    text,
    style,
    className = styles["label"],
  }) => {

  return (
    <span style={style} className={`${styles.label} ${className === undefined ? '' : className}`}>
      {text}
    </span>
  );
};

export default Label;
