import Plus from "../../../resources/svg/Plus_circle.svg";
import Icon from "../Icon";
import React, {useMemo} from "react";
import style from "./PlusIcon.module.scss";
import {IconProps} from "../Icon/Icon";

interface Props extends Omit<IconProps, 'src'> {
  className?: string;
}

const PlusIcon: React.FC<Props> = ({className, ...props}) => {
  const classNames = useMemo(()=>{
    return `${className ? className : ''} ${style.plusIcon}`
  }, [className])

  return <Icon {...props} src={Plus} className={classNames}/>
}

export default PlusIcon;