import {createAction, createAsyncThunk, createReducer, Reducer} from "@reduxjs/toolkit";
import {DateTime} from "luxon";
import properties from './defaultProperties.json';
import {GET_CHANNEL, CHANNEL_FLUSH} from "./channelConstants";
import Utils, {setFlowOptions} from '../../utils/Utils'
import {IChannelState, ChannelProperties} from "./channel.types";
import {Currency} from "../booking/bookingTypes";
import ChannelService from "./channelService";

const initialState: IChannelState = {
  id: -1,
  name: "",
  host: "",
  type: "",
  merchant: "",
  country: "",
  currency: {} as Currency,
  company: {},
  properties: properties as ChannelProperties,
  timestamp: undefined,
	error: null,
};

export const getChannel = createAsyncThunk(GET_CHANNEL, async (_, { rejectWithValue }) => {
  const response = await ChannelService.getChannel();
  
  if (response.success && response.data) return response.data;
	else return rejectWithValue(response.error);
});

export const flushChannel = createAction(CHANNEL_FLUSH)

const channel: Reducer<IChannelState> = createReducer(initialState, builder => {
  builder.addCase(getChannel.fulfilled, (state, action) => {
    const {
      id,
      name,
      host,
      type,
      merchant,
      country,
      currency,
      company,
      properties,
    } = action.payload;

    const mergedProperties = Utils.object.mergeDeep<ChannelProperties>(properties, initialState.properties, ['from', 'to', 'pickup', 'delivery']);
    let flows = setFlowOptions(properties.flows, name);

		state.id = id;
		state.name = name;
		state.host = host;
		state.type = type;
		state.merchant = merchant;
		state.country = country;
		state.currency = currency;
		state.company = company;
		state.properties = {
			...mergedProperties,
			flows
		};
		state.timestamp = DateTime.now().plus({minutes: 10}).toISO();
		state.error = null;
  })
	builder.addCase(getChannel.rejected, (state, action) => {
		state.error = action.payload as string;
	})
  builder.addCase(flushChannel, () => (initialState))
});

export default channel;
