import ENV from "../../utils/environments";
import {UserRegistrationData, UserResponse} from "./authTypes";
import Fetcher, { requestConfig } from "../../utils/fetcher";
import {AxiosRequestConfig, AxiosResponse} from "axios";

class AuthService {

  static async register(user: UserRegistrationData): Promise<UserResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/`, 'POST',
      {
        first_name: user.firstName,
        last_name: user.lastName,
        phone_number: user.phoneNumber,
        email: user.email,
        password: user.password
      },
      {},
      await requestConfig('registration')
    ).then((response) => response.data)
  }

  static verifyUser(id: string, token: string): Promise<AxiosResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/${id}/verify/`, 'GET', {}, {}, {headers: {Authorization: `Bearer ${token}`}} as AxiosRequestConfig)
  }

  static async requestPasswordReset(email: string): Promise<UserResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/${email}/password/`, 'GET', {}, {}, await requestConfig('passwordRecover')).then((response) => response.data);
  }

  static resetPassword(token: string, email: string, password: string): Promise<UserResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/${email}/change_password/`, 'PUT', {password}, {}, {headers: {Authorization: `Bearer ${token}`}}).then((response) => response.data);
  }

  static getUser(email: string): Promise<UserResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/`, 'GET', {}, {email}).then((response) => response.data?.results && response.data.results[0]);
  };

  static updateUser(user: UserResponse): Promise<UserResponse> {
    return Fetcher(`${ENV.API_URL}/auth/users/${user.id}/`, 'PUT', user).then((response) => response.data);
  };
}

export default AuthService;