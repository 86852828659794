import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Link, Navigate, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import TextInput from '../../components/inputs/TextInput';
import useTranslations from "../../hooks/useTranslations";
import Authentication from '../../utils/authentication';
import {OAuthErrorTypes} from "../../store/auth/authTypes";
import {getUser} from '../../store/auth/authReducer'
import {useValidation} from "../../hooks";
import {useAppDispatch} from "../../store";
import sharedStyles from '../../styles/Shared.module.scss';
import Button from 'components/inputs/buttons/Button';

interface Fields {
  email: string;
  password: string;
  general?: string;
}

const Login = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {translation} = useTranslations();
  const {validateEmail, validatePassword} = useValidation();
  const isLoggedIn = Authentication.isLoggedIn();
  const initialState = {
    email: "",
    password: ""
  }

  const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(false);
  const [formData, setFormData] = useState<Fields>(initialState);
  const [errors, setError] = useState<Fields>(initialState);
  const {email, password} = formData;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setFormData({...formData, [target.name]: target.value});
  }

  const validateFields = (fields: Fields): boolean => {
    const errors = {
      email: validateEmail(fields.email) || '',
      password: validatePassword(fields.password) || ''
    }
    setError(() => errors)
    return !!errors.email || !!errors.password;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateFields({email, password})) return;

    return Authentication.auth()
      .then(async (token) => ({
        oldToken: token,
        token: await Authentication.login(email, password),
      }))
      .then(() => dispatch(getUser(email)).catch((error) => console.log('error', error.response)))
      .then(() => navigate('/portal'))
      .catch((error) => {
        if (error?.body?.error === OAuthErrorTypes.invalid_grant) toast.error(translation.get('login:invalid_grant'))
        else toast.error(translation.get('error:general_error'))
      })
  }

  if (isLoggedIn) {
    return (<Navigate to="/"/>);
  }

  return (
    <div className={sharedStyles.authContainer}>
      <form onSubmit={handleSubmit} className={sharedStyles.authForm}>
        <TextInput
          label={translation.get('login:email')}
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={sharedStyles.authLabel}
          type="email"
          name="email"
          error={errors.email && errors.email}
        />

        <TextInput
          label={translation.get('login:password')}
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={sharedStyles.authLabel}
          type="password"
          name="password"
          component={<Link className={sharedStyles.link} to="/password/forgot">{translation.get("login:forgot_password")}</Link>}
          error={errors.password && errors.password}
        />

        <div className={`${sharedStyles.checkbox}`}>
          <input
            type="checkbox"
            id="createAccount"
            value={"true"}
          />
          <label
            htmlFor="createAccount"
            onClick={() => setStayLoggedIn(!stayLoggedIn)}
          >
            {translation.get('login:checkbox')}
          </label>
        </div>

        <Button type="submit" text={translation.get("login:submitButton")} />

        {/* <div className={sharedStyles.authLinks}>
					<span>{translation.get("login:registration")}</span>
					<Link to="/registration">{translation.get("login:registrationLink")}</Link>
				</div> */}
      </form>
    </div>
  )
}

export default Login;
