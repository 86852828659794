import useTranslations from "hooks/useTranslations";
import Utils from "utils/Utils";
import styles from "./AddressForm.module.scss";
import React, {useCallback, useMemo, useState} from "react";
import TextInput from "components/inputs/TextInput";
import {NeomAddress, Location} from "store/booking/bookingTypes";
import {useProperties, useValidation} from "hooks";
import LabeledDropdown from "components/inputs/dropdowns/LabeledDropdown/LabeledDropdown";
import {DropdownItem} from "components/inputs/dropdowns/BaseDropdown";

export interface AddressFormErrors {
  [key: string]: string
}

interface AddressFormProps {
  disabled: boolean;
  location?: Location;
  className: string;
  country: string;
  onUpdate: (form: {form: AddressFields, community: Community}) => void;
  onError: (errors: AddressFormErrors) => void;
}

export type AddressFields = Omit<NeomAddress, 'coordinates' | 'region' | ''>

export interface Community {
  name: string;
  coordinates: {lat: number, lng: number};
  postalCode: string;
}

const communities: Array<Community> = [
  {name: "Neom Community 1", coordinates: {lat: 28.10947, lng: 35.11308}, postalCode: "49662"},
  {name: "Neom Community 2", coordinates: {lat: 28.0263, lng: 35.2476}, postalCode: "49636"}
]

const AddressForm = ({className, disabled, location, country, onUpdate, onError}: AddressFormProps) => {
  /**
   * This component collects and validates address information from the customer using
   * a standardized address form.
   *
   * It also ties in to the map address selector using the redux store.
   */

  const {locale} = useProperties();
  const {translation} = useTranslations();
  const {addressValidators} = useValidation();
  const [errors, setErrors] = useState<AddressFormErrors>({})

  const countryName = useMemo(() => {
    /**
     * Fix location country name
     */
    return Utils.geo.getCountryNameFromISO(country, locale);
  }, [locale, country])

  const initialFormValues = useMemo(() => ({
    community: location?.address.city || "",
    number: location?.address.number || "",
    address_type: location?.address.addition || "",
    postal_code: location?.address.postal_code || "",
    country: countryName
  }), [countryName, location])

  const [form, setForm] = useState<AddressFields>(initialFormValues);

	const addressTypes = [
		{value: "office", label: translation.get("address::community:type:office")}, 
		{value: "cabin", label: translation.get("address::community:type:cabin")}
	]

	const fetchAndUpdateLocation = useCallback((updatedForm: AddressFields) => {
		const { community } = updatedForm;
		const matchingCommunity = communities.find(comm => comm.name === community);
	
		if (matchingCommunity && matchingCommunity.coordinates) {
			onUpdate({form: updatedForm, community: matchingCommunity})
		}
	}, [onUpdate]);

	const debouncedFetchLocation = useMemo(() => {
    let timer: NodeJS.Timeout;
    return (form: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => fetchAndUpdateLocation(form), 1000);
    };
  }, [fetchAndUpdateLocation]);

  const onDropdownChange = useCallback((name: string, item: DropdownItem<any>) => {
    let updates = {};

    if (name === 'community') {
      const value = item.value;
      const matchingCommunity = communities.find(comm => comm.name === value);

      updates = {
        community: value,
        postal_code: matchingCommunity?.postalCode || ""
      };
    } else if (name === 'address_type') {
      updates = {
        address_type: item.value
      };
    }

    const updatedForm = {
      ...form,
      ...updates
    };

    setForm(updatedForm);
		fetchAndUpdateLocation(updatedForm);
  }, [fetchAndUpdateLocation, form]);

  const communityOptions = useMemo(() => {
		return [
    {label: translation.get("address:neom_community_1"), value: "Neom Community 1", isLink: false},
    {label: translation.get("address:neom_community_2"), value: "Neom Community 2", isLink: false},
  ].map(option => ({
    ...option,
  }))}, [translation]);
 
  const onNumberChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const error = addressValidators.number(value)

    if (error) {
      setErrors({...errors, number: error})
      onError({...errors, number: error})

      return errors
    }

    setForm((form) => ({...form, number: value}));

		debouncedFetchLocation({...form, number: value})
  }, [addressValidators, debouncedFetchLocation, form, errors, onError])

  return (
    <div className={`${styles.address} ${className}`}>
      <div>
        <LabeledDropdown
          data-cy="community"
          name={"community"}
          value={communityOptions.find(comm => comm.value === form.community)}
          items={communityOptions}
          labelContainerClassName={styles.wideColumn}
          label={translation.get("address:community")}
          onChange={item => onDropdownChange('community', item)}
          disabled={disabled}
        />
        <TextInput
          data-cy="postal-code"
          customProperty
          name={"postal_code"}
          value={form.postal_code}
          label={translation.get("address:postalcode")}
          labelContainerClassName={styles.narrowColumn}
          error={errors.postal_code && errors.postal_code}
          disabled
        />
      </div>
      <div>
        <LabeledDropdown
          data-cy="address-type"
          name={"type"}
          value={addressTypes.find(type => type.value === form.address_type)}
          items={addressTypes}
          labelContainerClassName={styles.wideColumn}
          label={translation.get("address:type")}
          onChange={item => onDropdownChange('address_type', item)}
          disabled={disabled}
        />

        <TextInput
          data-cy="number"
          customProperty
          name={"number"}
          value={form.number}
          labelContainerClassName={styles.narrowColumn}
          label={translation.get("address:street:number")}
          onChange={onNumberChange}
          error={errors.number && errors.number}
          disabled={disabled}
        />
      </div>
      <div>
        <TextInput
          disabled
          name={"country"}
          labelContainerClassName={styles.wideColumn}
          value={form.country}
          label={translation.get("address:country")}
          error={errors.country && errors.country}
        />
      </div>
    </div>
  )
}

export default AddressForm
