import store from "../../store";
import {IChannelTranslationsState} from "../../store/channel/channel.types";

class Translation {
  public translation?: IChannelTranslationsState;

  public constructor(translation?: IChannelTranslationsState) {
    this.translation = translation
  }

  public get(key: keyof IChannelTranslationsState, variables?: { [key: string]: string }): string {
    let translation = this.translation !== undefined ? this.translation[key] : "";
    if (translation && variables) {
      Object.keys(variables).forEach((item: string) => {
        translation = translation.replace(new RegExp(`{{${item}}}`), variables[item]);
      })
    }
    return translation;
  }
}

class Language {
  public static subscriptions: ((translation: Translation) => void)[] = [];

  public static get language() {
    return store.getState().app.currentLanguage || store.getState().channel.properties.language.meta.default;
  }

  public static change(language: string) {
    const translation = new Translation(store.getState().channel.properties.language.translations[language]);

    for (let subscription of Language.subscriptions) {
      subscription(translation)
    }
  }

  public static getTranslation(): Translation {
    return new Translation(store.getState().channel.properties.language.translations[Language.language]);
  }

  public static subscribe(handler: (translation: Translation) => void) {
    Language.subscriptions.push(handler)
  }

  public static unsubscribe(handler: (translation: Translation) => void) {
    Language.subscriptions = Language.subscriptions.filter((subscription) => subscription !== handler)
  }
}

export default Language;
export {Translation};
