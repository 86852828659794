import { useAppSelector } from "store"
import styles from "./Terms.module.scss"
import { useMemo } from "react";
import React from "react";
import { TermsContent, TermsProperties } from "store/channel/channel.types";

interface Props {
	terms: TermsProperties;
}

const Terms = ({terms}: Props) => {
  const language = useAppSelector((state) => state.app.currentLanguage || state.channel.properties.language.meta.default);

  const content: TermsContent = useMemo(() => {
    return terms[language]
  }, [terms, language])

  if (!content) return <></>;

  return (
    <div className={styles.container}>
      {content.title && <div className={styles.title}>{content.title}</div>}

      {Object.entries(content).map(([key, section]) => {
        if (key === 'title' || typeof section === 'string') return null

        return (
          <React.Fragment key={key}>
            {section.subtitle && <div className={styles.title}>{section.subtitle}</div>}

            {section.statements && (
              <ul className={styles.list}>
                {section.statements.map((item, index) => (
                  <li key={index} className={styles.statement}>{item}</li>
                ))}
              </ul>
            )}
          </React.Fragment>
        );
      })}
    </div>
  )
}

export default Terms;
