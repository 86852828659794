import {createAction, createReducer, Reducer} from "@reduxjs/toolkit";
import {
  CLEAR_LOADER,
  SET_ANNOUNCEMENTS,
  SET_FLOW,
  SET_LANGUAGE,
  UPDATE_ANNOUNCEMENT
} from "./appConstants";
import {IAppState} from "./appTypes";
import {Flow, IAnnouncement} from "../channel/channel.types";

const initialState: IAppState = {
  loading: false,
  announcements: [],
  currentLanguage: undefined,
  currentFlow: undefined
}

export const setAnnouncements = createAction<IAnnouncement[]>(SET_ANNOUNCEMENTS);
export const updateAnnouncement = createAction<{announcements: IAnnouncement[], title: string}>(UPDATE_ANNOUNCEMENT);
export const setLanguage = createAction<string>(SET_LANGUAGE);
export const setCurrentFlow = createAction<Flow>(SET_FLOW);
export const clearLoader = createAction(CLEAR_LOADER);

const app: Reducer<IAppState> = createReducer(initialState, builder => {
  builder.addCase(setAnnouncements, (state, action) => {
    const results = action.payload as Array<IAnnouncement>;

    let announcements = [...state.announcements];

    results.forEach((announcement: IAnnouncement) => {
      if (!announcements.length || !announcements.find(item => item.title === announcement.title)) {
        announcements = announcements.concat(announcement)
      }
    })

    announcements.forEach((announcement: IAnnouncement) => {
      if (!results.some(e => e.title === announcement.title)) {
        announcements = announcements.filter((item) => item.title !== announcement.title);
      }
    })

    state.announcements = announcements
  })
  builder.addCase(updateAnnouncement, (state, action) => {
      state.announcements = action.payload.announcements.map((item: IAnnouncement) => {
        if (item.title === action.payload.title) {
          return {...item, display: false};
        }
        return item;
      })
  })
  builder.addCase(setLanguage, (state, action) => {
      state.currentLanguage = action.payload
  })
  builder.addCase(setCurrentFlow, (state, action) => {
    state.currentFlow = action.payload
  })
  builder.addCase(clearLoader, (state) => {
      state.loading = false
  })
});

export default app;