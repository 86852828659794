import { createAction, createReducer, Reducer} from "@reduxjs/toolkit";
import {
  CLEAR_FLIGHT,
  SET_FLIGHT_SEARCH,
  SET_PNR,
  SET_JOURNEY,
  SET_PASSENGERS, 
  SET_SESSION_ID
} from "./flightConstants";
import { FlightSearch } from "./types/api/liveFlight";
import { Journey } from "./types/api/reservation";
import {FlightState, Passenger} from "./types/store";

const initialState: FlightState = {
  pnr: undefined,
  search: undefined,
  journey: undefined,
  passengers: [],
  session: undefined
};

export const saveFlightInfo = createAction<FlightSearch>(SET_FLIGHT_SEARCH);
export const savePnrCode = createAction<string>(SET_PNR);
export const saveSessionId= createAction<string>(SET_SESSION_ID);
export const saveJourney = createAction<Journey>(SET_JOURNEY);
export const setPassengers = createAction<Passenger[]>(SET_PASSENGERS);
export const clearFlight = createAction(CLEAR_FLIGHT);

const flight: Reducer<FlightState> = createReducer(initialState, builder => {
  builder.addCase(saveFlightInfo, (state, action) => {
    state.search = action.payload
  })
  builder.addCase(savePnrCode, (state, action) => {
    state.pnr = action.payload
  })
  builder.addCase(saveJourney, (state, action) => {
    state.journey = action.payload
  })
  builder.addCase(setPassengers, (state, action) => {
    state.passengers = action.payload
  })
  builder.addCase(clearFlight, () => initialState)
  builder.addCase(saveSessionId, (state, action) => {
    state.session = action.payload
  })
});

export default flight;
