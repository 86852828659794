import {DateTime} from "luxon";
import CompleteStep from "../../../../../components/CompleteStep/CompleteStep";
import useTranslations from "../../../../../hooks/useTranslations";
import {useAppDispatch, useAppSelector} from "../../../../../store";
import {BookingState} from "../../../../../store/booking/types/store";
import {getTenantDetails} from "../../../../../store/portal/portalReducer";
import styles from "./NeomLocationStepComplete.module.scss";
import React, {useLayoutEffect, useMemo} from "react";
import {ITenant} from "../../../../../store/portal/portalTypes";
import {BookingMeta} from "../../../../../store/booking/bookingTypes";
import {CompleteStepProps} from "../../../../../components/containers/Step/types";
import {useBookingMeta} from "../../../../../hooks";
import {Flow} from "store/channel/channel.types";

interface Props extends CompleteStepProps {
  onEdit: () => void;
  data: {
    booking: Required<BookingState> & { details: { meta: Required<BookingMeta> } };
    disableEdit: boolean;
  }
}

const getInterval = (timeslot: string) => {
  if (!timeslot) return {};

  const intervals = timeslot.split("-");

  const start = intervals[0].trim()
  const end = intervals[1].trim()

  return {start, end}
}

const NeomLocationStepComplete: React.FC<Props> = ({onEdit, data}) => {
  /**
   * The completed LocationStep state.
   *
   * In this state the component will display a recap of the selected logistics options.
   */
  const {translation} = useTranslations();
  const {flow} = useBookingMeta();
  const dispatch = useAppDispatch();

  const pickupDate = DateTime.fromJSDate(new Date(data.booking.details?.meta?.timeslot?.time || "")).toLocaleString(DateTime.DATE_SHORT)

  const code = data.booking.details?.code;
  const tenantId = data.booking.details?.assignments[0]?.tenant;

  const tenant = useAppSelector(state =>
    state.portal.tenants.find((tenant: ITenant) => tenant?.bookingCode === code) || null
  );

  const luggage = data.booking.details?.assignments[0]?.luggage_count

  const {start, end} = useMemo(() => getInterval((data.booking.details?.meta?.timeslot?.timeLabel || "")), [data])

  const headingValues = useMemo(() => ({
    partner: tenant?.name || "",
    start: start || "",
    end: end || "",
    pickup_address: `, ${data.booking.meeting.location.address.city}, ${data.booking.meeting.location.address.number}, ${data.booking.meeting.location.address.postal_code}`,
    date: pickupDate,
    ...(flow === Flow.City && ({luggage: luggage?.toString() || ""}))
  }), [data.booking.meeting.location.address, end, flow, luggage, pickupDate, start, tenant?.name])

  const translationKey = useMemo(() => {
    return luggage === 1
      ? "location:heading:community_completed_single"
      : "location:heading:community_completed_plural"
  }, [luggage]);

  useLayoutEffect(() => {
    if (!tenant && tenantId && code) dispatch(getTenantDetails({tenantId, bookingCode: code}));
  }, [tenant, dispatch, code, tenantId]);

  // @ts-ignore
  return (
    <CompleteStep
      id="location-edit"
      disableEdit={data.disableEdit}
      onEdit={onEdit}
      className={styles.container}
      headingText={translation.get(translationKey, headingValues)}
    />
  )
}

export default NeomLocationStepComplete
