import React, {ChangeEvent, FormEvent, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import TextInput from "../../components/inputs/TextInput";
import useTranslations from "../../hooks/useTranslations";
import {OAuthErrorTypes} from "../../store/auth/authTypes";
import {useValidation} from "../../hooks";
import AuthService from "../../store/auth/authService";
import {toast} from "react-toastify";
import sharedStyles from '../../styles/Shared.module.scss'
import Button from 'components/inputs/buttons/Button';

interface Fields {
  email: string;
}

const PasswordReset = () => {

  const {translation} = useTranslations();
  const {validateEmail} = useValidation();
  const navigate = useNavigate();
  const initialState = {email: ""}

  const [formData, setFormData] = useState<Fields>(initialState);
  const [errors, setError] = useState<Fields>(initialState);
  const {email} = formData;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setFormData({...formData, [target.name]: target.value});
  }

  const validateFields = (fields: Fields): boolean => {
    const errors = {
      email: validateEmail(fields.email) || ''
    }
    setError(() => errors)
    return !!errors.email
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateFields({email})) return;
    return AuthService.requestPasswordReset(email)
      .then(() => {
        toast.success(translation.get("password_reset:request_sent"));
        navigate('/login');
      })
      .catch((error) => {
        if (error?.response?.data?.code === OAuthErrorTypes.invalid_grant) toast.error(translation.get('login:invalid_grant'))
        else toast.error(translation.get('error:general_error'))
      })
  }

  return (
    <div className={sharedStyles.authContainer}>
      <form onSubmit={handleSubmit} className={sharedStyles.authForm}>
        <TextInput
          label={translation.get('login:email')}
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={sharedStyles.authLabel}
          type="email"
          name="email"
          error={errors.email && errors.email}
        />
        <Button className={sharedStyles.authSubmit} type="submit" text={translation.get("password_reset:request")}/>
				<div className={sharedStyles.authLinks}>
					<span>{translation.get('password_reset:back_to_login')}</span>
					<Link to="/login">{translation.get("password_reset:link")}</Link>
				</div>
      </form>
    </div>
  )
}

export default PasswordReset;
