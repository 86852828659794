import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import environments from "../utils/environments";
import channelReducer from "./channel/channelReducer";
import flightReducer from "./flight/flightReducer";
import bookingReducer from "./booking/bookingReducer";
import authReducer from "./auth/authReducer";
import Migration from "./Migration";
import portalReducer from "./portal/portalReducer";
import appReducer from "./app/appReducer";
import membershipReducer from "./membership/membershipReducer";
import paymentReducer from "./payment/paymentReducer";
import { Connect, connect as reduxConnect, TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import migrationReducer from "./migrations/migrationReducer";

const reducers = combineReducers({
  channel: channelReducer,
  flight: flightReducer,
  booking: bookingReducer,
  auth: authReducer,
  portal: portalReducer,
  app: appReducer,
  membership: membershipReducer,
  payment: paymentReducer,
	migration: migrationReducer
})

const persistConfig = new Migration(storage, environments.VERSION, environments.CHANNEL).getConfig();

const persistedReducers = persistReducer(persistConfig, reducers);

const store = configureStore({
  devTools: true,
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => getDefaultMiddleware({
  }).prepend(thunk),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export { reducers, store, persistor };
export default store;


// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const connect: Connect<RootState> = reduxConnect