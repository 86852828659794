import React, {useCallback} from 'react';
import styles from './Option.module.scss'
import {DropdownItem} from "../../BaseDropdown";

interface Props {
  item: DropdownItem<number>
  open: Function;
  index: number;
  onClick: (item: DropdownItem<number>) => void;
}

const IntervalOption: React.FC<Props> = ({item, open, onClick, index}) => {
  /**
   * The default dropdown listed item visual.
   */
  const onOptionClick = useCallback(()=>{
    onClick(item);
    if (open) open(() => false)
  }, [item, open, onClick])

  return (
    <div data-cy="interval-option" key={index} className={styles['option']} onClick={onOptionClick}>
      <div className={styles['option-value']}>{item.label}</div>
    </div>
  )
}

export default IntervalOption;