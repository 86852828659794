import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import TextInput from "../../components/inputs/TextInput";
import ValidationError from "../../components/ValidationError";
import useTranslations from "../../hooks/useTranslations";
import {OAuthErrorTypes} from "../../store/auth/authTypes";
import {useValidation} from "../../hooks";
import AuthService from "../../store/auth/authService";
import {toast} from 'react-toastify';
import sharedStyles from '../../styles/Shared.module.scss'

interface Fields {
  password: string;
  repeatPassword: string;
}

const PasswordChange = () => {

  const {translation} = useTranslations();
  const navigate = useNavigate();
  const {validatePassword} = useValidation();
  const initialState = {password: "", repeatPassword: ""}

  const [formData, setFormData] = useState<Fields>(initialState);
  const [errors, setError] = useState<{password: string}>({password: ''});
  const [params, setParams] = useState<{token: string, email: string}>({token: '', email: ''})
  const {password, repeatPassword} = formData;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setFormData({...formData, [target.name]: target.value});
  }

  const validateFields = (fields: Fields): boolean => {
    const errors = {
      password: validatePassword(fields.password, fields.repeatPassword) || '',
    }
    setError(() => errors)
    return !!errors.password
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateFields({password, repeatPassword})) return;
    return AuthService.resetPassword(params.token, params.email, password)
      .then(() => {
        toast.success(translation.get("password_reset:success"));
        navigate('/login');
      })
      .catch((error) => {
        if (error?.response?.data?.code === OAuthErrorTypes.invalid_grant) toast.error(translation.get('login:invalid_grant'));
        else toast.error(translation.get('error:general_error'));
      })
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedToken = urlParams.get('token');

    if (encodedToken) {
      let decodedToken = '';
      try {
        decodedToken = atob(encodedToken);
      } catch {
        toast.error(translation.get('error:general_error'));
        navigate('/login');
      }

      const tokenRegex  = decodedToken.match(/token=(.*?),/);
      const emailRegex = decodedToken.match(/email=(.*?)$/);

      if (!tokenRegex || !emailRegex) return;
      const email = emailRegex[1];
      const token = tokenRegex[1];
      if (!email || !token) return;

      setParams({token, email});
    }
  }, [navigate, translation])

  return (
    <div className={sharedStyles.authContainer}>
      <form onSubmit={handleSubmit} className={sharedStyles.authForm}>
        <TextInput
          label={translation.get('password_change:new_password')}
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={sharedStyles.authLabel}
          type="password"
          name="password"
        />
        <TextInput
          label={translation.get("password_change:repeat_password")}
          value={repeatPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={sharedStyles.authLabel}
          type="password"
          name="repeatPassword"
        />
        {errors.password && <ValidationError error={errors.password}/>}
        <input className={sharedStyles.authSubmit} type="submit" value={translation.get("password_reset:request")}/>
				<div className={sharedStyles.authLinks}>
					<span>{translation.get('password_reset:back_to_login')}</span>
					<Link to="/login">{translation.get("password_reset:link")}</Link>
				</div>
      </form>
    </div>
  )
}

export default PasswordChange;
