import {ActiveStepProps, Errors} from "components/containers/Step/types";
import Button from "components/inputs/buttons/Button";
import useTranslations from "hooks/useTranslations";
import styles from '../LoginStep.module.scss'
import {ChangeEvent, FormEvent, useEffect, useMemo, useState} from "react";
import {useValidation} from "hooks";
import TextInput from "components/inputs/TextInput";
import {Link} from "react-router-dom";
import {LoginFields} from "../LoginStep";
import {useAppSelector} from "store";
import Icon from "components/icons/Icon";
import Email from 'resources/svg/Email.svg';
import Password from 'resources/svg/Password.svg';

declare function UpdateCallback(data: LoginFields): void;

interface Props extends ActiveStepProps {
  onSubmit: typeof UpdateCallback;
  onUpdate?: (...args: any[]) => any;
}

const LoginStepActive = ({onSubmit, errors: stepErrors}: Props) => {
  const {translation} = useTranslations();

  const {validateEmail} = useValidation();
  const initialState = {
    email: "",
    password: ""
  }

  const channel = useAppSelector((state) => state.channel.name)

  const [formData, setFormData] = useState<LoginFields>(initialState);
  const [errors, setErrors] = useState<Errors>(stepErrors);
  const {email, password} = formData;

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setFormData({...formData, [target.name]: target.value});
  }

  const validateFields = (email: string): boolean => {
    const errors = {
      email: validateEmail(email) || '',
    }
		
    setErrors(errors)
    return !!errors.email;
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validateFields(email)) return;

    return onSubmit({email, password});
  }

	useEffect(() => {
		setErrors(stepErrors);
	}, [stepErrors])

  const disableSubmit = useMemo(() => {
    return email === "" || password === "";
  }, [email, password])

  return (
    <div className={styles.container}>
      <h2>{translation.get("login:heading")}</h2>
      <h4>{translation.get("login:subheading", {channel})}</h4>

      <form onSubmit={handleSubmit} className={styles.form}>
        <TextInput
          label={translation.get('login:email')}
          value={email}
          icon={<Icon
            src={Email}
            className={styles.icon}
          />}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={styles.label}
          containerClassName={styles.field}
          type="email"
          name="email"
          error={errors.email}
        />

        <TextInput
          label={translation.get('login:password')}
          value={password}
          icon={<Icon
            src={Password}
            className={styles.icon}
          />}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          labelContainerClassName={styles.label}
          type="password"
          name="password"
          component={
            <Link className={styles.link} to="/password/forgot">
              {translation.get("login:forgot_password")}
            </Link>
          }
          error={errors.password}
        />

        <Button className={styles.submit} disabled={disableSubmit} type="submit" text={translation.get("button:next")}/>
      </form>
    </div>
  )
}

export default LoginStepActive;