import CompleteStep from "components/CompleteStep";
import { StepInfoProps } from "pages/types";
import { Passenger } from "store/flight/types/store";
import useTranslations from "../../../../../hooks/useTranslations";

export interface Props extends StepInfoProps {
  onEdit: () => void;
  data: {
   passengers: Passenger[]
  }
}

const PaxSelectionCompleted: React.FC<Props> = ({data, onEdit}) => {
  const {translation} = useTranslations();

  const variables = {
    luggage:  data.passengers.reduce((total, passenger) => {
      if(passenger.luggage !== undefined)
      return total + passenger.luggage;
      return 0;
    }, 0).toString()
  }

  return  (
    <CompleteStep
      id="pax-select-edit"
      onEdit={onEdit}
      headingText={translation.get("passenger:completed_heading", variables)}
    />
  )

}

export default PaxSelectionCompleted;