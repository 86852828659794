export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const SET_BOOKING = "SET_BOOKING";
export const SET_BUDGET = "SET_BUDGET";
export const SET_MEETING_ADDRESS = "SET_MEETING_ADDRESS";
export const SET_BOOKING_PAYMENT = "SET_BOOKING_PAYMENT";
export const SAVE_INVOICE_DATA = "SAVE_INVOICE_DATA";
export const PARTIAL_RESET_BOOKING = "PARTIAL_RESET_BOOKING";
export const RESET_BOOKING = "RESET_BOOKING";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_FAVOURITE_LOCATION = "SET_FAVOURITE_LOCATION";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SELECT_ADDRESS = "SET_SELECTED_ADDRESS";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const RESET_SA_ADDRESS = "RESET_SA_ADDRESS";
export const RESET_INVOICE = "RESET_INVOICE";
export const GO_TO_STEP = "GO_TO_STEP";