import React from "react";
import styles from "./StepCard.module.scss";
import Icon from "../../../components/icons/Icon/Icon";
import Time from "../../../resources/svg/Time.svg";
import EllipsisH from "../../../resources/svg/EllipsisH.svg";
import Utils from "../../../utils/Utils";

interface StepCardProps {
  iconUrl: string;
  date?: string;
  hour?: string;
  component: JSX.Element;
  timeZone?: string
}

const StepCard = ({iconUrl, date, hour, component, timeZone}: StepCardProps) => {

  let dateTime = new Date();
  if (date) dateTime = new Date(Utils.date.parse(date));

  return (
    <div className={styles.component}>
      <div className={styles.row}>
        {date ? <div className={styles.dateTime}>
          <Icon src={Time} className={styles.dateIcon}/>
          <div>
            <p className={styles.date}>{dateTime.toLocaleDateString([], {...timeZone && {timeZone: timeZone}})}</p>
            <p className={styles.date}>{hour ? hour : dateTime.toLocaleTimeString([], {
              ...timeZone && {timeZone: timeZone},
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })}</p>
          </div>
        </div> : <div className={styles.dateTime}/>}
      </div>
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          <Icon src={EllipsisH} className={styles.nextIcon}/>
          <Icon src={iconUrl} className={styles.stepIcon}/>
        </div>
        <div className={styles.cardBody}>
          {component}
        </div>
      </div>
    </div>
  );
}

export default StepCard;