import React, {ChangeEvent, useCallback, useEffect, useImperativeHandle, useState} from "react";
import TextInput from "../../../../../../components/inputs/TextInput";
import useTranslations from "../../../../../../hooks/useTranslations";
import {useAppSelector} from "../../../../../../store";
import styles from "./MembershipCode.module.scss";

type Validation = { status: 'valid' | 'error', value: string }
export type MembershipRef = {
  getFormState: () => Validation
}

interface Props {
  error: string
}

const MembershipCode = React.forwardRef<MembershipRef, Props>(({error}, ref) => {
  const validation = useAppSelector((state) => state.channel.properties.switches.validation);
  const membership = useAppSelector((state) => state.membership);
  const {translation} = useTranslations();

  const [code, setCode] = useState<string>()

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback((e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    setCode(() => (target.value));
  }, [])

  const validate = useCallback((): Validation => {
    if(code === undefined) return {
      status: 'error',
      value: translation.get('membership:error')
    }

    const regex = new RegExp(validation);
    const valid = regex.test(code);

    return {
      status: valid ? 'valid' : 'error',
      value: (!valid && translation.get('membership:error')) || code
    };
  }, [translation, validation, code])

  useImperativeHandle(ref, () => ({
    getFormState: validate,
  }));

  useEffect(() => {
    setCode(membership.code);
  }, [membership])

  return <TextInput
    containerClassName={styles.container}
    inputClassName={styles.codeField}
    label={translation.get("membership:field")}
    onChange={onChange}
    error={error}
  />
});

export default MembershipCode;
