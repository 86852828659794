import "./WindowSelector.module.scss"
import React, {useCallback, useLayoutEffect, useMemo, useState} from "react";
import useTranslations from "../../../hooks/useTranslations";
import {LogisticWindow, LogisticWindowOptions} from "./types";
import styles from "./WindowSelector.module.scss";
import DateInput from "../../inputs/DateInput/DateInput";
import IntervalDropdown from "../../inputs/dropdowns/IntervalDropdown/IntervalDropdown";
import {DateTime} from "luxon";
import TimeIcon from "../../icons/TimeIcon";
import CalendarIcon from "../../icons/CalendarIcon";

interface WindowSelectorProps {
  className?: string;
  disabled: boolean;
  onUpdate: (window: LogisticWindow) => any;
  options: Required<LogisticWindowOptions>;
  dateLabel: string;
  timezone: string;
  window?: LogisticWindow;
  date?: DateTime
}

const WindowSelector = (
  {
    className,
    disabled,
    onUpdate,
    options,
    dateLabel,
    timezone,
    window,
    date
  }: WindowSelectorProps
) => {
  /**
   * A dropdown menu which allows our customers to select a window of time within which they would
   * like to have their luggage picked up or delivered depending on the service.
   */
  const {translation} = useTranslations();

  const [selectedDate, setSelectedDate] = useState<DateTime|undefined>(date);
  const [windows, setWindows] = useState<LogisticWindow[]>([]);
  const [selectedWindow, setSelectedWindow] = useState<LogisticWindow|undefined>(window);

  useLayoutEffect(() => {
    /**
     * Set the initial date and timeSlot based on user's selection.
     */

    if(date) setSelectedDate(() => date);
    setSelectedWindow(() => window)
  }, [date, window])

  const start = useMemo(() => {
    /**
     * The start date used by the date selector
     */
    return DateTime
      .fromMillis(parseInt(Object.keys(options || {})[0]))
      .setZone(timezone)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {keepLocalTime: true})
      .toJSDate();
  }, [timezone, options])

  const end = useMemo(() => {
    /**
     * The end date used by the date selector
     */
    return DateTime
      .fromMillis(parseInt(Object.keys(options || {})[Object.keys(options || {}).length - 1]))
      .setZone(timezone)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {keepLocalTime: true})
      .toJSDate()
  }, [timezone, options])

  const jsDate = useMemo(() => {
    /**
     * The currently selected date
     */

    return selectedDate && selectedDate
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone, {keepLocalTime: true})
      .toJSDate();
  }, [selectedDate])

  useLayoutEffect(() => {
    /**
     * Set the initial date based on available options.
     */
    if (selectedDate !== undefined || options === undefined || !Object.keys(options).length) return;
    const date = DateTime.fromMillis(parseInt(Object.keys(options)[0])).setZone(timezone);
    setSelectedDate(date);
    setWindows(() => options[date.toMillis().toString()]);
  }, [timezone, options, selectedDate])

  const onIntervalSelection = useCallback((window: LogisticWindow): void => {
    /**
     * Submit a formatted datetime object back to the onUpdate callback.
     */
    if (selectedDate === undefined) return;
    setSelectedWindow(window);
    onUpdate(window);
  }, [selectedDate, onUpdate]);

  const onDateSelected = useCallback((date: Date): void => {
    /**
     * Save the date in the state and change the available windows based on the
     * selected date.
     *
     * We revert the timezone hack defined in jsDate in order to retrieve the
     * correct options value.
     */
    const revert = DateTime
      .fromJSDate(date)
      .setZone(timezone, {keepLocalTime: true})

    setSelectedDate(revert);
    setSelectedWindow(undefined);
    setWindows(() => options[revert.toMillis().toString()]);
  }, [timezone, options])

  if (Object.keys(options).length === 0)
    return <></>

  return (
    <React.Fragment>
      <div className={styles["date-time-container"]}>
        <div className={styles["date-time"]}>
          {jsDate && <DateInput
            disabled={disabled}
            label={dateLabel}
            value={jsDate}
            name="pickupDate"
            icon={CalendarIcon}
            min={start}
            max={end}
            onChange={onDateSelected}
          />}
        </div>
      </div>
      <div className={styles["date-time-container"]}>
        <div className={styles["date-time"]}>
          <IntervalDropdown
            data-cy="timeslot-select"
            icon={TimeIcon}
            label={translation.get("location:label_time")}
            items={windows}
            value={selectedWindow}
            containerClassName={className}
            onChange={onIntervalSelection}
            disabled={disabled}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default WindowSelector;
