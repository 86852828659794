interface Codes {
  iata: string
  icao: string

  [key: string]: string
}

interface Carrier {
  name: string
  codes: Codes
}

interface Airport {
  location_id: string
  name: string
  services: {
    injection: boolean
    belt: boolean
    meeting: boolean
  }
  codes: Codes
  latitude: number
  longitude: number
  timezone: string
  city: string
  region: string
  country: string
}

interface Schedule {
  id?: number
  datetime: string
  datetime_local: string
  airport: Airport
}

interface FlightEvent {
  id?: number
  designator: string
  codeshares: string[]
  carrier: Carrier
  schedule: Schedule
}

export interface Journey {
  departure: FlightEvent
  arrival: FlightEvent
}

export interface Passenger {
  title: string
  first_name: string
  last_name: string
  email?: string
  infant: boolean
  phone_numbers?: string[]
  allowance: Allowance[]
}

export interface Allowance {
  flight_id: number
  weight_sharing: boolean
  unit: AllowanceUnit
  base: number
  quantity: number
}

export enum AllowanceUnit {
  kg = 'kg',
  count = 'count',
}

export interface Recaptcha {
  success: boolean
  challenge_ts: string
  hostname: string
  score: number
  action: string
}

export interface APIReservation {
  reference?: string // will be removed soon... move towards record_locator instead
  record_locator: string
  session: string
  journeys: Journey[]
  journey?: Journey
  passengers: Passenger[]
  recaptcha: Recaptcha;
  query: {
    record_locator: string
    last_name: string
  }
}