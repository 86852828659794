import ResizeObserver from 'resize-observer-polyfill';
import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import useTranslations from "../../hooks/useTranslations";
import styles from "./PriceOverview.module.scss";
import {price} from "../../utils/price";
import {IChannelTranslationsState} from "../../store/channel/channel.types";
import {useAppSelector} from "../../store";

const PriceOverview: React.FC = () => {
  const [
    formatCodes,
    currentLanguage,
    budgetInfo,
    invoiceInfo,
    currentFlow
  ] = [
    useAppSelector(state => state.channel.properties.language.meta.ietf),
    useAppSelector(state => state.app.currentLanguage || state.channel.properties.language?.meta.default),
    useAppSelector((state) => state.booking.budget),
    useAppSelector((state) => state.booking.invoice),
    useAppSelector((state) => state.app.currentFlow || state.channel.properties.switches.flow.default)
  ];

  const [totalPrice, setTotalPrice] = useState<string>("");
  const [position, setPosition] = useState<'fixed' | 'relative'>('fixed')
  const [pageHeight, setPageHeight] = useState<number>(window.innerHeight);
  const {translation} = useTranslations();

  useEffect(() => {
    if (budgetInfo === undefined) return;
    if (Object.keys(budgetInfo).length === 0) return;
    const currentLanguageCode = formatCodes[currentLanguage];
    let exponent: number, gross: number;

    if (invoiceInfo && Object.keys(invoiceInfo).length > 0) {
      exponent = invoiceInfo?.currency?.exponent;
      gross = invoiceInfo?.price.gross;
    } else {
      exponent = budgetInfo.budgets[0]?.currency?.exponent;
      gross = budgetInfo.price?.gross;
    }

    setTotalPrice(() =>
      price(budgetInfo?.currency?.code, gross, exponent, currentLanguageCode ? currentLanguageCode : "nl-NL")
    );
  }, [formatCodes, currentLanguage, budgetInfo, invoiceInfo]);

  useEffect(() => {
    const footerHeight = document.getElementById('footer')?.offsetHeight;
    const onHeightChange = (e: Event) => {
      if (footerHeight && pageHeight - footerHeight > document.documentElement.scrollTop + window.innerHeight)
        setPosition('fixed')

      else setPosition('relative')
    }

    window.addEventListener("scroll", onHeightChange);
    return () => window.removeEventListener("scroll", onHeightChange);
  }, [pageHeight]);

  useEffect(() => {
    // create an Observer instance
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      return setPageHeight(entries[0].target.clientHeight)
    })

    // start observing a DOM node
    resizeObserver.observe(document.body);
    return () => resizeObserver.unobserve(document.body);
  }, [])

  return (
    <div className={`${styles.wrapper}`}>
      <div className={`${styles.floater} ${styles[position]}`}>
        {budgetInfo !== undefined && Object.keys(budgetInfo).length > 0 && (
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{ease: "easeOut", duration: 1}}
            className={`${styles.priceOverview}`}
          >
            <div
              className={styles['service']}>{translation.get(`service:${currentFlow}` as keyof IChannelTranslationsState)}</div>
            <div className={styles.flexContainer}>
              <div className={styles.total}>{translation.get('price_overview:total')}</div>
              <div data-cy="price-overview" className={styles.price}>{totalPrice}</div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default PriceOverview;
