import {useCallback, useState} from "react";
import styles from "./Switch.module.scss";
import useDirection from "hooks/useDir";


export interface ISwitchProps {
  id?: string;
  leftLabel: string;
  rightLabel: string;
  initial?: 'left' | 'right';
  onChange?: (option: 'left' | 'right') => void;
  className?: string;
}

const Switch = ({id, leftLabel, rightLabel, initial, onChange, className}: ISwitchProps) => {
  /**
   * Allows the customer to select one of two options.
   *
   * After an interaction with the switch, the onChange callback is triggered with
   * the selected option passed as a string literal.
   */
  const [switchDirection, setSwitchDirection] = useState<'left' | 'right'>(initial || 'left');

  const dir = useDirection();

  const handleChange = useCallback(() => {
    const value = switchDirection === 'right' ? 'left' : 'right';
    setSwitchDirection(value);

    if (onChange) onChange(value);

    return value;
  }, [switchDirection, onChange]);

  return (
    <div className={`${styles.switch} ${className}`}>
      <div className={styles.label}>{dir === "rtl" ? rightLabel : leftLabel}</div>
      <input
        id={id}
        type="checkbox"
        className={`${styles.toggle} toggle`}
        checked={switchDirection === 'right'}
        onChange={handleChange}
      />
      <div className={styles.label}>{dir === "rtl" ? leftLabel : rightLabel}</div>
    </div>
  );
};

export default Switch;
