import React from "react";
import styles from "./Warning.module.scss";
import Icon from "../../../components/icons/Icon/Icon";

interface WarningProps {
  icon: string;
  text: string | JSX.Element;
}

const Warning = ({icon, text}: WarningProps) => {

  return (
    <div className={styles.component}>
      <Icon className={styles.icon} src={icon}/>
      <div className={styles.message}>{text}</div>
    </div>
  );
}

export default Warning;