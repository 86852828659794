import Mustache from 'mustache';
import {useCallback} from "react";
import {isPossiblePhoneNumber} from 'react-phone-number-input'
import {Address} from "../store/booking/bookingTypes";
import {AirportDirection} from "../store/flight/types/enums";
import {DateTime, Duration} from "luxon";
import {Passenger} from 'store/flight/types/store';
import useTranslations from "./useTranslations";

export type AddressValidators = keyof Omit<Address, 'coordinates' | 'region'>;

export default function useValidation() {
  const {translation} = useTranslations()

  const validateFlightNumber = useCallback((flightNumber: string, whitelist: Array<string>, direction: AirportDirection): string | undefined => {
    if (direction === AirportDirection.Departure && !whitelist.length) return translation.get("validation:internal_error");
    const designator = flightNumber.substring(0, 2);
    const flightNumberChar = /[^A-Za-z0-9]+/.test(flightNumber);
    if (flightNumberChar) return translation.get("validation:flight:pnr_no_special_char");
    if (flightNumber.trim() === "") return translation.get("validation:flight:flight_number_required");
    if (whitelist.length) {
      const whitelisted = whitelist.includes(designator);
      if (!whitelisted) return translation.get("validation:flight:wrong_airline");
    }


    if (/^([A-Z]{2}|[A-Z][0-9]|[0-9][A-Z])\d{1,4}$/.test(flightNumber)) return;

    return translation.get("validation:flight:flight_number_invalid");
  }, [translation])

  const validatePnr = useCallback((pnr: string, pnrLength?: string): string | undefined => {
    const pnrChar = /[A-Za-z0-9]+/.test(pnr);
    if (!pnrChar) return translation.get("validation:flight:pnr_no_special_char") || '';

    if(pnrLength) {
      const isChannelRuleValid = new RegExp(pnrLength).test(pnr);

      if (!isChannelRuleValid) return translation.get("validation:flight:pnr_length") || '';
    }
  }, [translation])

  const validateFlightDate = useCallback((date: DateTime, offset: Duration): string | undefined => {
    /**
     * Check that a given flight does not depart later than a specified duration offset
     */

    const now = DateTime.now()

    if (date > now.plus(offset)) return;
    if (date <= now) return translation.get("validation:flight:date_departed");

    return Mustache
      .render(
        translation.get("validation:flight:date_offset_invalid"),
        {
          offset: offset.as('hour')
        })
  }, [translation])

  const validateFlightDeparture = useCallback((departure: string, whitelist?: Array<string>): string | undefined => {
    if (!whitelist) return translation.get("validation:internal_error");
    if (whitelist) {
      const whitelisted = whitelist?.includes(departure);
      if (!whitelisted) return translation.get("validation:flight:wrong_departure");
    }
  }, [translation])

  const validateFlightArrival = useCallback((arrival: string, whitelist?: Array<string>): string | undefined => {
    if (!whitelist) return translation.get("validation:internal_error");
    if (whitelist) {
      const whitelisted = whitelist?.includes(arrival);
      if (!whitelisted) return translation.get("validation:flight:wrong_arrival");
    }
  }, [translation])

  const validateEmail = useCallback((email: string): string | undefined => {
    if (/^[^\s@]+@[a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)*\.[a-zA-Z]{2,}$/.test(email)) return;
    if (email.trim() === "") return translation.get("validation:input:email_required");
  
    return translation.get("validation:input:email_invalid");
  }, [translation]);

  const validateConfirmEmail = useCallback((email: string, confirmEmail: string) => {
    if(email === confirmEmail) return;

    return translation.get("validation:input:email_confirmation_invalid");
  }, [translation])

  const validateFirstName = useCallback((name: string): string | undefined => {
    if (name.trim() === "") return translation.get("validation:input:firstname_required");
    if (/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-\s]*$/.test(name)) return;

    return translation.get("validation:input:firstname_invalid");
  }, [translation])

  const validateLastName = useCallback((name: string): string | undefined => {
    if (name.trim() === "") return translation.get("validation:input:lastname_required");
    if (/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-\s]*$/.test(name)) return;

    return translation.get("validation:input:lastname_invalid");
  }, [translation])

  const validatePassportNumber = useCallback((passportNumber: string): string | undefined => {
    if (/^\w+$/.test(passportNumber)) return;
    if (passportNumber.trim() === "") {
      return translation.get("validation:input:passport_required");
    }

    return translation.get("validation:input:passport_invalid");
  }, [translation])

  const validatePhoneNumber = useCallback((phoneNumber: string, required: boolean): string | undefined => {
    if (required && !phoneNumber) return translation.get("validation:input:phone_required");
    if (phoneNumber && (!isPossiblePhoneNumber(phoneNumber))) return translation.get("validation:input:phone_invalid");
  }, [translation])

  const validatePassword = useCallback((password: string, confirmPassword?: string): string | undefined => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)) return;
    if (password.trim() === "") return translation.get("validation:input:password_required");
    if (confirmPassword && password !== confirmPassword) {
      return translation.get("validation:input:password_no_match");
    }
    if (password.length < 8) return translation.get("validation:input:password_short");
    if (!/[a-zA-Z]/.test(password)) return translation.get("validation:input:password_no_letters");
    if (!/\d/.test(password)) return translation.get("validation:input:password_no_digit");
  }, [translation])

  const validateLuggage = useCallback((passengers: Passenger[]) => {
    /**
     * At least one luggage item should be selected.
     */
    const luggage = passengers.reduce((total, passenger) => {
      if (passenger.luggage !== undefined)
        return total + passenger.luggage;
      return 0;
    }, 0)

    if (luggage > 0) return;

    // return translation.get("validation:input:luggage");
    return "At least one luggage item is required"
  }, [])

  const validateSaudiAddress = useCallback((shortAddress: string): string | undefined => {
    /**
     * Used to validate Saudi style short addresses.
     */
    if (/^[A-Za-z]{4}\d{4}$/.test(shortAddress)) return;
    return translation.get("validation:input:short_address_incorrect");
  }, [translation])

  const validateConfirmPassword = useCallback((confirmPassword: string): string | undefined => {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(confirmPassword)) return;
    if (confirmPassword.trim() === "") return translation.get("validation:input:password_required");
    if (confirmPassword.length < 8) return translation.get("validation:input:password_short");
    if (!/[a-zA-Z]/.test(confirmPassword)) return translation.get("validation:input:password_no_letters");
    if (!/\d/.test(confirmPassword)) return translation.get("validation:input:password_no_digit");
  }, [translation])

  const validateAddressStreet = useCallback((street: string): string | undefined => {
    if (street.length > 100) return translation.get("validation:input:street_too_long");
    if (street.trim() === "") return translation.get("validation:input:street_required");
  }, [translation])

  const validateAddressNumber = useCallback((number: string): string | undefined => {
    if (number.length > 10) return translation.get("validation:input:number_too_long");
  }, [translation])

  const validateAddressAddition = useCallback((addition: string | number): string | undefined => {
    if (typeof addition === 'string' && addition.length > 10) return translation.get("validation:input:addition_too_long");
    if (typeof addition === 'number' && addition.toString(10).length > 5) return translation.get("validation:input:addition_too_long");
  }, [translation])

  const validateAddressPostalCode = useCallback((postalCode: string): string | undefined => {
    if (postalCode.length > 10) return translation.get("validation:input:postalCode_too_long");
  }, [translation])

  const validateAddressDescription = useCallback((description: string): string | undefined => {
    return;
  }, [])

  const validateAddressCountry = useCallback((country: string): string | undefined => {
    if (country.length > 56) return translation.get("validation:input:country_too_long");
    if (country.trim() === "") return translation.get("validation:input:country_required");
  }, [translation])

  const validateAddressCity = useCallback((city: string): string | undefined => {
    if (city.length > 67) return translation.get("validation:input:city_too_long");
    if (city.trim() === "") return translation.get("validation:input:city_required");
  }, [translation])

  return {
    validateFlightNumber,
    validatePnr,
    validateFlightDate,
    validateFlightDeparture,
    validateFlightArrival,
    validateEmail,
    validateConfirmEmail,
    validateFirstName,
    validateLastName,
    validatePassportNumber,
    validatePhoneNumber,
    validatePassword,
    validateSaudiAddress,
    validateConfirmPassword,
    validateLuggage,
    addressValidators: {
      street: validateAddressStreet,
      number: validateAddressNumber,
      addition: validateAddressAddition,
      postal_code: validateAddressPostalCode,
      city: validateAddressCity,
      country: validateAddressCountry,
      description: validateAddressDescription
    }
  }
};
