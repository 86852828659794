import ENV from "../../utils/environments";
import Fetcher from "../../utils/fetcher";
import {Flow} from "../channel/channel.types";
import {FlightSearch} from "./types/api/liveFlight";
import {APIReservation, Journey} from "./types/api/reservation";

class FlightService {
  static async getFlight({flight, date, flow}: { flight: string, date: string, flow: Flow }): Promise<FlightSearch> {
    const serviceability = flow === Flow.Airport;

    return Fetcher(`${ENV.API_URL}/flight/schedules/live/${flight}/?departure_date=${date}&serviceability=${serviceability}`, 'GET')
      .then((response) => response.data);
  }

	static createJourney(search: FlightSearch): Journey {
		return {
			departure: {
				designator: search.number,
				codeshares: search.codeshares,
				carrier: {
					name: search.airline.name,
					codes: search.airline.codes
				},
				schedule: {
					datetime: search.schedules[0].departure_datetime,
					datetime_local: search.schedules[0].departure_datetime_local,
					airport: {
						location_id: search.schedules[0].route.departure_airport.id,
						name: search.schedules[0].route.departure_airport.name,
						services: {
							injection: search.schedules[0].route.departure_airport.serviceable,
							meeting: search.schedules[0].route.departure_airport.serviceable,
							belt: search.schedules[0].route.departure_airport.serviceable,
						},
						codes: search.schedules[0].route.departure_airport.codes,
						latitude: search.schedules[0].route.departure_airport.latitude,
						longitude: search.schedules[0].route.departure_airport.longitude,
						timezone: search.schedules[0].route.departure_airport.timezone,
						city: search.schedules[0].route.departure_airport.city,
						region: search.schedules[0].route.departure_airport.region,
						country: search.schedules[0].route.departure_airport.country,
					}
				}
			},
			arrival: {
				designator: search.number,
				codeshares: search.codeshares,
				carrier: {
					name: search.airline.name,
					codes: search.airline.codes
				},
				schedule: {
					datetime: search.schedules[0].arrival_datetime,
					datetime_local: search.schedules[0].arrival_datetime_local,
					airport: {
						location_id: search.schedules[0].route.arrival_airport.id,
						name: search.schedules[0].route.arrival_airport.name,
						services: {
							injection: search.schedules[0].route.arrival_airport.serviceable,
							meeting: search.schedules[0].route.arrival_airport.serviceable,
							belt: search.schedules[0].route.arrival_airport.serviceable,
						},
						codes: search.schedules[0].route.arrival_airport.codes,
						latitude: search.schedules[0].route.arrival_airport.latitude,
						longitude: search.schedules[0].route.arrival_airport.longitude,
						timezone: search.schedules[0].route.arrival_airport.timezone,
						city: search.schedules[0].route.arrival_airport.city,
						region: search.schedules[0].route.arrival_airport.region,
						country: search.schedules[0].route.arrival_airport.country,
					}
				}
			}
		}
	}

	static async getFlightInfo(
    airline: string,
    pnr: string,
    lastName: string,
    mock: boolean = false,
    recaptcha?: string,
    recaptchaVersion?: number
): Promise<APIReservation> {
    const queryParams = [
        `mock=${mock ? "true" : "false"}`,
        `pnr=${pnr}`,
        `last_name=${lastName}`
    ];

    if (recaptcha) queryParams.push(`recaptcha=${recaptchaVersion?.toString()},${recaptcha}`);

    const queryString = queryParams.join('&');
    const url = `${ENV.API_URL}/flight/airlines/${airline}/reservation/?${queryString}`;

    return Fetcher(url, 'GET').then((response) => response.data);
}
}

export default FlightService;
