import TagManager from 'react-gtm-module';

class GoogleTagManager {
  public static initialize(code: string): void {
    TagManager.initialize({gtmId: code})
  }

  public static clearECommerce() {
    TagManager.dataLayer({dataLayer: {ecommerce: null}})
  }

  public static event(name: string, data: object): void {
    TagManager.dataLayer({
      dataLayer: {
        event: name,
        ...data
      }
    })
  }
}

export default GoogleTagManager;