import React, { useMemo } from "react";
import useTranslations from "../../../../../hooks/useTranslations";
import style from "./FlightStepCompleted.module.scss";
import CompleteStep from "../../../../../components/CompleteStep/CompleteStep";
import {StepInfoProps} from "../../../../types";
import {Flow} from "../../../../../store/channel/channel.types";
import { DateTime } from "luxon";
import { FlightSearch } from "store/flight/types/api/liveFlight";

export interface Props extends StepInfoProps {
  onEdit: () => void;
  data: {
    flow: Required<Flow>,
    search: Required<FlightSearch>
		disableEdit: boolean;
  }
}

const FlightStepCompleted: React.FC<Props> = ({onEdit, data}) => {
  const {translation} = useTranslations();

  const departure_date = useMemo(() => {
    return DateTime.fromJSDate(new Date(data.search.schedules[0].departure_datetime_local)).toLocaleString({
      dateStyle: "short",
      timeZone: data.search.schedules[0].route.departure_airport.timezone
    })
  }, [data])

  return (
    <CompleteStep
      id="flight-edit"
      onEdit={onEdit}
			disableEdit={data.disableEdit}
      headingText={translation.get("flight:completed_heading", {
        designator: data.search.number,
        departure_date,
        carrier: data.search.airline.name,
        departure_airport: data.search.schedules[0].route.departure_airport.name
      })}
      className={style.container}
    />
  );
};

export default FlightStepCompleted;
