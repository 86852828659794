import React, {useLayoutEffect, useState} from 'react';
import {PropsWithChildren} from "react";
import {Loader, LoaderOptions} from '@googlemaps/js-api-loader';
import {Location} from "../../store/booking/bookingTypes";

export type LocationMarker = google.maps.Marker & {
  location: Location;
  info: google.maps.InfoWindow
}

type MarkerConstructor = typeof google.maps.Marker

interface Marker extends MarkerConstructor {
  new(opts?: google.maps.MarkerOptions | null | undefined): LocationMarker;
}

interface Props {
  loader?: Loader;
  google?: typeof google;
  options: google.maps.MapOptions;
  Marker?: Marker;
}

const GoogleContextProvider = React.createContext({} as Props);

interface ProviderProps {
  options: LoaderOptions & { mapOptions: google.maps.MapOptions }
}

export const GoogleMapsProvider: React.FC<PropsWithChildren<ProviderProps>> = ({options, children}) => {
  /**
   * Make the Google Maps api and custom Markers available via a context provider.
   */
  // const loader = useMemo(() => new Loader(options), [options]);
  const [loader, setLoader] = useState<Loader>();
  const [api, setApi] = useState<typeof google>();
  const [marker, setMarker] = useState<Marker>();

  useLayoutEffect(() => {
    setLoader((loader) => {
      if (loader !== undefined) {

        (loader as any).reset();
        delete (window as Partial<typeof window>).google;
        (Loader as any).instance = null;
      }

      return new Loader(options);
    })
  }, [options])

  useLayoutEffect(() => {
    /**
     * Load the Google Maps API and enrich the Google Maps Marker object with a definable
     * location object.
     *
     * Since the Google Maps API is not available before context is loaded, we are not able
     * to extend the base google.maps.Marker in a standard class.
     * In order to resolve this problem, we dynamically inject the property using
     * Object.defineProperty and override the Marker type.
     */
    if (loader === undefined) return;

    loader
      .load()
      .then(setApi)
      .then(() => setMarker(() => {
        let marker = google.maps.Marker;
        Object.defineProperty(marker.prototype, 'location', {
          get: function (): Location {
            return this.get('_location')
          },
          set: function (location: Location) {
            this.set('_location', location)
          },
          configurable: true
        })

        Object.defineProperty(marker.prototype, 'info', {
          get: function (): google.maps.InfoWindow {
            return this.get('_info');
          },
          set: function (info: google.maps.InfoWindow) {
            this.set('_info', info)
          },
          configurable: true
        })

        return marker as Marker
      }))
  }, [loader])

  return <GoogleContextProvider.Provider
    value={{
      options: options.mapOptions,
      google: api,
      Marker: marker,
      loader,
    }}>
    {children}
  </GoogleContextProvider.Provider>
}

const useGoogleMaps = () => {
  return React.useContext(GoogleContextProvider)
}

export default useGoogleMaps;