import { BookingState } from "store/booking/types/store";
import useTranslations from "../../../hooks/useTranslations";
import {IChannelTranslationsState} from "../../../store/channel/channel.types";
import sharedStyles from "../../../styles/Shared.module.scss";


const BookingDetails = ({service, booking}: {service: 'pickup'|'dropoff', booking: BookingState}): JSX.Element => {
  /**
   * Showing details of the booking (booking number, luggage count) on the summary page
   */
  const {translation} = useTranslations();
  const luggageCount = booking.details?.assignments && booking.details?.assignments[0].luggage_count
  return (
    <>
      <h2 className={sharedStyles.cardText}>{translation.get('thank_you:booking_number', {bookingNumber: booking.details?.code || ""})}</h2>
      <br/>
      <h2 className={sharedStyles.cardText}>
				{translation.get(`thank_you:${service}:title:active` as keyof IChannelTranslationsState, {luggage: luggageCount?.toString() || ""})} 
			</h2>
      <br/>
    </>
  )
}

export default BookingDetails;