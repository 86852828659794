import React, {useCallback, useRef} from 'react';
import CaretIcon from "../../../icons/CaretIcon";
import sharedStyles from "../../Shared.module.scss";
import styles from "./LabeledDropdown.module.scss";

import Icon from '../../../icons/Icon';
import {GenericInput} from "../../types";
import LabeledContainer from "../../../containers/LabeledContainer";
import BaseDropdown, {DropdownItem} from '../BaseDropdown';

interface Props extends GenericInput<DropdownItem<any>> {
  items: DropdownItem<any>[];
  caretIcon?: React.ReactElement<typeof Icon>;
  disabled: boolean;
  onChange: (item: DropdownItem<any>) => any;
  error?: string;
  "data-cy"?: string
}

const LabeledDropdown: React.FC<Props> = (
  {
    items,
    value,
    icon,
    label,
    labelContainerClassName = '',
    containerClassName = '',
    onChange,
    caretIcon= CaretIcon,
    disabled = false,
    error,
    'data-cy': dataCy,
  }
) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
	
  const onSelect = useCallback((item: DropdownItem<number>) => onChange(item), [onChange])

  const onOutsideClick = useCallback(()=>{

    /**
     * Forward clicks on the input container to the dropdown element.
     */
    if(!dropdownRef || dropdownRef.current == null) return;
    dropdownRef.current?.click();
  }, [dropdownRef])

  const Dropdown = <BaseDropdown
    data-cy={dataCy}
    ref={dropdownRef}
    className={styles.dropdown}
    items={items || []}
    value={value && value.label}
    onSelect={onSelect}
  />

  if (label) return (
    <LabeledContainer
      onClick={onOutsideClick}
      label={label}
      icon={icon}
      caret={caretIcon}
      className={`${disabled && styles.disabled} ${labelContainerClassName}`}
      inputContainerClassName={`${styles.container} ${containerClassName}`}
      error={error}
    >
      {Dropdown}
    </LabeledContainer>
  )

  return (<div
    className={`${sharedStyles.container} ${containerClassName || ""} ${styles.container || ""}`}>
    {Dropdown}
  </div>);
}

export default LabeledDropdown;
