import React from "react";
import styles from "./TenantInformation.module.scss";
import Icon from "../../../components/icons/Icon/Icon";
import {ITenant} from "../../../store/portal/portalTypes";

interface TenantInformationProps {
  icon: string;
  tenant: ITenant;
}

const TenantInformation = ({icon, tenant}: TenantInformationProps) => {
  const {name, company} = tenant;
  const {address, email, phone_number, vat_number} = company || {};

  return (
    <div className={styles.component}>
      <Icon className={styles.tenantIcon} src={icon}/>
      <div className={styles.message}>
        <div>{name}</div>
        {address && (
          <div>
            {address.street && address.street}, {address.number && address.number},
            {address.postal_code && address.postal_code},
            {address.city && address.city}, {address.country && address.country}
          </div>
        )}
        <div>
          {email && <span>{email}</span>}
          {email && phone_number && <span>, </span>}
          {phone_number && <span>{phone_number}</span>}
          {(email || phone_number) && vat_number && <span>, </span>}
          {vat_number && <span>{vat_number}</span>}
        </div>
      </div>
    </div>
  );
}

export default TenantInformation;