import React, {FunctionComponent} from 'react';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';
import LanguageDropdown from "../../inputs/dropdowns/LanguageDropdown";

export interface ModalProps {
  isShown: boolean;
  hide?: () => void;
  modalContent: JSX.Element;
  headerText: string;
  className?: string;
  contentContainerClassName?: string;
}

const Modal: FunctionComponent<ModalProps> = (
  {
    isShown,
    hide,
    modalContent,
    headerText,
    className, 
    contentContainerClassName
  }) => {

  const modal = (
    <React.Fragment>
      <div className={styles.backdrop}/>
      <div className={`${styles.wrapper} ${className}`}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <div className={styles.headerText}>{headerText}</div>
            {hide ? <button className={styles.closeButton} id="close-button" onClick={hide}>X</button> :
              <LanguageDropdown listClass={styles.languageDropdownList} />}
          </div>
          <div className={`${styles.content} ${contentContainerClassName || ""}`}>{modalContent}</div>
        </div>
      </div>
    </React.Fragment>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export default Modal;
export {Modal}