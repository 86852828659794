import ENV from "../../utils/environments";
import {
  BudgetResponse,
  BookingResponse,
  Location,
  BookingRequestBody,
  BookingRequest,
  Zone,
	SpecificAddressParams
} from "./bookingTypes";
import AbstractService from "../service";
import Fetcher from "../../utils/fetcher";
import {AxiosResponse} from "axios";
import PaginatedResponse from "../paginatedResponse";
import {Passenger} from "./types/api/booking";

class BookingService extends AbstractService {

  static async getZones(url = `${ENV.API_URL}/zones/`): Promise<Required<Zone>[]> {
    const response = await Fetcher(url, 'GET').then(response => response.data);
    let zones = response.results;
  
    if (response.next) {
      const nextZones = await this.getZones(response.next);
      zones = zones.concat(nextZones);
    }
  
    return zones;
  }

  static getLocation(locationId: number) {
    return Fetcher(`${ENV.API_URL}/locations/${locationId}/`, 'GET');
  }

  static saveLocation(address: Location): Promise<AxiosResponse<Required<Location>>> {
    return Fetcher(`${ENV.API_URL}/locations/`, 'POST', address);
  }

  static getHubLocations(country: string): Promise<PaginatedResponse<Required<Location>>> {
    return Fetcher(`${ENV.API_URL}/locations/`, 'GET', {}, {
      type: 'bagpoint',
      country
    }).then((response) => response.data);
  }

  static createOrUpdateBooking({data}: { data: BookingRequestBody }): Promise<BookingResponse>;
  static createOrUpdateBooking({code, data}: { code: string; data: BookingRequestBody }): Promise<BookingResponse>;
  static createOrUpdateBooking({code, data}: BookingRequest): Promise<BookingResponse> {
    const url = code ? `${ENV.API_URL}/bookings/${code}/` : `${ENV.API_URL}/bookings/`;
    const method = code ? 'PUT' : 'POST';

    return Fetcher(url, method, data).then((response) => response.data);
  }

  static async updatePassengers(bookingCode: string, passengers: Passenger[]): Promise<BookingResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/`, 'PUT', {passengers}).then((response) => response.data);
  }

  static getBudget(bookingCode: string): Promise<BudgetResponse> {
    return this.awaitStatus(bookingCode, 'booking', ['STATUS.PROGRESS.001', 'STATUS.EVENT.004'])
      .then((): Promise<BudgetResponse> => Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/budget/`, 'GET').then((response) => response.data))
  }

  static getBookingStatus(bookingCode: string): Promise<BookingResponse> {
    return Fetcher(`${ENV.API_URL}/bookings/${bookingCode}/`, 'GET').then((response) => response.data);
  }

  static setFavoriteLocation(id: number, favourite: boolean): Promise<any> {
    return Fetcher(`${ENV.API_URL}/locations/${id}/`, 'PATCH', {favourite}).then((response) => response);
  }

  static getCountrySpecificAddress({country_code, language, short_address, latitude, longitude, address_string, page}: SpecificAddressParams): Promise<any>  {
    const acceptedLanguages = ['en', 'ar'];
			return Fetcher(`${ENV.API_URL}/transport/locations/${country_code.toLowerCase()}/`, 'GET', {}, {
							short_address,
							latitude,
							longitude,
							page,
							language: acceptedLanguages.includes(language) ? language : 'en',
							address_string
					})
					.then((response) => response)
	}
}

export default BookingService;
