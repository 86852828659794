import { createAction, createAsyncThunk, createReducer, Reducer } from "@reduxjs/toolkit";
import PaymentService from "store/payment/paymentService";
import {
  CLEAR_BOOKING,
  SET_BOOKING,
  SAVE_INVOICE_DATA,
  SET_BOOKING_PAYMENT,
  SET_DESCRIPTION,
  PARTIAL_RESET_BOOKING,
  RESET_BOOKING,
  SET_MEETING_ADDRESS,
  UPDATE_PROGRESS,
  SET_FAVOURITE_LOCATION,
  SET_ADDRESSES,
  SELECT_ADDRESS,
  SET_SEARCH_TERM,
  RESET_SA_ADDRESS,
	GO_TO_STEP
} from "./bookingConstants";
import BookingService from "./bookingService";
import { SelectableAddress, Location, BookingCreate, BookingResponse, BookingMeta, BookingRequest, Job, BookingCustomerUpdate, BookingLocationUpdate, BudgetResponse } from "./bookingTypes";
import { BookingState, Payment } from "./types/store";
import { Flow, OffsetRules, Steps } from "store/channel/channel.types";
import { DateTime } from "luxon";
import { AppDispatch, RootState } from "store/store";

type BookingCallback = (booking: BookingResponse) => void;

const initialState: BookingState = {
  details: undefined,
  budget: undefined,
  invoice: undefined,
  flow: {
    progress: 1
  },
  payment: undefined,
  meeting: undefined,
  saAddress: undefined
};

async function createOrUpdateBooking(
  {
    code,
    flow, 
    data,
    callback,
    offsets
  }: {
    code?: string;
    flow: Flow;
    data: BookingCreate | BookingLocationUpdate | BookingCustomerUpdate;
    callback?: BookingCallback;
    offsets?: {
      [key in Flow]: OffsetRules;
    }
  }
): Promise<{booking: BookingResponse, budget: BudgetResponse}> {
  /**
   * Map the collected flight, location and pickup window data to a consumable API payload and store the result
   * of the latter into the redux store.
   *
   * A booking creation request must include a full BookingCreate object whilst when making an update request,
   * the booking data may be partial and may provide either/or location and luggage count information
   * along with the relevant booking code.
   */

    let payload: BookingRequest = {
      ...(code && { code }),
      data: {
        meta: {}
      }
    }

    const jobs: Job[] = []
    if ("flight" in data) {
      let locations: [
        { location: number, datetime: string },
        { location: number, datetime: string }
      ] = flow === Flow.City ? [
        {
          location: parseInt(data.flight.journey.arrival.schedule.airport.location_id),
          datetime: DateTime
            .fromISO(data.flight.journey.arrival.schedule.datetime_local, { zone: data.flight.journey.arrival.schedule.airport.timezone })
            .plus(offsets?.city.pickup || {})
            .toISO()
        },
        {
          location: data.location,
          datetime: DateTime
            .fromISO(data.datetime, { zone: data.flight.journey.arrival.schedule.airport.timezone })
            .plus(offsets?.city.delivery || {})
            .toISO()
        }
      ] : [
          {
            location: data.location,
            datetime: DateTime
              .fromISO(data.datetime, { zone: data.flight.journey.departure.schedule.airport.timezone }) // Technically incorrect, should use tz of pickup location
              .plus(offsets?.airport.pickup || {})
              .toISO()
          },
          {
            location: parseInt(data.flight.journey.departure.schedule.airport.location_id),
            datetime: DateTime
              .fromISO(data.flight.journey.departure.schedule.datetime_local, { zone: data.flight.journey.departure.schedule.airport.timezone })
              .plus(offsets?.airport.delivery || {})
              .toISO()
          }
        ]

      jobs.push({
        flight: data.flight.journey.departure.designator,
        pnr: data.flight.pnr,
        session: data.flight.session,
        locations: locations,
      })
    }

    payload["data"] = {
      ...payload["data"],
      ...(jobs.length && { jobs }),
      ...(data.meta && { meta: data.meta }),
      ...('passengers' in data ? { passengers: data.passengers } : {}),
    }

    try {
      const booking = await BookingService.createOrUpdateBooking(payload);
      const budget = await BookingService.getBudget(booking.code);

      const remappedBooking = {
        ...booking,
        meta: {
          timeslot: {
            ...booking.meta.timeslot
          }
        } as BookingMeta
      }

      if (callback) callback(remappedBooking);

      return {
        booking: remappedBooking,
        budget
      };

    } catch (e) {
      throw Error("There was an error whilst creating the booking.");
    }
}

export const resetInvoice = createAction(SAVE_INVOICE_DATA);
export const resetSaAddress = createAction(RESET_SA_ADDRESS);
export const setLocationDescription = createAction<string>(SET_DESCRIPTION);
export const partialResetBooking = createAction(PARTIAL_RESET_BOOKING);
export const resetBooking = createAction(RESET_BOOKING);
export const clearBooking = createAction(CLEAR_BOOKING);
export const setFavouriteLocation = createAsyncThunk(SET_FAVOURITE_LOCATION,
  async ({ id, favourite }: { id: number, favourite: boolean }) => await BookingService.setFavoriteLocation(id, favourite)
);
export const getInvoice = createAsyncThunk(SAVE_INVOICE_DATA,
  async (bookingCode: string) => await PaymentService.getInvoice(bookingCode)
);
export const setBookingPayment = createAction<Payment | undefined>(SET_BOOKING_PAYMENT);
export const selectAddress = createAction<SelectableAddress>(SELECT_ADDRESS);
export const setSearchTerm = createAction<string | undefined>(SET_SEARCH_TERM);
export const setMeetingLocation = createAsyncThunk(SET_MEETING_ADDRESS,
  async (location: Location) => {
    if (location.id === undefined) {
      const {data} = await BookingService.saveLocation(location);

      return data as Required<Location>;
    }

    return location as Required<Location>;
  }
);
export const updateProgress = createAction<number>(UPDATE_PROGRESS);
export const setSelectableAddresses = createAction<SelectableAddress[] | undefined>(SET_ADDRESSES);
export const setBooking = createAsyncThunk(SET_BOOKING, 
  async ({ code, data, flow, offsets, callback }
    :
    { code?: string,
      data: BookingCreate | BookingLocationUpdate | BookingCustomerUpdate,
      flow: Flow,
      callback?: BookingCallback,
      offsets?: {
        [key in Flow]: OffsetRules;
      }
    }
  ) => await createOrUpdateBooking({ code, data, flow, callback, offsets })
)
export const goToStep = createAsyncThunk<void, {stepName: Steps, currentFlow: string}, { dispatch: AppDispatch }>(
  GO_TO_STEP,
  async ({ stepName, currentFlow }, { dispatch, getState }) => {
    const state = getState() as RootState;
    const channelFlows = state.channel.properties.flows;
    let stepIndex = -1;

    // Find the index of the step in the provided flow
    if (channelFlows[currentFlow]) {
      stepIndex = channelFlows[currentFlow].components.findIndex((step: Steps) => step === stepName);
    }

    if (stepIndex !== -1) {
      dispatch(updateProgress(stepIndex + 1));
    }
  }
);

const booking: Reducer<BookingState> = createReducer(initialState, builder => {
  builder.addCase(setBooking.fulfilled, (state, action) => {
    const { booking, budget } = action.payload;
    state.budget = budget;
    state.details = booking;
  })
  builder.addCase(resetInvoice, (state, action) => {
    state.invoice = action.payload;
  })
  builder.addCase(updateProgress, (state, action) => {
    state.flow.progress = action.payload;
  })
  builder.addCase(setBookingPayment, (state, action) => {
    state.payment = action.payload;
  })
  builder.addCase(setMeetingLocation.fulfilled, (state, action) => {
    if(!action.payload) return state;

    state.meeting = {
      ...state.meeting,
      location: action.payload
    }
  })
  builder.addCase(setLocationDescription, (state, action) => {
    if(state.meeting?.location === undefined) return state;

    state.meeting.location.description = action.payload;
  })
  builder.addCase(setFavouriteLocation.fulfilled, (state, action) => {
    state.meeting = {
      ...state.meeting,
      location: action.payload
    }
  })
  builder.addCase(setSelectableAddresses, (state, action) => {
    if (state.saAddress === undefined) return state;

    state.saAddress.selectable = action.payload;
  })
  builder.addCase(setSearchTerm, (state, action) => {
    if (state.saAddress === undefined) return state;

    state.saAddress.searchTerm = action.payload;
  })
  builder.addCase(resetSaAddress, (state) => {
    if (state.saAddress === undefined) return state;

    state.saAddress.selected = undefined;
    state.saAddress.selectable = undefined;
  })
  builder.addCase(selectAddress, (state, action) => {
    if (state.saAddress === undefined) return state;

    state.saAddress.selected = action.payload;
  })
  builder.addCase(partialResetBooking, (state) => {
    if (state.details === undefined) return state;

    state.details.meta = undefined;
    state.details.assignments = [];
    state.budget = undefined;
    state.invoice = undefined;

  })
  builder.addCase(resetBooking, (state) => {
    state.details = undefined
    state.budget = undefined
    state.invoice = undefined
    state.payment = undefined
    state.meeting = undefined
  })
  builder.addCase(clearBooking, () => initialState)
});

export default booking;
