import React, {memo, useCallback, useEffect, useState,} from "react";
import "react-datepicker/dist/react-datepicker.css";
import useTranslations from "../../../../../hooks/useTranslations";
import SingleInput from "components/inputs/SingleInput/SingleInput";
import Label from "../../../../../components/containers/LabeledContainer/Label/Label";
import styles from "./FlightNumber.module.scss";
import Utils from "../../../../../utils/Utils";
import ValidationError from "../../../../../components/ValidationError";
import {useAppSelector} from "../../../../../store";

export interface FlightNumberProps {
  onChange: (otp: string) => any;
  inputs: Array<string>;
  autoFocus?: boolean;
  flightNumber: string;
	placeholder?: string;
}

const FlightNumber = ({onChange, autoFocus, inputs, flightNumber, placeholder}: FlightNumberProps) => {
  const {translation} = useTranslations();

  const properties = useAppSelector((state) => state.channel.properties);
  const [activeInput, setActiveInput] = useState<number>(0);
  const [values, setValues] = useState<Array<string>>(flightNumber.split('').length ? Utils.string.formatFlightNumber(flightNumber).split('') : ['', '', '', '', '', '']);
  const [error, setError] = useState<string>('');

  const handleChange = useCallback(
    (char: string[]) => {
      const value = char.join('');
      onChange(value);
    },
    [onChange],
  );

  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedValues = [...values];
      updatedValues[activeInput] = str[0] || '';
      setValues(updatedValues);
      handleChange(updatedValues);
    },
    [activeInput, handleChange, values],
  );

  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(inputs.length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [inputs],
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput],
  );

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      if (!target?.value) {
        e.preventDefault();
        return;
      }

      changeCodeAtFocus(target.value.toUpperCase());
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput],
  );

	const handleOnPaste = useCallback(
		(e: React.ClipboardEvent<HTMLInputElement>) => {
			e.preventDefault();
			const pastedData = e.clipboardData.getData('text');
			
			if (pastedData.length > 0) {
				const updatedValues = [...values];
				let nextIndex = activeInput;
				for (let char of pastedData) {
					if (nextIndex < inputs.length) {
						updatedValues[nextIndex] = char.toUpperCase();
						nextIndex++;
					} else {
						break;
					}
				}
				setValues(updatedValues);
				handleChange(updatedValues);
				focusInput(Math.min(nextIndex, inputs.length - 1));
			}
		},
		[activeInput, focusInput, handleChange, inputs.length, values]
	);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'Backspace':
        case 'Delete': {
          e.preventDefault();
          if (values[activeInput]) {
            changeCodeAtFocus('');
          } else {
            focusPrevInput();
          }
          break;
        }
        case 'ArrowLeft': {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case ' ': {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, values],
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const onComponentBlur = (e: React.FocusEvent) => {
    /**
     * Check flightNumber and add leading 0s in case of a shorter flightNumber
     */
    const divHasFocus = (e?.relatedTarget as any)?.dataset?.customproperty === "true";
    const input = values.join('').trim();
    if (divHasFocus || input.length < 3) return;
    const formattedFlightNumber = Utils.string.formatFlightNumber(input);
    setValues(() => formattedFlightNumber.split(''));
    onChange(formattedFlightNumber);
    setError(() => '');
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const flightNumber = urlParams.get('flightNumber');

    if (flightNumber) setValues(() => flightNumber.split(''));
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles["flight-number-container"]}>
        <Label text={translation.get("flight:number:label_flight-number")}/>
        <div className={styles["flight-input-codes-wrapper"]} onBlur={(e) => onComponentBlur(e)}>
          {inputs.map((item: string, index: number) => (
            <SingleInput
							placeholder={placeholder ? placeholder.charAt(index) : ""}
              customProperty={true}
							onPaste={handleOnPaste}
              properties={properties}
              id={`SingleInput-${index}`}
              key={`SingleInput-${index}`}
              type={item}
              focus={activeInput === index}
              value={values && values[index]}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onBlur={onBlur}
            />
          ))}
        </div>
        {error && (
          <ValidationError error={error}/>
        )}
      </div>
    </div>
  );
}

export default memo(FlightNumber);