import StepError from "../../../pages/BookingFlow/steps/StepError";
import { StepInfoProps } from "../../../pages/types";
import React, { useCallback, useEffect, useImperativeHandle, useState, useRef, RefObject } from "react";
import { useAppDispatch } from "../../../store";
import GoogleTagManager from "../../../utils/GoogleTagManager";
import StepNumber from "../../StepNumber";
import Spinner from "../../Spinner";
import MotionDiv from "../MotionDiv";
import Incomplete from "./Incomplete";
import {updateProgress} from "../../../store/booking/bookingReducer";
import Utils from "../../../utils/Utils";
import { ActiveStepProps, CompleteStepProps, Errors } from "./types";
import useBookingMeta from "../../../hooks/useBookingMeta";
import styles from "./Step.module.scss";

interface AbstractStepInfoProps<A, C> extends StepInfoProps {
  Active: React.ComponentType<A>;
  Completed?: React.ComponentType<C>;
  header: string;
  onEnter?: () => any;
  onExit?: () => any;
  onUpdate?: (...data: any[]) => Promise<any>;
  onEdit?: (number: number) => Promise<any>;
  onSubmit?: (...data: any[]) => Promise<any>;
  onError?: (error: Error) => Errors;
  ready?: boolean;
	errRef?: RefObject<(error: Error) => Errors>;
  props?: {
    Active?: Omit<A, 'onSubmit' | 'isReady' | 'onUpdate' | 'errors'>
    Completed?: Omit<C, 'onEdit'>
  }
}

const Step = <A extends ActiveStepProps, C extends CompleteStepProps>(
  {
    header,
    Active,
    Completed,
    onUpdate,
    onEdit,
    onSubmit,
    onEnter,
    onExit,
    onError,
    ready = true,
    props,
		errRef,
  }: AbstractStepInfoProps<A, C>,
  ref: React.ForwardedRef<A["onSubmit"]>
) => {
  /**
   * An abstract step container intended to be used when defining concrete steps in the webfunnel.
   *
   * This Component requires the following properties:
   *
   * Incomplete:
   *    A React component displayed whenever the content has not yet been reached.
   * Active:
   *    The content to show whenever the currently active step number matches the number of the given step.
   * Complete:
   *    The content to show whenever the step has been submitted.
   */

  const dispatch = useAppDispatch();
  const { step, stepName, progress, flow } = useBookingMeta();
  const [, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({})

	const onStepError = useCallback((e: Error) => {
    /**
     * Catch and handle errors
     */

    GoogleTagManager.event("stepError", {
      data: {
        exception: e.name
      }
    })

    setErrors(() => {
      if (onError) return onError(e)
      if (e instanceof StepError) return e.fields

      return {
        message: e.message
      }
    })
  }, [onError])

  const onStepEnter = useCallback(() => {
    GoogleTagManager.event("stepEnter", {
      name: stepName,
      flow,
    })

		if (typeof onEnter !== 'function') return;
		
		const onEnterResult = onEnter();
    if (onEnterResult instanceof Promise) onEnterResult.catch(onStepError);
  }, [stepName, flow, onEnter, onStepError])

  useEffect(() => {
    /**
     * Handle on enter and on exit hooks.
     */

    setActive((active) => {
      if (step === progress && !active) {
        onStepEnter();
        if (onEnter) onEnter();

        return true;
      }

      if (step !== progress && active && onExit) onExit();

      return false;
    });
  }, [step, progress, onEnter, onExit, onStepEnter, flow])

  const onStepSubmit = useCallback((data: object) => {
    /**
     * Run the onSubmit hook before moving to the next step.
     */
    let nextStep = step + 1;

    GoogleTagManager.event('stepSubmit', {
      flow,
    })
    if (!onSubmit) return dispatch(updateProgress(nextStep));

    setLoading(true);
    return onSubmit(data)
      .then((_) => {
        setErrors({});

        return dispatch(updateProgress(nextStep));
      })
      .catch(e => onStepError(e))
      .finally(() => setLoading(false));
  }, [step, onSubmit, dispatch, flow, onStepError])

  /**
   * Allow the step to be submitted from outside the component.
   */
  const submitRef = useRef<A["onSubmit"]>(onStepSubmit)
  useImperativeHandle(ref, () => submitRef.current!, [submitRef])

	const errorRef = useRef<any>(onStepError)
  useImperativeHandle(errRef, () => errorRef.current!, [errorRef])

  const onStepUpdate = useCallback((data: object) => {
    /**
     * Similar to onStepSubmit however it does not go to the next step afterwards
     */
    if (!onUpdate) throw Error("Tried to update on a step without an update method.");

    setLoading(true);
    return onUpdate(data)
      .then((_) => {
        setLoading(false);
        return _;
      })
      .catch(e => onStepError(e))
      .finally(() => setLoading(false));
  }, [onUpdate, onStepError])

  const onStepEdit = useCallback(() => {
    /**
     * Run the onEdit hook before moving back to associated step.
     */
    if (!onEdit) return dispatch(updateProgress(step));

    onEdit(step).then(() => dispatch(updateProgress(step)));
  }, [step, onEdit, dispatch])

  return (
    <div id={`step-${step}`} className={styles.wrapper}>
      <StepNumber number={step} />
      <div className={`${styles.container}`}>
        {progress < step && <Incomplete heading={header} />}
        {progress === step &&
          <MotionDiv
            isComplete={Utils.layout.scrollTo}
            child={React.createElement(Active, {
              onSubmit: onStepSubmit,
              onUpdate: onStepUpdate,
              errors,
              isReady: true, ...props?.Active
            } as A)}
          />}
        {progress > step && Completed && React.createElement(Completed, { onEdit: onStepEdit, ...props?.Completed } as C)}
        {progress === step && loading && <Spinner className={styles.spinner} />}
      </div>
    </div>
  )
}

export default React.forwardRef(Step);