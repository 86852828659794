import tinycolor from "tinycolor2";
import React, {useLayoutEffect, useState} from "react";
import store from "../../store";
import {Unsubscribe} from "redux";

class InputStyles {
  private static subscriptions: ((inlines: React.CSSProperties) => void)[] = [];
  private static storeSubscription?: Unsubscribe;
  private static styles?: React.CSSProperties;

  private static dispatch(callback?: (inlines: React.CSSProperties) => void) {
    /**
     * Retrieve inlines styles from store and provide it back to the callback
     */
    // If a callback is defined then this was initiated by a new subscription; in which case forward the stored styles
    if (callback && InputStyles.styles) {
      return callback(InputStyles.styles);
    }

    const state = store.getState();
    InputStyles.styles = {
      backgroundColor: state.channel.properties.whitelabel.colors["input:background"]
        || state.channel.properties.whitelabel.colors["background"],
      ...['iPad', 'iPhone', 'iPod'].includes(navigator.platform) && {padding: '1px'},
      color: (() => {
        const background = state.channel.properties.whitelabel.colors["input:background"]
          || state.channel.properties.whitelabel.colors["background"];

        if (tinycolor(background).isDark())
          return state.channel.properties.whitelabel.colors["input:text:light"] || state.channel.properties.whitelabel.colors["text:light"]

        return state.channel.properties.whitelabel.colors["input:text:dark"] || state.channel.properties.whitelabel.colors["text:dark"]
      })()
    }

    for (let subscription of InputStyles.subscriptions) subscription(InputStyles.styles);
  }

  public static subscribe(callback: (inlines: React.CSSProperties) => void) {
    InputStyles.subscriptions.push(callback);
    if (!InputStyles.storeSubscription) {
      InputStyles.storeSubscription = store.subscribe(InputStyles.dispatch)
    }
    InputStyles.dispatch();
  }

  public static unsubscribe(callback: (inlines: React.CSSProperties) => void) {
    InputStyles.subscriptions = InputStyles.subscriptions.filter((subscription) => subscription !== callback)

    if (!InputStyles.subscriptions.length && InputStyles.storeSubscription) {
      InputStyles.storeSubscription();
    }
  }
}

const useInputStyles = (value?: React.CSSProperties) => {
  const [initiated, setInitiated] = useState<boolean>(false)
  const [style, setStyle] = useState<React.CSSProperties>(value || {})

  useLayoutEffect(() => {
    if(initiated) return;

    const onStoreUpdate = (inlines: React.CSSProperties) => {
      setStyle((style) => {
        return {...inlines, ...(value || {})}
      });
    }

    setInitiated(true);
    InputStyles.subscribe(onStoreUpdate);
    return (() => {
      InputStyles.unsubscribe(onStoreUpdate);
    })
  }, [value, initiated])

  return style || {};
}


export default useInputStyles;