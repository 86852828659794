import { LoaderOptions } from "@googlemaps/js-api-loader";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MapSelectorInlines from "components/forms/MapAddressSelector/MapSelector.inlines";
import { LogisticWindow } from "components/forms/WindowSelector/types";
import { GoogleMapsProvider } from "hooks/useGoogleMaps/useGoogleMaps";
import useTranslations from "hooks/useTranslations";
import {
  setBooking,
  getInvoice,
  partialResetBooking,
  resetInvoice,
  setMeetingLocation,
	goToStep
} from "store/booking/bookingReducer";
import BookingService from "store/booking/bookingService";
import { BookingState } from "store/booking/types/store";
import { Flow, Steps } from "store/channel/channel.types";
import { clearPaymentData } from "store/payment/paymentReducer";
import ENV from "utils/environments";
import Step from "components/containers/Step";
import { useAppDispatch, useAppSelector } from "store";
import { useProperties, useBookingMeta, useOptions } from "hooks";
import { FlightState } from "store/flight/types/store";
import PaymentService from "store/payment/paymentService";
import NeomLocationStepActive from './NeomLocationStepActive'
import NeomLocationStepComplete from './NeomLocationStepComplete'
import {BookingMeta, BookingRequestPassenger, Location} from "store/booking/bookingTypes";
import {AirportDirection} from "store/flight/types/enums";
import { sha3_512 } from 'js-sha3';
import { StepInfoProps } from "pages/types";


export interface LocationStepErrors {
  location?: string,
  booking?: string,
}

const NeomLocationStep: React.FC<StepInfoProps> = () => {
  /**
   * This is a controller for the location step.
   *
   * The location step is intended to collect information regarding the
   * booking's location and pickup/delivery time.
   *
   * It is the LocationStep controller's role to dispatch the collected information
   * to the store and the API.
   *
   * The callbacks receive data from the Active step component which is in charge of collecting
   * and validating the customer's information before submission.
   */

  const dispatch = useAppDispatch();
  const {locale, language, properties} = useProperties();
  const {translation} = useTranslations();
  const {direction, loading, flow} = useBookingMeta();
	const {options: timeSlotOptions} = useOptions();

  const flight = useAppSelector((state) => state.flight as Required<FlightState>);
  const membership = useAppSelector((state) => state.membership);
  const booking = useAppSelector((state) => state.booking as Required<BookingState> & { details: { meta: Required<BookingMeta> } });
  const terms = useAppSelector(state => state.channel.properties.terms);
	const content = useAppSelector((state) => state.channel.properties.content);
  const [hubs, setHubs] = useState<Required<Location>[]>();
  const [error, setError] = useState<LocationStepErrors>({});

	const verification = useAppSelector(state => state.payment.verification)
	const verified = useMemo(() => {
		return verification?.status === "STATUS.PROGRESS.010"
	}, [verification])

  const onEnter = useCallback(() => {
    /**
     * Retrieve available hubs for the given flight leg.
     */
    if (flight.journey === undefined) return;

    // If no timeslot options are available, redirect back to first step
    if (Object.keys(timeSlotOptions).length === 0) {
      // according to the flow, find the step which should be redirected to
      const step = flow === Flow.City ? Steps.FLIGHT : Steps.PNR_FLIGHT
      dispatch(goToStep({stepName: step, currentFlow: flow}))
    }

    BookingService
      .getHubLocations(flight.journey[direction].schedule.airport.country)
      .then((response) => setHubs(response.results));
  }, [flight.journey, flow, direction, timeSlotOptions, dispatch])

  const options: LoaderOptions & { mapOptions: google.maps.MapOptions } = useMemo(() => ({
    apiKey: ENV.GOOGLE_API_KEY,
    mapOptions: {
      styles: MapSelectorInlines,
      disableDefaultUI: true,
      disableDoubleClickZoom: true,
      zoomControl: true,
      clickableIcons: false
    },
    libraries: ["places", "geometry"],
    version: "weekly",
    language: language === 'en' ? "nl" : language
  }), [language])

  const onEdit = useCallback(() => Promise.all([
    /**
     * When returning to the location step, via the edit button,
     * we should clear the invoice and any payment data.
     */

    dispatch(resetInvoice()),
    dispatch(clearPaymentData()),
  ]), [dispatch])

  const onSubmit = useCallback((/*favourite: boolean*/) => {
    /**
     * Create an order for the given booking code.
     */
    if (booking.details?.code === undefined) throw Error("Cannot create order before booking");

    const code = booking.details.code;

    return PaymentService
      .createOrder(code)
      .then(() => dispatch(getInvoice(code)))
  }, [dispatch, booking]);

  const onUpdate = useCallback(async ({ luggage, location, window, reset }: {
    luggage?: number,
    location?: Location,
    window?: LogisticWindow,
    reset?: boolean
  }) => {
    /**
     * Whenever the form data is updated we make a request to the API either to create or update
     * the booking information.
     */

    if (reset) {
      dispatch(partialResetBooking());
      setError({});
      return;
    }

    /**
     * Returning if there is no change to data
     */
    if (
      location?.id && location.id === booking.meeting?.location?.id
      && luggage === booking.details?.assignments[0]?.luggage_count
      && window?.label === booking.details?.meta?.window?.label
      && DateTime.fromISO(booking.details?.meta?.window?.start || "", { zone: flight.journey[direction].schedule.airport.timezone }).toMillis() === window?.value.start.toMillis()
    ) return;

    const code = booking.details?.code;
    const savedLocation = location && await dispatch(setMeetingLocation(location)).unwrap()
      .then((response) => {
        setError({});
        return response;
      })
      .catch((error) => {
        setError({ location: error?.response?.data?.detail[0] || translation.get("error:general_error") });
      });

    if (!savedLocation) return;

    // Handle the different sources of information for passenger data.
    let passengers: BookingRequestPassenger[]
    if (flow === Flow.City && luggage !== undefined) {
      passengers = [{
        items: luggage
      }]
    } else {
      passengers = flight.passengers.filter(passenger => passenger.luggage > 0).map((passenger) => ({
        first_name: passenger.first_name,
        last_name: passenger.last_name,
        items: passenger.luggage,
        passport: sha3_512(passenger.passport)
      }))
    }

    if (luggage && savedLocation && savedLocation.id && window) {
      return dispatch(setBooking({
        ...code && { code },
        flow,
        data: {
          passengers,
          flight,
          location: savedLocation.id,
          datetime: window.value.start.toISO(),
          meta: {
            timeslot: {
              time: window.value.start.toISO(),
              formatted: window.value.start.setLocale(locale).toLocaleString({
                day: "2-digit",
                month: "long",
                year: "numeric"
              }),
              timeLabel: window.label,
            },
            flightNumber: flight.journey.departure.designator,
            window: {
              label: window.label,
              start: window.value.start.toISO(),
              end: window.value.end.toISO()
            },
            ...membership && { membership: membership.code }
          }
        },
        offsets: properties.logistics.offsets,
      })).catch((e) => setError(e.message || translation.get('error:general_error')));
    }

    // throw Error('Cannot update without luggage, location or datetime information');
  }, [
    direction,
    booking.details?.code,
    booking.meeting?.location?.id,
    booking.details?.assignments,
    booking.details?.meta?.window,
    dispatch,
    flight,
    flow,
    locale,
    membership,
    translation,
    properties
  ])

  useEffect(() => {
    if (!error.location) return;

    setTimeout(() => {
      setError({ ...error, location: undefined })
    }, 3000)
  }, [error])

  return (<GoogleMapsProvider options={options}>
    <Step
      header={translation.get(`location:heading:${direction === AirportDirection.Arrival ? 'city' : 'airport'}`)}
      Active={NeomLocationStepActive}
      Completed={NeomLocationStepComplete}
      onEdit={onEdit}
      onSubmit={onSubmit}
      onEnter={onEnter}
      onUpdate={onUpdate}
      props={{
        Active: {
          data: {
            error,
            flight,
            booking,
            flow,
            direction,
						terms,
						content,
            hubs /*favourite: booking?.meeting?.location?.favourite || false*/
          },
          loading: loading || hubs === undefined,
        },
        Completed: { data: { booking, disableEdit: verified } }
      }}
    />
  </GoogleMapsProvider>)
};

export default NeomLocationStep;
