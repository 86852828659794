import ENV from "../../utils/environments";
import AbstractService from "../service";
import {IBooking, ITenant, LocationType} from "./portalTypes";
import {APIStatus} from "../../utils/apiStatus";
import PaginatedResponse from "../paginatedResponse";
import Fetcher from "../../utils/fetcher";
import {Location} from "../booking/bookingTypes";

class PortalService extends AbstractService {
  static async getBookings(status: APIStatus, offset?: number): Promise<PaginatedResponse<IBooking>> {
    return Fetcher(`${ENV.API_URL}/bookings/`, 'GET', {}, {status, offset}).then((response) => response.data);
  }

  static async getLocations(type: LocationType, offset?: number): Promise<PaginatedResponse<Required<Location>>> {
    return Fetcher(`${ENV.API_URL}/locations/`, 'GET', {}, {type, offset, has_booking: true, favourite: true}).then((response) => response.data);
  }

  static async getLocationById(locationId: number): Promise<Required<Location>> {
    return Fetcher(`${ENV.API_URL}/locations/${locationId}/`,'GET').then((response) => response.data);
  }

  static async getTenant(tenantId: number): Promise<ITenant> {
    return Fetcher(`${ENV.API_URL}/tenants/${tenantId}/`,'GET').then((response) => response.data);
  }
}

export default PortalService;