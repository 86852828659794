import "@adyen/adyen-web/dist/adyen.css";
import "../CheckoutStyles.scss";
import "react-phone-number-input/style.css";
import Core from "@adyen/adyen-web/dist/types/core";

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Svg from "../../../../../components/images/Svg";
import Switch from "../../../../../components/inputs/Switch/Switch";
import useTranslations from "../../../../../hooks/useTranslations";
import { useAppSelector } from "../../../../../store";
import { BookingState } from "../../../../../store/booking/types/store";
import { FlightState, Passenger } from "../../../../../store/flight/types/store";
import ContactSelectionForm from "./PnrContactForm";
import styles from "./PaymentStepActive.module.scss";
import AdyenCheckout from "@adyen/adyen-web";
import ENV from "../../../../../utils/environments";
import Authentication from "../../../../../utils/authentication";
import ContactForm, { Fields } from './../ContactForm/ContactForm'
import ValidationError from "../../../../../components/ValidationError";
import { ActiveStepProps } from "../../../../../components/containers/Step/types";
import { ChannelProperties, Flow, IChannelTranslationsState } from "../../../../../store/channel/channel.types";
import {
  AdyenResult,
  PaymentContact,
  Payment,
  PaymentState
} from "../../../../../store/payment/paymentTypes";
import { OAuth2Error, UserResponse } from "../../../../../store/auth/authTypes";
import AuthService from "../../../../../store/auth/authService";
import PaymentError from '../../../../../resources/svg/PaymentError.svg';
import Button from "components/inputs/buttons/Button/Button";
import { clearPaymentData, resetAdyenSession } from "store/payment/paymentReducer";
import { useDispatch } from "react-redux";
import UIElement from "@adyen/adyen-web/dist/types/components/UIElement";
import PaymentSuccess from 'resources/svg/PaymentSuccess.svg';
import { clearBooking } from "store/booking/bookingReducer";
import { clearFlight } from "store/flight/flightReducer";
import { toast } from "react-toastify";
import Modal from "components/modals/Modal";

const errorInitialState = { general: '' }

declare function UpdateCallback(data: { email: string }): void;
declare function UpdateCallback(data: { payment?: Payment, bookingCode?: string }): void;
declare function UpdateCallback(data: { resetBookingPayment: boolean }): void;
declare function UpdateCallback(data: { contact: PaymentContact, paymentLink: boolean }): void;


export type PaymentStepCallbackProps = typeof UpdateCallback;


interface Props extends ActiveStepProps {
  onUpdate: PaymentStepCallbackProps;
  onPaymentCompleted: (result: AdyenResult, component: UIElement | undefined) => void;
  data: {
    createAccount: boolean;
    properties: ChannelProperties;
    booking: Required<BookingState>;
    user: UserResponse;
    language: string;
    payment: Partial<Required<PaymentState>>;
    flow: Flow;
    flight: Required<FlightState>;
  }
}

const PaymentStepActive: React.FC<Props> = ({ onUpdate, onPaymentCompleted, data, isReady }: Props) => {
  /**
   * Handle customer payment information and user creation.
   */
  const dispatch = useDispatch();
  const { translation } = useTranslations();
  const [errors, setErrors] = useState<{ general: string }>(errorInitialState);
	const [isResendDisabled, setResendDisabled] = useState(true);
	const [countdown, setCountdown] = useState<number | null>(null);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [adyen, setAdyen] = useState<Core | undefined>();
  const errorCodes = ["Cancelled", "Expired", "Refused", "Error"]
  const passengers = useMemo(() => data.flight.passengers.filter(passenger => passenger.luggage > 0) as Required<Passenger>[], [data.flight])
  
  const merchant = useAppSelector(state => state.channel.merchant);
  const properties = useAppSelector(state => state.channel.properties);
  const userDisplay = useAppSelector(state => state.channel.properties.switches.user.display)
  const session = useAppSelector(state => state.payment.session)
  const paymentLink = useAppSelector(state => state.channel.properties.content.paymentLink)
  const contact = useAppSelector(state => state.payment.contact)
  const paymentLinkSuccess = useAppSelector(state => state.payment.paymentLinkSuccess)

  const initialState = {
    email: contact.email,
    confirmEmail: contact.email,
    firstName: contact.first_name,
    lastName: contact.last_name,
    passportNumber: passengers.find(pax => pax.first_name === contact.first_name)?.passport || "",
    phoneNumber: contact.phone_number,
    password: "",
    confirmPassword: "",
  }

  const [login, setLogin] = useState<boolean>(false);
  const [register, setRegister] = useState<boolean>(false);

  const [formData, setFormData] = useState<Fields>(initialState);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formRef, languageRef] = [
    useRef<Fields>(formData),
    useRef<string>(""),
  ];
  const dropinContainer = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const {
    email,
    confirmEmail,
    firstName,
    lastName,
    passportNumber,
    phoneNumber,
    password,
    confirmPassword,
    general
  } = formData;

  useEffect(() => {
    /**
     * These are necessary in order to update the reference in calls made outside this execution context.
     */
    formRef.current = { email, confirmEmail, firstName, lastName, passportNumber, phoneNumber, password, confirmPassword, general };
    languageRef.current = data.language;
  }, [formRef, languageRef, data.language, email, firstName, lastName, passportNumber, phoneNumber, password, confirmPassword, general, confirmEmail]);

  const handleLogin = useCallback(async () => {
    /**
     * Authenticate a given user.
     */
    if (!formRef.current.email || !formRef.current.password) throw new Error('no userdata');
    await Authentication.login(formRef.current.email, formRef.current.password).then(() => {
      setErrors(() => errorInitialState);
      onUpdate({ email: formRef.current.email });
    }).catch((error: OAuth2Error) => {
      throw error
    })
  }, [formRef, onUpdate])

  const handleRegistration = useCallback(() => {
    /**
     * Register and authenticate a given user.
     */
    return AuthService.register(formRef.current as Required<Fields>)
      .then(async () => {
        setRegister(false);
        await handleLogin();
      })
      .catch((error) => {
        const { email } = error?.response?.data?.detail;
        setErrors(() => ({ general: email }));
        throw Error(error);
      })
  }, [formRef, handleLogin])

  const handleFormChange = (formData: Fields) => {
    setFormData(() => formData);
  }

	const submitContactForm = useCallback((resend: boolean) => {
		const translationVariables = {
			countdown: countdown ? countdown.toString() : ""
		}
			
		if(resend && isResendDisabled) {
			toast.error(translation.get("payment:link:resend:error", translationVariables))
			return;
		}

    onUpdate({
			paymentLink: resend ? true : paymentLink,
      contact: {
        email: formData.email,
        first_name: formData.firstName,
        last_name: formData.lastName,
				language: data.language,
        ...formData.phoneNumber && { phone_number: formData.phoneNumber }
      }
    })
	}, [countdown, data.language, formData.email, formData.firstName, formData.lastName, formData.phoneNumber, isResendDisabled, onUpdate, paymentLink, translation]);

  const editContactForm = () => {
    dispatch(resetAdyenSession())
  }

	const bookAgain = useCallback((): void => {
    dispatch(clearFlight());
    dispatch(clearBooking());
    dispatch(clearPaymentData());
		window.scrollTo(0, 0);
  }, [dispatch])


	useEffect(() => {
		let timer: NodeJS.Timeout;
	
		if (isResendDisabled) {
			setCountdown(30);
	
			timer = setInterval(() => {
				setCountdown(prev => {
					if (prev === 1) {
						clearInterval(timer);
						setResendDisabled(false);
						return null;
					} 
					return prev ? prev - 1 : null;
				});
			}, 1000);
		}
	
		return () => clearInterval(timer);
	}, [isResendDisabled]);

  useEffect(() => {
    /**
     * Updating adyen language on the run
     */
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    setAdyen((core) => {
      core?.update({
        locale: data.properties.language.meta.adyen[data.language],
      })

      return core;
    })
  }, [data.language, data.properties.language.meta.adyen])

  useLayoutEffect(() => {
    /**
     * Initiate adyen booking form.
     */
    if (
      data.payment.session === undefined
      || !dropinContainer.current
      || !isReady
      || adyen !== undefined
    ) return;

    // For Ayden Drop-in load an extra Google Pay library
    const script = document.createElement('script');
    script.src = 'https://pay.google.com/gp/p/js/pay.js';
    script.async = true;
    document.head.appendChild(script);

    const params = new URLSearchParams(window.location.search)
    let options = {
      onError: (error: any) => console.error('Error with payment', error),
      onPaymentCompleted,
      clientKey: ENV.ADYEN_CLIENT_KEY,
      environment: ENV.ADYEN_ENVIRONMENT,
      locale: data.properties.language.meta.adyen[data.language],
      session: data.payment.session,
      paymentMethodsConfiguration: {
        googlepay: {
          configuration: {
            gatewayMerchantId: merchant,
            merchantId: 'BCR2DN4TQDVI5FCQ',
            merchantName: 'Bagpoint B.V.',
          }
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
          autofocus: true
        }
      },
    }

    AdyenCheckout(options)
      .then((core) => {
        if (params.has('redirectResult')) {
          core.submitDetails({ details: { redirectResult: params.get('redirectResult') } })
        }

        setAdyen(core);

        if (dropinContainer.current) core.create("dropin").mount(dropinContainer.current);
      })
  }, [
    adyen,
    merchant,
    onPaymentCompleted,
    data.payment.session,
    isReady,
    data.payment.session?.id,
    data.language,
    data.properties.language.meta.adyen
  ]);

  useLayoutEffect(() => {
    /**
     * Update adyen when session changes.
     */
    if (adyen === undefined) return;

    adyen.update({
      session: data.payment.session,
    }).then(setAdyen)
  }, [data.payment.session, adyen])

  return (
    <React.Fragment>
      <h2 className={styles.heading}>
        {translation.get("payment:heading")}
      </h2>

			<Modal
				hide={() => setShowConfirmation(false)}
				headerText={translation.get("payment:link:confirmation_heading")}
				isShown={showConfirmation}
				modalContent={<>
					<div className={styles.confirmation}>
						<Button className={styles.cancelButton} type="submit" text={translation.get("payment:link:cancel")} onClick={() => setShowConfirmation(false)} />
						<Button className={styles.confirmButton} type="button" text={translation.get("payment:link:confirm")} onClick={() => {
							submitContactForm(true);
							setResendDisabled(true);
							setShowConfirmation(false);
						}} />
					</div>
				</>}
			/>

      {paymentLinkSuccess ?
				<div className={styles.paymentLinkSuccessful}>
					<div className={styles.textWrapper}>
							<Svg url={PaymentSuccess} className={styles.successIcon} />
						<span>{translation.get("payment:link:success")}</span>
					</div>

					<div className={styles.buttonWrapper}>
						<Button type="submit" text={translation.get("payment:link:book_again")} onClick={bookAgain} />
						<Button type="submit" text={translation.get("payment:link:resend")} onClick={() => setShowConfirmation(true)} />
					</div>
				</div>

        :
        <div className={styles.wrapper}>
          <div className={styles.selector}>
            {properties.switches.user.display && !paymentLink && <Switch
              leftLabel={translation.get("payment:switch_new")}
              rightLabel={translation.get("payment:switch_login")}
              initial={"left"}
              onChange={(selected) => setLogin(selected === "right")}
            />}
          </div>
          <div className={styles.container}>
            <div className={`${session && styles.disabledForm}`}>
              {
                /*
                * Contact information
                */
              }

              {userDisplay && !login && <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  id="createAccount"
                  value={`${register}`}
                  checked={register}
                  onChange={() => setRegister(!register)}
                />
                <label htmlFor="createAccount">
                  {translation.get("payment:label_create")}
                </label>
              </div>}

              {data.flow === Flow.Airport && data.flight.passengers && !login && <ContactSelectionForm
                passengers={passengers}
                onChange={handleFormChange}
              />}

              <ContactForm
                formData={formData}
                login={login}
                register={register}
                handleLogin={handleLogin}
                handleRegistration={handleRegistration}
                onChange={handleFormChange}
                setValidForm={setFormIsValid}
              />
              {errors.general && (
                <ValidationError error={errors.general}/>
              )}

            </div>

            {session ?
              <Button
                id="edit-contact"
                data-cy="edit-contact"
                type="submit"
                className={styles.submitContact}
                onClick={editContactForm}
                text={translation.get("button:edit")}
              />
              :
              <Button
                id="submit-contact"
                data-cy="submit-contact"
                type="submit"
                className={styles.submitContact}
                onClick={() => submitContactForm(false)}
                text={translation.get("button:submit")}
                disabled={!formIsValid}
              />
            }

            <p>{translation.get("payment:security")}</p>
          </div>
          <div className={styles.headingContainer}>
            {data.payment?.result?.resultCode && errorCodes.includes(data.payment.result.resultCode) &&
              <div data-cy="payment-error" className={styles.error}>
                {translation.get(`payment:result:${data.payment.result?.resultCode}` as keyof IChannelTranslationsState)}
                <Svg url={PaymentError} className={styles.paymentError}/>
              </div>}
            <div
              data-cy="dropin-container"
              ref={dropinContainer}
              id="dropin-container"
              className={`${formIsValid ? "" : styles.disabledForm}`}
            />
          </div>
        </div>}
    </React.Fragment>
  );
}

export default PaymentStepActive;